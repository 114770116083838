import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import SupplierTable from './Table';

const MainContainer = styled(Box)(
  () => `padding: 0px 32px 0px 32px;
  width: 100%;
  `
);

export default function ManageSuppliers() {
  return (
    <>
      <MainContainer>
        <SupplierTable />
      </MainContainer>
    </>
  );
}