import { ActionTypes } from "../constants/action-types";

const initialState = {
    rawMaterials: [],
    finishedGoods: [],
    rawMaterialGroups: [],
    loading: false,
    error: null
  };
  
const rawMaterialReducer = (state = initialState, {type, payload} = {}) => {
    switch (type) {
      case ActionTypes.RAW_MATERIAL_FETCH_SUCCESS:
        return {
          ...state,
          rawMaterials: payload,
          loading: false,
          error: null
        };
      case ActionTypes.RAW_MATERIAL_FETCH_ERROR:
      case ActionTypes.RAW_MATERIAL_GROUPED_FETCH_ERROR:
        return {
          ...state,
          loading: false,
          error: payload
        };
      case ActionTypes.FINISHED_GOODS_FETCH_SUCCESS:
          return {
            ...state,
            finishedGoods: payload,
            loading: false,
            error: null
        };
      case ActionTypes.CLEAR_DATA:
        return {
          ...initialState
        };
        return 
      case ActionTypes.RAW_MATERIAL_GROUPED_FETCH_SUCCESS:
        return {
          ...state,
          rawMaterialGroups: payload,
          loading: false,
          error: null
        };
      case ActionTypes.GET_RAW_MATERIAL_VIEW:
        return { ...state, rawMaterialData: { ...payload } };
      default:
        return state;
    }
};
  
export default rawMaterialReducer;