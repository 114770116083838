import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import $ from 'jquery';
import ReactDOMServer from 'react-dom/server';

import 'react-datepicker/dist/react-datepicker.css';
import Trash from '../../../../../../../assets/Icons/trashRed.svg';
import { ColorRing } from 'react-loader-spinner';
import Switch, { SwitchProps } from '@mui/material/Switch';
import CustomModal from '@mui/material/Modal';
import { FileUploader } from 'react-drag-drop-files';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Box,
  Button,
  styled,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import './DataGrid.css';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBOUploadHistory,
  uploadBulkOrder,
  validateBulkOrder,
  placeBulkOrder,
  placeAllValidBulkOrder
} from 'src/redux/actions/BulkOrderActions';

import moment from 'moment';

import { addDays, subDays } from 'date-fns';
import { toast } from 'react-toastify';

import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';

const ModalHeader = styled(Box)(
  () => `
    font-weight: 900;
    font-size: 18px;
    `
);

const CustomLabel = styled(FormControlLabel)(
  () => `
    margin: 0
    `
);

const CancelButton = styled(Button)(
  () => `
  color: white !important;
  background: #15364A  !important;
  border-radius: 3px  !important;
  height: 38px !important;
  width: 100px !important;

  :hover {
      background-color:  #022f53  !important;
    }
    :disabled {
      opacity: 0.8 !important;
      color: white
    }
  `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 3,
  bordeRadius: '5px'
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '88%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 4,
  bordeRadius: '5px'
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'light' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#ffffff !important',
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const BulkOrderHistoryTable = () => {
  const dispatch = useDispatch();

  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [deleteInfo, setDeleteInfo] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [openTableModal, setOpenTableModal] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [spaces, setSpaces] = React.useState(false);

  const customerData = useSelector((state) => state?.customers?.customerData);
  const authToken = useSelector((state) => state?.auth?.token?.token);

  const [bulkOrderHistoryDataTableColumns, setBulkOrderHistoryDataTableColumns] = useState(false);
  const [bulkOrderHistoryDataTableOptions, setBulkOrderHistoryDataTableOptions] = useState(false);
  const [bulkOrderHistoryDataTableData, setBulkOrderHistoryDataTableData] = useState(false);

  const [bulkOrderUploadDataTableColumns, setBulkOrderUploadDataTableColumns] = useState(false);
  const [bulkOrderUploadDataTableOptions, setBulkOrderUploadDataTableOptions] = useState(false);
  const [bulkOrderUploadDataTableData, setBulkOrderUploadDataTableData] = useState(false);

  const [bulkOrderHistoryId, setBulkOrderHistoryId] = useState(false);

  const isLoading = useSelector((state) => state?.loaderStatus?.showLoading);

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', margin: '12px 0' }),
    []
  );

  const fileTypes = ['CSV', 'XLSX', 'XLS'];

  const [statusFilteredData, setStatusFilteredData] = React.useState({
    status: [],
    jobType: [],
    date: {}
  });

  const handleBulkUpload = () => {
    const fd = new FormData();
    fd.append('file', file);

    dispatch(
      uploadBulkOrder(
        customerData?.customerNumber,
        spaces,
        fd,
        authToken,
        setOpenTableModal,
        setBulkOrderHistoryId
      )
    );
    setFile(null);
    handleModalClose();
  }

  const onTrashClick = () => {
    setFile(null);
  };

  const onSpacesSwitchChange = (e) => {
    if (spaces) {
      setSpaces(e.target.checked);
    } else {
      setSpaces(e.target.checked);
    }
  };

  const handlePlaceOrder = (data) => {
    dispatch(
      placeBulkOrder(
        customerData?.customerNumber,
        bulkOrderHistoryId,
        data,
        authToken,
        handleTableModalClose,
      )
    );
  }

  const handlePlaceAllValidOrder = () => {
    dispatch(
      placeAllValidBulkOrder(
        customerData?.customerNumber,
        bulkOrderHistoryId,
        authToken,
        handleTableModalClose,
        gridRef
      )
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setOpenModal(false);
    setFile(null);
  };

  const handleTableModalClose = () => {
    setSelectedData([]);
    onStatusClearFilter();
    setOpenTableModal(false);
    $('#BulkOrderHistoryTable').DataTable().ajax.reload();
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const deleteBPO = (e, props) => {
    setModalOpen(true);
    setDeleteInfo(props?.data);
  };

  const onDelete = (data) => {
    dispatch(
      deleteBOUploadHistory(data?.id, customerData?.customerNumber, authToken)
    );
    handleModalClose();
  };
  const validateBO = (event, props) => {
    let row = $(event.currentTarget).data();

    dispatch(
      validateBulkOrder(
        row.id,
        customerData?.customerNumber,
        authToken,
        setOpenTableModal,
        setBulkOrderHistoryId
      )
    );
    handleModalClose();
  };

  const productTable = useCallback(() => {
    return (
      <>
        {bulkOrderUploadDataTableColumns && bulkOrderUploadDataTableOptions && (bulkOrderHistoryId !== false) && (
          <DataTable
            id={'BulkOrderUploadTable'}
            columns={bulkOrderUploadDataTableColumns}
            options={bulkOrderUploadDataTableOptions}
          />
        )}
      </>
    );
  }, [
    bulkOrderUploadDataTableColumns,
    bulkOrderUploadDataTableOptions,
    bulkOrderHistoryId
  ]);

  const onStatusClearFilter = () => {
    setStatusFilteredData({
      status: [],
      jobType: [],
      date: {}
    });

    setState([
      {
        startDate: subDays(new Date(), 0),
        endDate: addDays(new Date(), 0),
        key: 'selection'
      }
    ]);
  };

  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ]);

  //Bulk Orders History Data Table settings
  const trashIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faTrashCan} />);
  useEffect(() => {
    let columns = [
      {
        title: 'File Uploaded',
        name: 'fileuploaded',
        data: 'uploadedFileUrl',
        render: function (data, type, row) {
          let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
          return `<div class="text-truncate" style="width: 200px;"><a href="${linkUri}" target="_blank" title="${data.match(/[^\/]+$/)[0]}">${data.match(/[^\/]+$/)[0]}</a></div>`;
        },
      },
      {
        title: 'File Count',
        name: 'uploadedfilecount',
        data: 'uploadedFileCount',
      },
      {
        title: 'Updated By',
        name: 'updatedby',
        data: 'createdBy',
      },
      {
        title: 'Date/Time',
        name: 'uploaddate',
        data: 'uploadDate',
        render: function (data) {
          return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY HH:mm');
        }
      },
      {
        title: 'Valid Orders',
        name: 'validordersfile',
        data: 'validOrdersFile',
        render: function (data, type, row) {
          let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
          return `<a href="${linkUri}" target="_blank">${row.validOrdersFileCount}</a>`;
        },
      },
      {
        title: 'Invalid Orders',
        name: 'invalidordersfile',
        data: 'invalidOrdersFile',
        render: function (data, type, row) {
          let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
          return `<a href="${linkUri}" target="_blank">${row.invalidOrdersFileCount}</a>`;
        },
      },
      {
        title: 'Orders Placed',
        name: 'orderplacedfile',
        data: 'orderPlacedFile',
        defaultContent: '',
        render: function (data, type, row) {
          if (data !== null) {
            let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
            return `<a href="${linkUri}" target="_blank">${row.orderPlacedFileCount}</a>`;
          }
        },
      },
      {
        title: 'Status',
        name: 'orderstatus',
        data: 'orderStatus',
        render: function (data, type, row) {
          let pillColorClass = (data.code == 'PE') ? 'bg-warning text-dark' : 'bg-success';
          return `<span class="badge rounded-pill px-3 fw-normal ${pillColorClass}">${data.description}</span>`;
        }
      },
      {
        title: 'Action',
        name: 'action',
        data: 'id',
        className: 'actionColumn',
        render: function (data, type, row) {
          let pillColorClass = (row.orderStatus.code == 'PE') ? 'bg-dark' : 'bg-secondary pe-none';
          return `<div class="text-nowrap"><span role="button" class="badge rounded-pill px-3 fw-normal align-text-top ${pillColorClass}" data-button="validate" data-id="${row.id}">Validate</span><span role="button" class="ms-3 fs-6" data-button="delete" data-id="${row.id}">${trashIcon}</span></div>`;
        }
      },
    ];

    setBulkOrderHistoryDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const BulkOrderHistoryDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      {
        text: 'Upload Bulk',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          handleOpen();
        },
      },
      {
        extend: 'copy',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'csv',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      }
    );
    return buttons;
  };

  const handleHistoryLinks = (event) => {
    let row = $(event.currentTarget).data();
    let props = { 'data': { 'id': row.id } };
    //Different actions depending on data-button type
    if (row.button == 'validate') {
      validateBO(event, props);
    }
    if (row.button == 'delete') {
      deleteBPO(event, props);
    }
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: BulkOrderHistoryDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/customer/${customerData?.customerNumber}/BOUploadHistory`,
        type: "GET",
        dataSrc: function (data) {
          setBulkOrderHistoryDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 5,
      // fixedHeader: true,
      // scrollY: '270px',
      order: [[3, 'desc']],
      searching: false,
      select: false,
    };
    setBulkOrderHistoryDataTableOptions(options);
  }, []);

  //Bulk Orders Upload Data Table settings
  useEffect(() => {
    let columns = [
      {
        name: 'checkbox',
        data: '',
        orderable: false,
        render: function (data, type, row) {
          if (row.status[0] == 'Valid') {
            return `<input type="checkbox" class="form-check-input" name="item${row.id}" id="item${row.id}" value="${row.id}">`;
          } else {
            return '';
          }
        },
      },
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'PO Number',
        name: 'ponumber',
        data: 'poNumber',
      },
      {
        title: 'PO Line',
        name: 'poline',
        data: 'poLine',
      },
      {
        title: 'Quantity',
        name: 'quantity',
        data: 'quantity',
      },
      {
        title: 'UOM',
        name: 'uom',
        data: 'uom',
      },
    ];

    //Inventory Columns
    customerData?.associatedCustomers?.map((d) => {
      columns.push({
        title: d.branchName,
        name: d.branchName,
        data: 'id',
        defaultContent: '',
        render: function (data, type, row, meta) {
          let returnval = 0;
          if (row.inventoryList.length > 0) {
            row.inventoryList.map(obj => {
              if (obj.branchName === meta.settings.aoColumns[meta.col].title) {
                returnval += parseInt(obj.quantity);
              }
            });
          }
          //Add uom after inventory for display
          returnval = returnval + " " + row.uom;

          return returnval;
        },
      });
    });

    columns.push({
      title: 'After Order',
      name: 'afterorder',
      data: 'afterOrder',
    },
      {
        title: 'Due Date',
        name: 'duedate',
        data: 'dueDate',
        render: function (data) {
          return moment(data, 'MM-DD-YYYY').format('MM/DD/YYYY');
        }
      },
      {
        title: 'Status',
        name: 'status',
        data: 'status',
        render: function (data, type, row) {
          let pillColorClass = (data == 'Valid') ? 'bg-success' : 'bg-danger';
          return `<span class="badge rounded-pill px-3 fw-normal ${pillColorClass}">${data}</span>`;
        }
      }
    );

    setBulkOrderUploadDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const BulkOrderUploadDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      {
        text: 'Place Selected Orders',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          setSelectedData({ dtselected });
          handlePlaceOrder(dtselected);
        },
      },
      {
        text: 'Place All Valid Orders',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          handlePlaceAllValidOrder();
        },
      },
      {
        extend: 'copy',
        className: 'btn-outline-secondary'
      },
      {
        extend: 'csv',
        className: 'btn-outline-secondary'
      },
      {
        extend: 'print',
        className: 'btn-outline-secondary'
      }
    );
    return buttons;
  };

  useEffect(() => {
    if (bulkOrderHistoryId !== false) {
      let options = {
        dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
          "<'row'<'col-12'tr>>" +
          "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
        buttons: BulkOrderUploadDataTableButtons(),
        ajax: {
          url: `${SERVER_BASE_URL}/customer/boPagingList/${customerData?.customerNumber}/${bulkOrderHistoryId}`,
          type: "POST",
          dataSrc: function (data) {
            setBulkOrderUploadDataTableData(data.content);
            return data.content;
          },
          // dataSrc: 'content',
          dataType: 'JSON',
          contentType: 'application/json',
          data: function (d) {
            return JSON.stringify({
              pageIndex: 0,
              pageSize: 99999,
              statusList: [],
              id: bulkOrderHistoryId,
            });
          },
          timeout: 0,
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
        info: true,
        processing: true,
        retrieve: true,
        pageResize: true,
        scrollCollapse: true,
        lengthChange: false,
        pageLength: 10,
        order: [[1, 'desc']],
        searching: false,
        select: {
          style: 'multi',
        },
      };
      setBulkOrderUploadDataTableOptions(options);
    }
  }, [bulkOrderHistoryId]);


  return (
    <div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { mr: 1 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ flexGrow: 1 }}>
          <span style={{ fontSize: '20px', fontWeight: 700 }}>
            Upload History
          </span>
        </Box>
      </Box>
      <div style={gridStyle}>
        <Box style={{ width: '100%' }}>
          {bulkOrderHistoryDataTableColumns && bulkOrderHistoryDataTableOptions && (
            <DataTable
              id={'BulkOrderHistoryTable'}
              columns={bulkOrderHistoryDataTableColumns}
              options={bulkOrderHistoryDataTableOptions}
              onRowClick={handleHistoryLinks}
            />
          )}
        </Box>
      </div>

      <CustomModal open={openModal} onClose={handleModalClose}>
        <Box sx={style}>
          <ModalHeader>Upload Bulk Order</ModalHeader>
          <div style={{ marginTop: '20px' }}>
            {!file && (
              <Box className="file_Upload">
                <FileUploader
                  onTypeError={(err) => toast.error(err)}
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    padding: '8px 0 0 0'
                  }}
                >
                  <Grid item xs={6} md={6} sm={12}>
                    {'Acceptable File Types: ' + fileTypes}
                  </Grid>
                  <Grid item xs={6} md={6} sm={12}>
                    {'Max File Size: 50mB'}
                  </Grid>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                flexGrow: 1,
                borderBottom: '1px solid #D9D9D9',
                padding: '25px 0'
              }}
            >
              <CustomLabel
                label={
                  <Box sx={{ fontWeight: '700', fontSize: '12px' }}>
                    Remove spaces from Part Number
                  </Box>
                }
                control={
                  <IOSSwitch
                    defaultChecked={spaces}
                    onChange={(e) => onSpacesSwitchChange(e)}
                    name="statusCode"
                    sx={{ mr: 1 }}
                  />
                }
              />
            </Box>
            {file && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '25px 0',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#343D42'
                }}
              >
                {file && file?.name}
                <span style={{ margin: '5px 10px 0 10px' }}>
                  {' '}
                  <img
                    width="20px"
                    className="Img1"
                    style={{ cursor: 'pointer' }}
                    onClick={onTrashClick}
                    src={Trash}
                  ></img>
                </span>
              </Box>
            )}

            <Box
              sx={{
                '& > :not(style)': { mr: 2, mt: 3 },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Grid item xs={6} md={2}>
                <CancelButton onClick={handleModalClose}>Cancel</CancelButton>
              </Grid>{' '}
              <Grid item xs={6} md={2}>
                <CancelButton disabled={!file} onClick={handleBulkUpload}>
                  Upload
                </CancelButton>
              </Grid>
            </Box>
          </div>
        </Box>
      </CustomModal>

      <CustomModal open={openTableModal} onClose={handleTableModalClose}>
        <Box sx={modalStyle}>
          <div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { mr: 1 },
                display: { md: 'flex', xs: 'box' },
                flexDirection: 'row'
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ flexGrow: 1 }}>
                <span style={{ fontSize: '20px', fontWeight: 700 }}>
                  Upload Bulk Orders for Customer Number:{' '}
                  {customerData?.customerNumber}
                </span>
              </Box>
            </Box>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              //  minHeight="100vh"
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[]}
                />

                <span>Loading...</span>
              </Box>
            ) : (
              <Box style={gridStyle}>
                <Box
                  className="ag-theme-alpine bulk_order_modal"
                  style={{
                    maxWidth: '100%',
                    width: '100%'
                  }}
                >
                  {productTable()}
                </Box>

                <Box
                  sx={{
                    '& > :not(style)': { mr: 2, mt: 3 },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                >
                  <Grid item xs={6} md={2}>
                    <CancelButton onClick={handleTableModalClose}>
                      Cancel
                    </CancelButton>
                  </Grid>
                </Box>
              </Box>
            )}

          </div>
        </Box>
      </CustomModal>

      <Dialog
        className="On-close"
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this history?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="On-delete"
            onClick={(e) => onDelete(deleteInfo)}
            autoFocus
          >
            Agree
          </Button>
          <Button onClick={handleModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkOrderHistoryTable;
