import axios from 'axios';
import { ActionTypes } from '../constants/action-types';
import SERVER_BASE_URL from '../../config/config';
import { toast } from 'react-toastify';
import $ from 'jquery';

export const getForecastData =
  (authToken, customerNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_FORECAST_DATA,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const addOrUpdateForecastData =
  (
    authToken,
    name,
    format,
    customerNumber,
    ForecastDataAddData,
    existingFields
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        if (
          ForecastDataAddData?.find((d) => d?.headerName === name) ||
          existingFields?.find((d) => d.toUpperCase() === name.toUpperCase())
        ) {
          toast.error('Field Already Exists');
        } else {
          const response = await axios.post(
            `${SERVER_BASE_URL}/customer/FConfiguration/addAdditionalField`,
            [
              {
                customerNumber: customerNumber,
                headerName: name,
                headerDataType: format,
                value: ''
              }
            ],

            config
          );
        }

        const fetchForecastData = await axios.get(
          `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration`,
          config
        );

        dispatch({
          type: ActionTypes.GET_FORECAST_DATA,
          payload: fetchForecastData.data
        });
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const UploadAllValidOrder =
  (customerNumber, id, authToken, handleModalClose) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/uploadAllValidForecast/${customerNumber}/${id}`,
          {},
          config
        );

        if (response) {
          handleModalClose(true);
          toast.success("Uploaded successfully.");
        }

      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const deleteForecastConfig =
  (authToken, data, id) => async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      const response = await axios.delete(
        `${SERVER_BASE_URL}/customer/${data?.customerNumber}/AdditionalFConfiguration/delete?headerName=${data?.headerName}`,

        config
      );

      const getForecastConfig = await axios.get(
        `${SERVER_BASE_URL}/customer/${data?.customerNumber}/FConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_FORECAST_DATA,
        payload: getForecastConfig.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const saveForecastConfig =
  (authToken, values, addValues, customerNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/customer/FConfiguration/addOrUpdate`,

        { ...values, customerNumber: customerNumber },

        config
      );

      const saveAddForecastConfig = await axios.post(
        `${SERVER_BASE_URL}/customer/FConfiguration/addAdditionalField`,
        addValues,
        config
      );

      const getForecastConfig = await axios.get(
        `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_FORECAST_DATA,
        payload: getForecastConfig.data
      });

      toast.success('Forecast configuration got updated successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }

      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const deleteForecastHistory =
  (id, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.delete(
        `${SERVER_BASE_URL}/customer/FConfiguration/UploadHistory/delete/${id}`,
        config
      );

      toast.success('History removed successfully.');

    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
    $('#forecastUploadHistoryTable').DataTable().ajax.reload();
  };

export const uploadAndCompareForecast =
  (
    customerNumber,
    spaces,
    file,
    authToken,
    setopenCompareModal,
    navigate,
    file1,
    secondFile2,
    setErrorModalOpen,
    setErrorHistory
  ) =>
    async (dispatch) => {

      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setErrorHistory(false);
        setErrorModalOpen(true);
        toast.success('Comparing Forecasts...');
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration/compare?shouldSpaceBeRemoved=${spaces}`,
          file,
          config
        );

        dispatch({
          type: ActionTypes.GET_FORECAST_COMPARE_DATA,
          payload: response.data
        });



        if (response?.data?.errors?.length > 0) {
          dispatch({
            type: ActionTypes.FORECAST_COMPARE_ERROR,
            payload: response?.data?.errors
          });
        } else {
          toast.success('Compared Successfully');
          setopenCompareModal(false);
          navigate('/compare-forecast', {
            state: {
              file1: file1,
              file2: secondFile2
            }
          });
        }
      } catch (e) {

        if (typeof e?.response?.data?.error === 'string') {
          toast.error(e?.response?.data?.error);
        } else {
          dispatch({
            type: ActionTypes.FORECAST_COMPARE_ERROR,
            payload: e?.response?.data?.error
          });
        }
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
      }
    };

export const uploadAndCompareForecastHistory =
  (
    customerNumber,
    FileIds,
    authToken,
    setopenCompareModal,
    navigate,
    selectedData,
    setErrorHistory
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };

      try {
        setErrorHistory(true);
        toast.success('Comparing Forecasts...');
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration/${FileIds[0]}/${FileIds[1]}/compare`,
          {},
          config
        );

        dispatch({
          type: ActionTypes.GET_FORECAST_COMPARE_DATA,
          payload: response.data
        });

        if (response?.data?.errors?.length > 0) {
          dispatch({
            type: ActionTypes.FORECAST_COMPARE_ERROR,
            payload: response?.data?.errors
          });
        } else {
          toast.success('Compared Successfully');
          setopenCompareModal(false);
          navigate('/compare-forecast', {
            state: {
              file1: selectedData[0]?.fileUploaded?.substring(
                selectedData[0]?.fileUploaded?.lastIndexOf('/') + 1
              ),
              file2: selectedData[1]?.fileUploaded?.substring(
                selectedData[1]?.fileUploaded?.lastIndexOf('/') + 1
              )
            }
          });
        }
      } catch (e) {

        if (typeof e?.response?.data?.error === 'string') {
          toast.error(e?.response?.data?.error);
        } else {
          dispatch({
            type: ActionTypes.FORECAST_COMPARE_ERROR,
            payload: e?.response?.data?.error
          });
        }
      }
    };

export const uploadPartsForecast =
  (customerNumber, id, orders, authToken, handleModalClose, gridRef) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };

      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration/uploadParts/${id}`,
          orders,
          config
        );

        handleModalClose(true);
        toast.success('Forecast parts are uploaded successfully.');
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }

        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const uploadForecast =
  (
    customerNumber,
    spaces,
    file,
    authToken,
    setTableModalOpen,
    setForecastId
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setTableModalOpen(true);
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration/uploadExcel?shouldSpaceBeRemoved=${spaces}`,
          file,
          config
        );

        if (response && response?.data?.id) {
          setForecastId(response?.data?.id);
        }

        toast.success('File uploaded successfully.');
        setTableModalOpen(true);
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
        setTableModalOpen(false);
      }
    };

export const validateForecastConfig =
  (
    fcuhId,
    customerNumber,
    authToken,
    setOpenTableModal,
    setForecastId
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setOpenTableModal(true);
        const response = await axios.get(
          `${SERVER_BASE_URL}/customer/${customerNumber}/FConfiguration/validate/${fcuhId}`,
          config
        );

        if (response) {
          setForecastId(fcuhId);
        }

        toast.success('File Uploaded Successfully');
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
        setOpenTableModal(false);
      }
    };
