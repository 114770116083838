import { Box, Button, Grid, styled } from '@mui/material';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewFeature, } from 'src/redux/actions/sysAdminActions';

import Typography from '@mui/material/Typography';
import CustomModal from '@mui/material/Modal';
import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import $ from 'jquery';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const TableContainer = styled(Box)(
  () => `
  padding: 15px 24px;
  background-color: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  border-radius: 3px;  
  `
);

const MainContainer = styled(Box)(
  () => `
  padding: 0px 32px 0px 32px;
  width: 100%; 
  `
);

const AddButton = styled(Button)(
  () => `
        color: white !important;
        background: #15364A  !important;
        border-radius: 3px  !important;
        height: 38px !important;
        width: 63px !important

        :hover {
            background-color:  #022f53  !important;
          }
        :disabled {
          color: #aeaeae !important;
        }
        `
);
const CancelButton = styled(Button)(
  () => `
  background: #FFFFFF  !important;
  border: 1px solid #15364A  !important;
  border-radius: 3px  !important;
  color: #15364A  !important;
  height: 38px !important;
  width: 84px !important
      
          :hover {
              background-color: white  !important;
            }
          
          `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);

const FeatureList = ({ }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state?.auth?.token?.token);

  const [featureListDataTableColumns, setFeatureListDataTableColumns] = useState(false);
  const [featureListDataTableOptions, setFeatureListDataTableOptions] = useState(false);
  const [featureListDataTableData, setFeatureListDataTableData] = useState(false);
  const [featureOpen, setFeatureOpen] = useState(false);
  const inputRef = useRef();

  const handleFormSubmit = async (e) => {
    e?.preventDefault();
    dispatch(addNewFeature({ description: inputRef.current.value }, authToken));
    setFeatureOpen(false);
    $('#input').val('')
  }

  useEffect(() => {
    let columns = [];
    columns.push(
      {
        title: 'Description',
        name: 'description',
        data: 'description'
      }
    );
    setFeatureListDataTableColumns(columns);
  }, []);

  const roleListDataTableButtons = () => {
    let buttons = [];
    buttons.push({
      text: 'Add Feature',
      className: 'btn-dark me-1',
      action: function (e, dt, node, config) {
        handleOpen();
      }
    })
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  const handleOpen = () => {
    setFeatureOpen(true);
  };

  const handleClose = (e) => {
    setFeatureOpen(false);
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: roleListDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/feature/getAllFeature`,
        type: "GET",
        dataSrc: function (data) {
          setFeatureListDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 10,
      fixedHeader: true,
      // scrollY: '450px',
      order: [[0, 'asc']],
      searching: true,
      select: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setFeatureListDataTableOptions(options);
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', sm: '100%' },
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 4,
    bordeRadius: '5px'
  };

  const gridStyle =
    (() => ({ height: '100%', width: '100%', margin: '12px 0' }), []);

  return (
    <MainContainer>
      <div className="custom-pg-content-sec">
        <Box
          className="custom-pg-titlesticky-sec"
          component="form"
          sx={{
            '& > :not(style)': { ml: 0 },
            display: { md: 'flex', xs: 'box' },
            flexDirection: 'row'
          }}
          noValidate
          autoComplete="off"
        >
          <Box className="custom-heading-container-sec" sx={{}}>
            <span
              className="custom-heading-sec"
              style={{
                fontSize: '20px',
                lineHeight: 1,
                fontWeight: 700,
                display: 'inline-flex',
                flexDirection: 'column'
              }}
            >
              Manage Features
            </span>
          </Box>
        </Box>
        <div className="custom-pg-tables-content-sec">
          <TableContainer style={gridStyle}>
            {featureListDataTableColumns && featureListDataTableOptions && (
              <DataTable
                id={'featureListTable'}
                columns={featureListDataTableColumns}
                options={featureListDataTableOptions}
                onRowClick={handleOpen}
              />
            )}
          </TableContainer>
        </div>
        <CustomModal id="addModal" keepMounted
          open={featureOpen} onClose={handleClose}>
          <Box sx={style}>
            <div>
              <Typography id="keep-mounted-modal-title" variant="h3" component="h2">Add Feature</Typography>
            </div>
            <div style={{ marginTop: '30px' }}>
              <form onSubmit={handleFormSubmit}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <>
                    {' '}
                    <Grid item xs={12} sx={{ margin: '10px 0' }}>
                      <LabelBox>
                        Feature Name<span style={{ color: 'red' }}> *</span>
                      </LabelBox>
                      <input id='input' type='text' ref={inputRef} style={{ width: '100%', background: '#F4F7F9', height: '36px', border: 'none' }} />
                    </Grid>
                  </>
                </Grid>
                <Box
                  sx={{
                    '& > :not(style)': { ml: 2, mt: 3 },
                    display: 'flex',
                    flexDirection: 'row-reverse'
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <AddButton
                      type="submit"
                    >
                      Add
                    </AddButton>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                  </Grid>
                </Box>
              </form>
            </div>
          </Box>
        </CustomModal>
      </div>
    </MainContainer>
  );
};

export default FeatureList;