import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  saveBulkPOChangeRequestData
} from 'src/redux/actions/SupplierPortalActions';

const useFormControls = (schedule, status) => {
  const dispatch = useDispatch();

  const supplierData = useSelector((state) => state?.suppliers?.supplierData);
  const authToken = useSelector((state) => state?.auth?.token?.token);

  let navigate = useNavigate();
  // We'll update "values" as the form updates
  const [values, setValues] = React.useState();
  // "errors" is used to check the form for errors
  const [errors, setErrors] = React.useState({});

 
  const handleFormSubmit = (e, type) => {
    e?.preventDefault();
    if (formIsValid() && type != 'cancel button') {
      dispatch(
        saveBulkPOChangeRequestData(
          authToken,
          {
            ...values,
            statusCode:
              (type === 'Status' && e?.target.checked === true && 'A') ||
              (e?.target.checked === false && 'IA') ||
              undefined
          },
          supplierData?.id,
          supplierData?.supplierNumber,
          type,
        )
      );
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('asnNumber' in fieldValues) {
      temp.asnNumber =
        (parseInt(fieldValues.asnNumber) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.asnNumber.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '');
    }
    if ('asnQuantity' in fieldValues) {
      temp.asnQuantity =
        (parseInt(fieldValues.asnQuantity) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.asnQuantity.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '');
    }

    if ('dueDate' in fieldValues) {
      temp.dueDate =
        (parseInt(fieldValues.dueDate) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.dueDate.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '') ||
        (fieldValues.dueDate ? '' : 'This field is required.');
    }

    if ('firstRow' in fieldValues) {
      temp.firstRow =
        (parseInt(fieldValues.firstRow) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.firstRow.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '') ||
        (fieldValues.firstRow ? '' : 'This field is required.');
    }

    if ('partNumber' in fieldValues) {
      temp.partNumber =
        (parseInt(fieldValues.partNumber) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.partNumber.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '') ||
        (fieldValues.partNumber ? '' : 'This field is required.');
    }

    if ('poLine' in fieldValues) {
      temp.poLine =
        (parseInt(fieldValues.poLine) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.poLine.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '');
    }

    if ('poNumber' in fieldValues) {
      temp.poNumber =
        (parseInt(fieldValues.poNumber) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.poNumber.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '');
    }

    if ('poStatus' in fieldValues) {
      temp.poStatus =
        (parseInt(fieldValues.poStatus) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.poStatus.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '');
    }

    if ('quantity' in fieldValues) {
      temp.quantity =
        (parseInt(fieldValues.quantity) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.quantity.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '') ||
        (fieldValues.quantity ? '' : 'This field is required.');
    }

    if ('dueDate' in fieldValues) {
      temp.dueDate =
        (parseInt(fieldValues.dueDate) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.dueDate.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '') ||
        (fieldValues.dueDate ? '' : 'This field is required.');
    }
    if ('uom' in fieldValues) {
      temp.uom =
        (parseInt(fieldValues.uom) <= 0
          ? 'No zero/negative values allowed'
          : '') ||
        (fieldValues.uom.indexOf('.') !== -1
          ? 'No decimal values allowed'
          : '') ||
        (fieldValues.uom ? '' : 'This field is required.');
    }
  }


  const handleInputValue = (e, type, nm) => {
    let obj = values;

    if (type === 'array') {
      obj = { ...obj, [nm]: e?.map((item) => item.value) };
    }
    if (type === 'string') {
      obj = { ...obj, [e?.target.name]: e?.target.value };

      validate({ [e?.target.name]: e?.target.value });
    }

    if (type === 'select_string') {
      obj = { ...obj, [nm]: e?.value };
    }

    if (type === 'string_logo') {
      obj = { ...obj, [nm]: e };
    }

    setValues(obj);
  };

  const formIsValid = (fieldValues = values) => {
    const isValid = Object.values(errors).every((x) => x === '');

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values
  };
};

export default useFormControls;
