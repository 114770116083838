import axios from 'axios';
import { ActionTypes } from '../constants/action-types';
import SERVER_BASE_URL from '../../config/config';
import { toast } from 'react-toastify';
import $ from 'jquery';

export const getBulkOrderData =
  (authToken, customerNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/customer/${customerNumber}/BOConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_ORDER_DATA,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const addOrUpdateBulkOrder =
  (
    authToken,
    name,
    format,
    customerNumber,
    BulkOrderDataAddData,
    existingFields
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };

      try {
        let response;

        if (
          BulkOrderDataAddData?.find(
            (d) => d?.headerName === name.replace(/\s\s+/g, ' ')
          ) ||
          existingFields?.find(
            (d) => d.toUpperCase() === name.toUpperCase().replace(/\s\s+/g, ' ')
          )
        ) {
          toast.error('Field Already Exists');
        } else {
          response = await axios.post(
            `${SERVER_BASE_URL}/customer/BOConfiguration/addAdditionalField`,
            [
              {
                customerNumber: customerNumber,
                headerName: name.replace(/\s\s+/g, ' '),
                headerDataType: format,
                value: ''
              }
            ],

            config
          );
        }

        const fetchBulkOrder = await axios.get(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BOConfiguration`,
          config
        );

        dispatch({
          type: ActionTypes.GET_BULK_ORDER_DATA,
          payload: fetchBulkOrder.data
        });
        return response;
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }

        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const deleteBulkOrderConfig =
  (authToken, data, id) => async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };

      const response = await axios.delete(
        `${SERVER_BASE_URL}/customer/${data?.customerNumber}/additionalBOConfiguration/delete?headerName=${data?.headerName}`,

        config
      );

      const getBulkOrderConfig = await axios.get(
        `${SERVER_BASE_URL}/customer/${data?.customerNumber}/BOConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_ORDER_DATA,
        payload: getBulkOrderConfig.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const saveBulkOrderData =
  (authToken, values, addValues, customerNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/customer/BOConfiguration/addOrUpdate`,

        { ...values, customerNumber: customerNumber },

        config
      );

      const saveAddBulkOrderData = await axios.post(
        `${SERVER_BASE_URL}/customer/BOConfiguration/addAdditionalField`,
        addValues,
        config
      );

      const getBulkOrderConfig = await axios.get(
        `${SERVER_BASE_URL}/customer/${customerNumber}/BOConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_ORDER_DATA,

        payload: getBulkOrderConfig.data
      });
      toast.success('Bulk order configuration got updated successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }

      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const deleteBOUploadHistory =
  (id, customerNumber, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.delete(
        `${SERVER_BASE_URL}/customer/BOUploadHistory/delete/${id}`,
        config
      );
      toast.success('History removed successfully.');

    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
    $('#BulkOrderHistoryTable').DataTable().ajax.reload();
  };

export const validateBulkOrder =
  (
    bulkhistoryId,
    customerNumber,
    authToken,
    setOpenTableModal,
    setBulkOrderHistoryId
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setOpenTableModal(true);
        const response = await axios.get(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BOConfiguration/validate/${bulkhistoryId}`,
          config
        );

        if (response) {
          setBulkOrderHistoryId(bulkhistoryId);
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
        setOpenTableModal(false);
      }
    };

export const uploadBulkOrder =
  (
    customerNumber,
    spaces,
    file,
    authToken,
    setOpenTableModal,
    setBulkOrderHistoryId
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setOpenTableModal(true);
        let data;
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BOConfiguration/uploadExcel?shouldSpaceBeRemoved=${spaces}`,
          file,
          config
        );

        if (response && response?.data?.id) {
          setBulkOrderHistoryId(response?.data?.id);
        }

        toast.success('File uploaded successfully.');
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const placeAllValidBulkOrder =
  (customerNumber, id, authToken, handleModalClose, gridRef) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/placeAllValidOrders/${customerNumber}/${id}`,
          {},
          config
        );

        if (response) {
          handleModalClose(true);
        }
        toast.success('Orders are placed successfully.');
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const placeBulkOrder =
  (customerNumber, id, orders, authToken, handleModalClose) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {

        if (orders.length !== 0) {
          const response = await axios.post(
            `${SERVER_BASE_URL}/customer/${customerNumber}/BOConfiguration/placeOrder/${id}`,
            orders,
            config
          );

          if (response) {
            handleModalClose(true);
          }

          dispatch({
            type: ActionTypes.GET_FORECAST_UPLOAD_HISTORY,
            payload: response.data
          });
          toast.success('Orders are placed successfully.');
        } else {
          toast.error('No valid orders selected.');
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const DownloadBulkOrder =
  (fileName, extension, authToken) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Disposition': `attachment; filename=template.${extension}`,
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      };
      const response = await axios.get(
        `${SERVER_BASE_URL}/file/download?fileName=${fileName}`,
        config
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };
