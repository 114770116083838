import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import User_Setup from '../../../../assets/Icons/User_setup.svg';
import Access_Portal from '../../../../assets/Icons/Access_portal.svg';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { addSuppliers } from 'src/redux/actions/sysAdminActions';

import {
  Box,
  Button,
  styled,
  TextField
} from '@mui/material';

import './DataGrid.css';

import { useDispatch, useSelector } from 'react-redux';

import CustomModal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import DataTable from 'src/components/DataTables/DataTable';
import InfoIcon from '../../../../assets/Icons/infoIcon.svg';
import SERVER_BASE_URL from 'src/config/config';
import $ from 'jquery';

const AddButton = styled(Button)(
  () => `
        color: white !important;
        background: #15364A  !important;
        border-radius: 3px  !important;
        height: 38px !important;
        width: 63px !important

        :hover {
            background-color:  #022f53  !important;
          }
        :disabled {
          color: #aeaeae !important;
        }
        `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);

const CancelButton = styled(Button)(
  () => `
  background: #FFFFFF  !important;
  border: 1px solid #15364A  !important;
  border-radius: 3px  !important;
  color: #15364A  !important;
  height: 38px !important;
  width: 84px !important
      
          :hover {
              background-color: white  !important;
            }
          
          `
);

const CustomInput = styled(TextField)(
  () => `
    .MuiInputBase-root {
      height: 36px;
      background: #F4F7F9 ;
      border-radius: 4px;
      width: 100%;
    }
  
          :after{
            border-bottom: unset !important;
  
          }
          :before{
            border-bottom: unset !important;
  
          }
  
          .Mui-disabled:after {
            border-bottom: unset !important;
            border-bottom-style: solid;
          }
          .Mui-disabled:before {
            border-bottom: unset;
            border-bottom-style: solid;
          }
          .Mui-disabled {
            color: white !important;
            background: #f5f5f5;
          }
        `
);

const TableContainer = styled(Box)(
  () => `
  padding: 15px 24px;
  background-color: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  border-radius: 3px;

  `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 4,
  bordeRadius: '5px'
};

const SupplierTable = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state?.auth?.token?.token);

  const [modalOpen, setModalOpen] = React.useState(false);

  const [supplierDataTableData, setSupplierDataTableData] = useState(false);
  const [supplierDataTableColumns, setSupplierDataTableColumns] = useState(false);
  const [supplierDataTableOptions, setSupplierDataTableOptions] = useState(false);

  const [supplierNumber, setSupplierNumber] = React.useState('');

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', margin: '12px 0' }),
    []
  );

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setSupplierNumber('');
    setModalOpen(false);
    $('#ManageSuppliersTable').DataTable().ajax.reload();
  };

  const handleAddSupplier = (e) => {
    e.preventDefault();
    dispatch(
      addSuppliers(supplierNumber, setModalOpen, setSupplierNumber, authToken)
    );
  };

  //Setting up Supplier Columns
  useEffect(() => {
    let columns = [
      {
        title: "Supplier Number",
        name: "suppliernumber",
        data: "supplierNumber"
      },
      {
        title: "Supplier Name",
        name: "suppliername",
        data: "supplierName"
      },
      {
        title: "Status",
        name: "status",
        data: "status",
        width: "120px",
        render: function (data, type, row, meta) {
          var backgroundColor = (data.description === 'Active') ? "#46bd60" :
            (data.description === 'Inactive') ? "#FF4A4A" :
              "#555555";
          return `
            <div>
              <span
                style="
                  border-radius: 30px;
                  text-align: center;
                  padding: 4px 20px;
                  height: 22px;
                  color: white;
                  background: ${backgroundColor};      
                  margin-top: -2px;"
              >
                ${data.description}
              </span>
            </div>`;
        },
      },
      {
        title: "Action",
        name: "action",
        data: "id",
        //'/supplier-portal/${data}/poview'
        render: function (data, type, row, meta) {
          var supplierPortalLink = (row.status.description === 'Active') ? `
            <Tooltip title="Access Portal" arrow>
                <img
                  className="Img"
                  disabled=${row.status.description === 'Inactive'
              ? true
              : false || row.status.description === 'Pending Setup'
                ? true
                : false
            }
                  style=
                    "cursor: pointer"
                    opacity:${(row.status.description === 'Inactive') || (row.status.description === 'Pending Setup') ? '0.5' : '1'
            };
                    onclick="${(row.status.description === 'Inactive' || row.status.description === 'Pending Setup') ? '' : `document.location='/supplier-portal/${data}/poview';`}"
                  src=${Access_Portal}
                ></img>
              </Link>
            </Tooltip> `
            : `
              <img
                className="Img"
                disabled=${row.status.description === 'Inactive'
              ? true
              : false || row.status.description === 'Pending Setup'
                ? true
                : false
            }
                style=
                opacity:.5
            }"
                onclick="${(row.status.description === 'Inactive' || row.status.description === 'Pending Setup') ? '' : `document.location='/supplier-portal/${data}/poview';`}"
                src=${Access_Portal}
              ></img>`;

          return `
            <div style= margin: '5px 0 0 0' >
              <Box>
                <Tooltip title="Supplier Setup" arrow>
                  <img
                    className="Img customer_setup"
                    style="cursor: pointer"
                    onclick="document.location='/manage-suppliers/${data}';"
                    src=${User_Setup}
                  ></img>
                </Tooltip>
                <Box>${supplierPortalLink}</Box>
              </Box>
            </div>`;
        }
      }
    ]
    setSupplierDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const SupplierDataTableButtons = () => {
    let buttons = [];
    buttons.push({
      text: 'Add Supplier',
      className: 'btn-dark me-1',
      action: function (e, dt, node, config) {
        handleOpen();
      }
    });
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };


  //Setting up configuration
  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: SupplierDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/supplier/getAllSuppliers`,
        type: "GET",
        dataSrc: function (data) {
          setSupplierDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        contentType: 'application/json',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: true,
      pageLength: 10,
      fixedHeader: true,
      scrollY: '450px',
      order: [[2, 'asc']],
      searching: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setSupplierDataTableOptions(options);
  }, []);

  return (
    <div className="custom-pg-content-sec">
      <Box
        className="custom-pg-titlesticky-sec"
        component="form"
        sx={{
          '& > :not(style)': { ml: 0 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box className="custom-heading-container-sec" sx={{}}>
          <span
            className="custom-heading-sec"
            style={{
              fontSize: '20px',
              fontWeight: 700,
              fontWeight: 700,
              display: 'inline-flex',
              flexDirection: 'column'
            }}
          >
            Manage Suppliers
            <label
              style={{
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1',
                marginTop: '12px'
              }}
            >
              Supplier List
            </label>
          </span>{' '}
        </Box>

        <Box className="custom-heading-actionable-sec" sx={{}}>
          <Box sx={{ paddingLeft: '15px' }}>
            <CustomModal keepMounted open={modalOpen} onClose={handleModalClose}>
              <Box sx={style}>
                <div>
                  <Typography
                    id="keep-mounted-modal-title"
                    variant="h3"
                    component="h2"
                  >
                    Add Supplier
                  </Typography>
                </div>
                <div style={{ marginTop: '30px' }}>
                  <form onSubmit={handleAddSupplier}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sx={{ margin: '10px 0' }}>
                        <Box sx={{ display: 'flex' }}>
                          <LabelBox sx={{ flexGrow: 1 }}>
                            Enter Supplier Number
                            <span style={{ color: 'red' }}> *</span>
                          </LabelBox>
                          <Tooltip
                            title={
                              <ul>
                                <li>
                                  Add by comma seperated to load more supplier
                                  numbers
                                </li>
                                <li>Only numbers are allowed to enter!</li>
                              </ul>
                            }
                            disableInteractive
                          >
                            <img src={InfoIcon}></img>
                          </Tooltip>
                        </Box>

                        <CustomInput
                          name="firstName"
                          onChange={(e) => setSupplierNumber(e.target.value)}
                          fullWidth
                          value={supplierNumber}
                          autoComplete="none"
                          type="text"
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        '& > :not(style)': { ml: 2, mt: 3 },
                        display: 'flex',
                        flexDirection: 'row-reverse'
                      }}
                    >
                      <Grid item xs={6} md={6}>
                        <AddButton
                          disabled={!supplierNumber}
                          // onClick={(e) => handleSaveClick(e)}
                          type="submit"
                        >
                          Add
                        </AddButton>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <CancelButton onClick={handleModalClose}>Cancel</CancelButton>
                      </Grid>
                    </Box>
                  </form>
                </div>
              </Box>
            </CustomModal>
          </Box>
        </Box>
      </Box>
      <div className="custom-pg-table-content-sec">
        <TableContainer style={gridStyle}>
          {supplierDataTableColumns && supplierDataTableOptions && (
            <DataTable
              id={'ManageSuppliersTable'}
              columns={supplierDataTableColumns}
              options={supplierDataTableOptions}
            />
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default SupplierTable;
