import * as React from 'react';
import { addUser, getAllUsers, getUser } from 'src/redux/actions/userActions';
import UserForm from './poSalesView';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
    submitPOChangeRequest
  } from 'src/redux/actions/SupplierPortalActions';

const useFormControls = (
  selectedData,
  handleModalClose,
  handleDeselect,
  setSelectedData,
  setPage
) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // We'll update "values" as the form updates
  const [values, setValues] = React.useState({});
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const authUser = useSelector((state) => state?.auth?.authUserData);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = React.useState({});
  const [submit, setSubmit] = React.useState(false);
  const supplierData = useSelector((state) => state?.suppliers?.supplierData);
  const resetFields = () => { };

  
  React.useEffect(() => {
    const valuesObj = {};
    Object.keys(selectedData).forEach((page) => {
      valuesObj[page] = selectedData[page].map(
        ({ uom, dueDate, poNumber, poLineNumber, quantity, poCost, costUom, purchaseOrderId, remarks }) => ({
          uom,
          cost: poCost,
          oldCost: poCost,
          costUom: costUom,
          quantity: quantity,
          oldQuantity: quantity,
          poNumber: poNumber,
          poLineNumber: poLineNumber,
          supplierId: supplierData?.id,
          supplierNumber: supplierData?.supplierNumber,
          dueDate: dueDate,
          oldDueDate: dueDate,
          purchaseOrderI5Id: purchaseOrderId,
          remarks: remarks,
        })
      );
    });
    setValues({ ...valuesObj });
  }, [selectedData]);

  

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ('quantity' in fieldValues) {
      temp.quantity = fieldValues.quantity ? '' : 'This field is required.';
    }

    if ('cost' in fieldValues) {
      temp.cost = fieldValues.cost ? '' : 'This field is required.';
    }

    if ('remarks' in fieldValues) {
      temp.remarks = fieldValues.remarks ? '' : 'This field is required.';
    }

    if ('dueDate' in fieldValues) {
      temp.dueDate = fieldValues.dueDate ? '' : 'This field is required.';
      temp.dueDate = fieldValues.dueDate >= Date.now ? '' : 'Due date must be after today.';
    }
    setErrors({
      ...temp
    });
  };

  
  const removeErrorOnChange = ({ id, partId }) => {
    // call an action to clear the error of the field
  };

  const handleInputValue = (e, type, nm, i, j) => {
    
    let obj = values[i]?.[j];
    const purchaseOrderI5Id = obj.purchaseOrderI5Id;
   
    if (type === 'date') {
        removeErrorOnChange({ id: 'dueDate', purchaseOrderI5Id });
        obj = {
            ...obj,
            dueDate: moment(e).format('MM/DD/YYYY')
        };
        validate({ e });
    }

    if (nm === 'quantity') {
      removeErrorOnChange({ id: e.target.name, purchaseOrderI5Id });
        e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 10);
        obj = {
            ...obj,
            [e?.target.name]: e?.target.value
        };

        validate({ [e?.target.name]: e?.target.value });
    }

    if (nm === 'cost') {
        removeErrorOnChange({ id: e.target.name, purchaseOrderI5Id });
        e.target.value = Math.max(0, parseFloat(e.target.value))
          .toString();
        obj = {
            ...obj,
            [e?.target.name]: e?.target.value
        };
  
        validate({ [e?.target.name]: e?.target.value });
      }

    if (type === 'select_string') {
      obj = {
        ...obj,

        [nm]: e?.value
      };
    }
    values[i][j] = obj;
  
    setValues({ ...values });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let valueArray = Object.values(values)?.flat();
    valueArray.forEach((s, index) => {
      if(s.remarks == undefined){
        s.remarks = 'Supplier request';
      }
    });

    if (formIsValid()) {
      dispatch(
        submitPOChangeRequest(
          valueArray,
          supplierData?.id,
          authToken,
          handleModalClose,
          handleDeselect,
          setSelectedData,
          setSubmit,
          setPage
        )
      );
    }
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
        // fieldValues?.quantity &&
        // fieldValues?.dueDate &&
        // fieldValues?.remarks &&

      Object.values(errors).every((x) => x === '');

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    resetFields,
    values,
    setValues,
    submit,
    setSubmit
  };
};

export default useFormControls;
