import axios from 'axios';
import { ActionTypes } from '../constants/action-types';
import SERVER_BASE_URL from '../../config/config';
import { toast } from 'react-toastify';
import $ from 'jquery';

export const addOrUpdateBlanketPo =
  (authToken, name, format, customerNumber, BlanketPoData, existingFields) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        if (BlanketPoData?.find((d) => d?.headerName === name) || existingFields?.find((d) => d.toUpperCase() === name.toUpperCase())) {
          toast.error('Field Already Exists');
        } else {
          const response = await axios.post(
            `${SERVER_BASE_URL}/customer/BPOConfiguration/addAdditionalField`,
            [
              {
                customerNumber: customerNumber,
                headerName: name,

                headerDataType: format,
                value: ''
              }
            ],

            config
          );
        }

        const fetchBlanketPo = await axios.get(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BPOConfiguration`,
          config
        );

        dispatch({
          type: ActionTypes.GET_BLANKET_PO_DATA,
          payload: fetchBlanketPo.data
        });
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });

        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const getBlanketPoData =
  (authToken, customerNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/customer/${customerNumber}/BPOConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BLANKET_PO_DATA,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });

      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const deleteBlanketPoConfig =
  (authToken, data, id) => async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      const response = await axios.delete(
        `${SERVER_BASE_URL}/customer/${data?.customerNumber}/AdditionalBPOConfiguration/delete?headerName=${data?.headerName}`,

        config
      );

      const getBlanketPoConfig = await axios.get(
        `${SERVER_BASE_URL}/customer/${data.customerNumber}/BPOConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BLANKET_PO_DATA,
        payload: getBlanketPoConfig.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });

      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const saveBlanketPO =
  (authToken, values, addValues, customerNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/customer/BPOConfiguration/addOrUpdate`,

        { ...values, customerNumber: customerNumber },

        config
      );

      const saveAddBlanketPO = await axios.post(
        `${SERVER_BASE_URL}/customer/BPOConfiguration/addAdditionalField`,
        addValues,
        config
      );

      const getBlanketPoConfig = await axios.get(
        `${SERVER_BASE_URL}/customer/${customerNumber}/BPOConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BLANKET_PO_DATA,
        payload: getBlanketPoConfig.data
      });
      toast.success('Blanket PO configuration got updated successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });


      }

      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const deleteBPOHistory =
  (id, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.delete(
        `${SERVER_BASE_URL}/customer/BPOUploadHistory/delete/${id}`,
        config
      );
      toast.success('History removed successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });

      }
      toast.error(e?.response?.data?.errorDescription);
    }
    $('#BlanketPOHistoryTable').DataTable().ajax.reload();
  };


export const uploadBlanketPO =
  (customerNumber, spaces, file, authToken, setTableModalOpen, setBlanketPoHistoryId) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setTableModalOpen(true);
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BPOConfiguration/uploadExcel?shouldSpaceBeRemoved=${spaces}`,
          file,
          config
        );

        if (response && response?.data?.id) {
          setBlanketPoHistoryId(response?.data?.id);
        }

        toast.success('File uploaded successfully.');
        setTableModalOpen(true);
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });

        }
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const placeBlanketPO =
  (customerNumber, id, orders, handleTableModalClose, setPlaceOrder, authToken) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BPOConfiguration/uploadParts/${id}`,
          orders,
          config
        );

        if (response) {
          toast.success('Blanket PO parts are uploaded successfully.');
          handleTableModalClose();
        }

      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        toast.error(e?.response?.data?.errorDescription);
      }
      setPlaceOrder(false);
    };

export const validateBlanketPo =
  (blanketPoHistoryId, customerNumber, authToken, setOpenTableModal, setBlanketPoHistoryId) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      try {
        setOpenTableModal(true);
        const response = await axios.get(
          `${SERVER_BASE_URL}/customer/${customerNumber}/BPOConfiguration/validate/${blanketPoHistoryId}`,
          config
        );

        if (response) {
          setBlanketPoHistoryId(blanketPoHistoryId);
        }

        toast.success('File Uploaded Successfully');
      } catch (e) {
        if (e?.response?.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT
          });
        }
        setOpenTableModal(false);
        toast.error(e?.response?.data?.errorDescription);
      }
    };
