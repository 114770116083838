import * as React from 'react';
import Box from '@mui/material/Box';
import Select from 'react-select';
import {
  Button,
  Grid,
  Input,
  styled,
  TextField,
  Typography
} from '@mui/material';

import Switch, { SwitchProps } from '@mui/material/Switch';
import DeleteIcon from '../../../../../../assets/Icons/Delete.png';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import CustomModal from '@mui/material/Modal';
import $ from 'jquery';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BulkPOHistoryTable from './Table';
import {
  addOrUpdateOrderChangeRequest,
  getBulkPOChangeRequestData,
  getBulkPOChangeRequestConfig,
  deleteBulkPOChangeRequestConfig,
  saveBulkPOChangeRequestData
} from 'src/redux/actions/SupplierPortalActions';
import { advanceTime } from 'src/services/Time-tracking';
import useFormControls from '../Validations';
import Breadcrumb from 'src/components/Breadcrumb';
import { timeCalculation } from 'src/redux/actions/DashboardActions';

const DownloadButton = styled(Button)(
  () => `
          color: white !important;
          background: #15364A  !important;
          border-radius: 3px  !important;
          height: 38px !important;
          width: 130px !important;
  
          :hover {
              background-color:  #022f53  !important;
            }
          :disabled {
            color: #aeaeae !important;
          }
          `
);

const AddButton = styled(Button)(
  () => `
          color: white !important;
          background: #15364A  !important;
          border-radius: 3px  !important;
          height: 38px !important;
          width: 63px !important
  
          :hover {
              background-color:  #022f53  !important;
            }
          :disabled {
            color: #aeaeae !important;
          }
          `
);
const CancelButton = styled(Button)(
  () => `
    background: #FFFFFF  !important;
    border: 1px solid #15364A  !important;
    border-radius: 3px  !important;
    color: #15364A  !important;
    height: 38px !important;
    width: 84px !important
        
            :hover {
                background-color: white  !important;
              }
            
            `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);

const AddMoreButton = styled(Button)(
  () => `
  background: #FFFFFF !important;
border: 1px solid #15364A !important;
border-radius: 3px !important;
color: #15364A !important;

height: 36px; !important;
  `
);

const ContentContainer = styled(Box)(
  () => `
    padding: 0px 31px 0px 31px;
    background-color:#FFFFFF;
    
    `
);

const Header = styled(Box)(
  () => `
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    padding: 28px 0 16px 0;
    `
);
const SubContentContainer = styled(Box)(
  () => `
      border-bottom: 2px solid #D9D9D9;
    `
);


const HeaderBulkPO = styled(Box)(
  () => `  
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;  
  color: #343D42;
    `
);

const CustomInput = styled(TextField)(
  () => `
    .MuiInputBase-root {
      height: 36px;
      background: #F4F7F9 ;
      border-radius: 4px;
      width: 100%;
    }
  
          :after{
            border-bottom: unset !important;
  
          }
          :before{
            border-bottom: unset !important;
  
          }
  
          .Mui-disabled:after {
            border-bottom: unset !important;
            border-bottom-style: solid;
          }
          .Mui-disabled:before {
            border-bottom: unset;
            border-bottom-style: solid;
          }
          .Mui-disabled {
            color: white !important;
            background: #f5f5f5;
          }
        `
);

export default function BulkPOChangeRequestConfig({ SupplierPortal }) {
  const [time, setTime] = React.useState({
    seconds: 0,
    minutes: 0,
    hours: 0
  });
  const dispatch = useDispatch();
  const supplierData = useSelector((state) => state?.suppliers?.supplierData);

  const BulkPOChangeRequestConfig = useSelector((state) => state?.supplierPortal?.getBulkPOChangeRequestConfig);
  const BulkPOChangeRequestData = useSelector((state) => state?.supplierPortal?.getBulkPOChangeRequestData);

  const authToken = useSelector((state) => state?.auth?.token?.token);
  const authUser = useSelector((state) => state?.auth?.authUserData);

  const [fields, setFields] = React.useState([]);
  const [headerName, setHeaderName] = React.useState();
  const [values, setValues] = React.useState({});
  const [csvData, setCsvData] = React.useState([]);
  const [defaultCsvValues, setDefaultCsvValues] = React.useState([]);
  const [addValues, setAddValues] = React.useState([]);
  const [browserClosed, setBrowserClosed] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [name, setName] = React.useState('');
  const [format, setFormat] = React.useState('');
  const [deleteField, setDeleteField] = React.useState('');
  const [headerDataType, setHeaderDataType] = React.useState(null);
  const [del, setDel] = React.useState(false);
  const [startTime, setStartTime] = React.useState(0);
  const [endtime, setendTime] = React.useState(0);
  const TopBox = styled(Box)(
    () => `
    
     padding: ${SupplierPortal ? '0px' : '56px 0px 0 0'};
     
     background: ${SupplierPortal ? '' : 'white'};
     color: #EB4F0C;
     
  font-weight: 700;
  font-size: 16px;
      
      `
  );

  React.useEffect(() => {
    const initialFormValues = {
      quantity: BulkPOChangeRequestConfig?.quantity,
      dueDate: BulkPOChangeRequestConfig?.dueDate,
      cost: BulkPOChangeRequestConfig?.cost,
      reason: BulkPOChangeRequestConfig?.reason,
      poNumber: BulkPOChangeRequestConfig?.poNumber,
      poLineNumber: BulkPOChangeRequestConfig?.poLineNumber,
      supplierNumber: BulkPOChangeRequestConfig?.supplierNumber
    };
    const intitialCsvData =
      BulkPOChangeRequestConfig && BulkPOChangeRequestConfig?.exportData
        ? BulkPOChangeRequestConfig?.exportData?.map((d) => ({
            headerName: d?.dataName,
            value: d?.dataValue
          }))
        : [
            {
              headerName: '',
              value: ''
            }
          ];

    setValues(initialFormValues);
    setCsvData(intitialCsvData);
  }, [BulkPOChangeRequestConfig]);

  const { handleInputValue, formIsValid, errors, handleFormSubmit } =
    useFormControls(values, addValues, 'Bulk PO Change Request');

  React.useEffect(() => {
    dispatch(getBulkPOChangeRequestConfig(authToken, supplierData?.supplierNumber));
    
  }, []);
  
  React.useEffect(() => {
    if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_SUPPLIER')) {
      let isCancelled = false;

      advanceTime(time, isCancelled, setTime, 'Bulk PO Change Request');

      return () => {
        isCancelled = true;
        localStorage.setItem('componentTime', JSON.stringify(time));
      };
    }
  }, [time]);

  React.useEffect(() => {
    return () => {
      const value = JSON.parse(localStorage.getItem('componentTime'));

      if (value?.seconds > 59) {
        dispatch(
          timeCalculation(
            authUser?.email,
            'Bulk PO Change Request',
            value?.seconds,
            authUser?.associatedSupplierId
          )
        );
      }

     
      localStorage.clear();
    };
  }, []);

  React.useEffect(() => {
    if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_SUPPLIER')) {
      window.addEventListener('beforeunload', handleUnload);
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
  }, []);

  function handleUnload(event) {
    event.preventDefault();
    event.returnValue = '';

    // Make API call here
    const value = JSON.parse(localStorage.getItem('componentTime'));
    if (value?.seconds > 59) {
      dispatch(
        timeCalculation(
          authUser?.email,
          'Bulk PO Change Request',
          value?.seconds,
          authUser?.associatedSupplierId
        )
      );
      localStorage.clear();
    }
  }

  const handleClickOpen = (field, i) => {
    setOpen(true);
    setDeleteField(field);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setName('');
    setFormat('');
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdditionalFormValues = (e, i, dataType) => {
    let arr = addValues;

    const index = arr.findIndex((a) => a.headerName === e?.target.name);

    if (index != -1) {
      arr[index] = {
        headerName: e?.target.name,
        value: e?.target.value,
        headerDataType: dataType,
        supplierNumber: supplierData?.supplierNumber
      };
    } else {
      arr = [
        ...arr,
        {
          headerName: e?.target.name,
          value: e?.target.value,
          headerDataType: dataType,
          supplierNumber: supplierData?.supplierNumber
        }
      ];
    }

    setAddValues(arr);
  };

  const onNewFormSubmit = (e) => {
    e?.preventDefault();
    const existingFields = [
      'Quantity',
      'PO Number',
      'PO Line Number',
      'Cost',
      'Due Date',
      'Reason'
    ];

    handleFormSubmit(
      e,
      dispatch(
        addOrUpdateBulkPO(
          authToken,
          name,
          format,
          supplierData?.supplierNumber,
          BulkPOChangeRequestConfig?.BulkPOAdditionalConfigurationDtoList,
          existingFields
        )
      )
    );

    setOpenModal(false);
    setName('');
    setFormat('');
  };

  const onDelete = (e, i, data) => {
    dispatch(
      deleteBulkPOConfig(authToken, data, supplierData?.supplierNumber)
    );
    setAddValues([]);

    handleClose();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', sm: '100%' },
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 4,
    bordeRadius: '5px'
  };

  const inputTypeOption = [
    { label: 'Numeric', value: 'integer' },
    { label: 'Date', value: 'date' },
    { label: 'Text', value: 'text' }
  ];

  // const resetFields =()=>{
  //   setValues({})

  // }
  //

  return (
    <>
      {SupplierPortal && (
        <Box sx={{ margin: '32px 0 10px 0' }}>
          <Breadcrumb
            userData={supplierData}
            location={[
              {
                location: 'Home',
                to: `/supplier-portal/${supplierData?.id}/products`
              }
            ]}
            page={'Bulk PO Change Request'}
          />
        </Box>
      )}

      <TopBox>
        <Box
          sx={{
            display: { md: 'flex', xs: 'box' },
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {!SupplierPortal ? (
              <div>
                <span style={{ fontWeight: '400', fontSize: '20px' }}>
                  {/* Supplier Number : {supplierData?.supplierNumber} Supplier Name
                  |{' ' + supplierData?.supplierName} */}
                </span>
              </div>
            ) : (
              <Box
                sx={{
                  display: { md: 'flex', xs: 'box' },
                  flexDirection: 'row'
                }}
              >
                <HeaderBulkPO flexGrow={1}>Bulk PO Change Request</HeaderBulkPO>
                <Box
                  sx={{
                    '& > :not(style)': { ml: 1 },
                    display: { md: 'flex', xs: 'box' },
                    flexDirection: 'row'
                  }}
                >
                </Box>
              </Box>
            )}
          </Box>
          <Box style={{ marginRight: SupplierPortal ? 'unset' : '18px' }}>
            {!SupplierPortal && (
              <AddButton
                className="save-Button"
                type="submit"
                onClick={handleFormSubmit}
                disabled={!formIsValid()}
              >
                Save
              </AddButton>
            )}
          </Box>
          {/* <Box
            sx={{
              '& > :not(style)': { ml: 2 }
            }}
          > */}
          <CSVLink data={csvData} filename={'BulkUpload_config.csv'}>
            <DownloadButton>Download Config </DownloadButton>
          </CSVLink>
          {/* </Box> */}
        </Box>
      </TopBox>
      <div>
        <Header>Field Name</Header>
        <SubContentContainer>
          <form>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {BulkPOChangeRequestConfig && (
                <Grid item xs={4} sx={{ margin: '10px 0' }}>
                  <LabelBox>Quantity</LabelBox>

                  <CustomInput
                    key={BulkPOChangeRequestConfig?.quantity}
                    oninput="validity.valid||(value='')"
                    name="quantity"
                    fullWidth
                    inputProps={{ min: 1 }}
                    autoComplete="none"
                    {...(errors['quantity'] && {
                      error: true,
                      helperText: errors['quantity']
                    })}
                    className="basic-multi-select2 input_box"
                    disabled={SupplierPortal}
                    type={'number'}
                    defaultValue={BulkPOChangeRequestConfig?.quantity}
                    onChange={(e) => handleInputValue(e, 'string', '')}
                  />
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
              )}

              {BulkPOChangeRequestConfig && (
                <Grid item xs={4} sx={{ margin: '10px 0' }}>
                  <LabelBox>
                    Due Date 
                  </LabelBox>

                  <CustomInput
                    key={BulkPOChangeRequestConfig?.dueDate}
                    name="dueDate"
                    fullWidth
                    inputProps={{ min: '1' }}
                    {...(errors['dueDate'] && {
                      error: true,
                      helperText: errors['dueDate']
                    })}
                    autoComplete="none"
                    disabled={SupplierPortal}
                    className="basic-multi-select4 input_box"
                    type={'number'}
                    defaultValue={BulkPOChangeRequestConfig?.dueDate}
                    onChange={(e) => handleInputValue(e, 'string', '')}
                  />
                  <Grid item xs={2}></Grid>
                </Grid>
              )}

              {BulkPOChangeRequestConfig && (
                <Grid item xs={4} sx={{ margin: '10px 0' }}>
                  <LabelBox>
                    Cost 
                  </LabelBox>

                  <CustomInput
                    key={BulkPOChangeRequestConfig?.cost}
                    name="cost"
                    fullWidth
                    inputProps={{ min: '1' }}
                    autoComplete="none"
                    {...(errors['cost'] && {
                      error: true,
                      helperText: errors['cost']
                    })}
                    className="basic-multi-select5 input_box"
                    disabled={SupplierPortal}
                    type={'number'}
                    defaultValue={BulkPOChangeRequestConfig?.cost}
                    onChange={(e) => handleInputValue(e, 'string', '')}
                  />
                  <Grid item xs={2}></Grid>
                </Grid>
              )}

              {BulkPOChangeRequestConfig && (
                <Grid item xs={4} sx={{ margin: '10px 0' }}>
                  <LabelBox>
                    Reason 
                  </LabelBox>

                  <CustomInput
                    key={BulkPOChangeRequestConfig?.reason}
                    name="reason"
                    fullWidth
                    inputProps={{ min: '1' }}
                    autoComplete="none"
                    disabled={SupplierPortal}
                    className="basic-multi-select6 input_box"
                    {...(errors['reason'] && {
                      error: true,
                      helperText: errors['reason']
                    })}
                    type={'number'}
                    defaultValue={BulkPOChangeRequestConfig?.reason}
                    onChange={(e) => handleInputValue(e, 'string', '')}
                  />
                  <Grid item xs={2}></Grid>
                </Grid>
              )}

              {BulkPOChangeRequestConfig && (
                <Grid item xs={4} sx={{ margin: '10px 0' }}>
                  <LabelBox>
                    PO Number <snap style={{ color: 'red' }}>*</snap>
                  </LabelBox>

                  <CustomInput
                    key={BulkPOChangeRequestConfig?.poNumber}
                    name="poNumber"
                    fullWidth
                    inputProps={{ min: '1' }}
                    autoComplete="none"
                    {...(errors['poNumber'] && {
                      error: true,
                      helperText: errors['poNumber']
                    })}
                    disabled={SupplierPortal}
                    className="basic-multi-select7 input_box"
                    type={'number'}
                    defaultValue={BulkPOChangeRequestConfig?.poNumber}
                    onChange={(e) => handleInputValue(e, 'string', '')}
                  />
                  <Grid item xs={2}></Grid>
                </Grid>
              )}

              {BulkPOChangeRequestConfig && (
                <Grid item xs={4} sx={{ margin: '10px 0' }}>
                  <LabelBox>
                    PO Line Number <snap style={{ color: 'red' }}>*</snap>
                  </LabelBox>

                  <CustomInput
                    name="poLineNumber"
                    key={BulkPOChangeRequestConfig?.poLineNumber}
                    fullWidth
                    inputProps={{ min: '1' }}
                    autoComplete="none"
                    {...(errors['poLineNumber'] && {
                      error: true,
                      helperText: errors['poLineNumber']
                    })}
                    disabled={SupplierPortal}
                    className="basic-multi-select11 input_box"
                    type={'number'}
                    defaultValue={BulkPOChangeRequestConfig?.poLineNumber}
                    onChange={(e) => handleInputValue(e, 'string', '')}
                  />
                  <Grid item xs={2}></Grid>
                </Grid>
              )}

              <Grid item xs={4} sx={{ margin: '10px 0' }}>
                <br></br>
                {!SupplierPortal && (
                  <AddMoreButton
                    className="Add-more"
                    onClick={handleModalOpen}
                    style={{
                      backgroundColor: '#15364A',
                      color: 'white',
                      padding: '10px 15px',
                      cursor: 'pointer'
                    }}
                  >
                    Add new field
                  </AddMoreButton>
                )}
              </Grid>
            </Grid>{' '}
            <Box sx={{ mt: 2, mb: 3 }}></Box>
          </form>
        </SubContentContainer>
        <Box sx={{ margin: '40px 0' }}>
          <BulkPOHistoryTable />
        </Box>
      </div>
      <CustomModal
        className="custom-model"
        open={openModal}
        onClose={handleModalClose}
      >
        <Box sx={style}>
          <div>
            <Typography variant="h3" component="h2">
              Add New Field
            </Typography>
          </div>
          <div style={{ marginTop: '30px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sx={{ margin: '10px 0' }}>
                <LabelBox>Field Name</LabelBox>
                <CustomInput
                  value={name}
                  type="text"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '10px 0' }}>
                <LabelBox>Field Format</LabelBox>
                <Select
                  value={{ label: format, value: format }}
                  name="preferredUOM"
                  options={inputTypeOption}
                  className="basic-multi-select1 input_box"
                  classNamePrefix="select"
                  onChange={(e) => setFormat(e.value)}
                />
              </Grid>
            </Grid>{' '}
            <Box
              sx={{
                '& > :not(style)': { mt: 3 },
                display: 'flex',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <Grid item xs={6} md={6}>
                <AddButton
                  className="Add-Button"
                  onClick={(e) => onNewFormSubmit(e)}
                  disabled={!format || !name ? true : false}
                  type="submit"
                >
                  Add
                </AddButton>
              </Grid>
              <Grid item xs={6} md={6}>
                <CancelButton type="reset" onClick={handleModalClose}>
                  Cancel
                </CancelButton>
              </Grid>
            </Box>
          </div>
        </Box>
      </CustomModal>
    </>
  );
}
