import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRawMaterialData, clearData } from 'src/redux/actions/rawMaterialActions';
import './../RawMaterialAnalysis.css'; // Import the CSS file
import {RotatingLines} from 'react-loader-spinner';
import SERVER_BASE_URL from 'src/config/config';
import axios from 'axios';

let rawData = [];
let totalInventory = 0;

const RawMaterialView = () => {
  const { id, customerNumber } = useParams();
  const [rawMaterialData, setRawMaterialData] = useState(null); // Initialize rawMaterialData as null
  const [isLoading, setIsLoading] = useState(true);
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const [isForecast, setIsForecast] = useState(true);
  const [forecastCount, setForecastCount] = useState(0);
  const [firmCount, setFirmCount] = useState(0);
  const [vaseCount, setVaseCount] = useState(0);
  const [isVase, setIsVase] = useState(true);
  const [sku, setSku] = useState('');
  const [isFirmorders, setIsFirmorders] = useState(true);
  const [initialBalance, setInitialBalance] = useState(0);
  const [initialBalanceUom, setInitialBalanceUom] = useState("");
  //const [totalInventory, setTotalInventory] = useState(0);
  const [initialFGBalance, setInitialFGBalance] = useState(0);
  const [initialAfterFGBalance, setInitialAfterFGBalance] = useState(0);
  const [lastForescastUploadDate, setLastForescastUploadDate] = useState("");
  let tempFilters = [];
  let filteredInventory = [];

  const dispatch = useDispatch();
  const [filters, setFilters] = useState([]);
  let inventoryFirstTime = 1;


  const consolidateFinishedGoodInventory=(data)=>{
    let forecastData=data[0].rawData;
    let finishedGoodInventories=data[0].finishedGoodInventories;
    let totalFinishedGoodInventory=0;
    for (let i = 0; i < finishedGoodInventories.length; i++) {
      let inv=finishedGoodInventories[i]; 
      for (let j = 0; j < forecastData.length; j++) {
        let forecastT=forecastData[j];
        if(forecastT.part.trim()===inv.partnumber.trim() && forecastT.type.trim()==="F"){ 
          if(forecastT.uomForecast.trim()==="PCS"){
            totalFinishedGoodInventory=parseFloat(totalFinishedGoodInventory) + parseFloat(inv.quantity * ( forecastT.totalprocesslength /12 ) );
          }else{
            totalFinishedGoodInventory=parseFloat(totalFinishedGoodInventory) + parseFloat(inv.quantity);
          }
          break;
        }
      }
    }
    return parseFloat(totalFinishedGoodInventory).toFixed(2);
  }

  const consolidateInventory = (inventories) => {
    // apply branch filters
    //filteredInventory = rawMaterialData.length > 0 ? calculateFilteredInventory(rawMaterialData[0].inventories) : [];
    const selectedBranches = filters.filter((inv) => inv.checked).reduce((carry, { branchnumber }) => {
      carry.push(branchnumber);
      return carry;
    }, []);

    filteredInventory = inventories.filter((inv) => {
      return selectedBranches.includes(inv.branchnumber);
    });

    filteredInventory = calculateFilteredInventory(filteredInventory);
    totalInventory = initialBalance;
  }

  const calculateFilteredInventory = (fi) => {
    let tempFi = fi.map((inv) => {
      inv.classD = 'positive-number';
      if (inv.type == 'pos') {
        inv.balance = totalInventory + inv.quantity;
      } else {
        inv.balance = totalInventory - inv.quantity;
        //setTotalInventory(totalInventory - e.quantity);
      }
      if (totalInventory <= 0) {
        inv.classD = 'negative-number';
      }
      //totalInventory = inv.balance;
      return inv;
    });

    return tempFi;
  }

  const displayDaysDataPos = (e, index = 0, extendedUom = "PCS") => {
    if(e.balance){
      return <><td className={e.classD + ' text-right'} title='Balance' >{e.balance} {extendedUom}</td></>;
    }
    return <></>;
  }

  //  load data from sever
  useEffect(() => {
    setIsLoading(true);
    // dispatch(fetchRawMaterialData(id, customerNumber, authToken));
    const fetchData = async () => {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };

      try {
        const response = await axios.get(
          `${SERVER_BASE_URL}/raw-material-depletion-analysis/get/${id}/${customerNumber}`,
          config
        );

        setRawMaterialData(response.data); // Update the state with the fetched data
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData(); // Call the fetchData function to make the AJAX request
    return () => {
      dispatch(clearData()); // Dispatch the action to clear the data when the component unmounts
    };
  }, [id, customerNumber, authToken]);

  useEffect(() => {
    if (rawMaterialData && rawMaterialData.length > 0) {
      // This logic depends on rawMaterialData, so it should be inside a useEffect
      inventoryFirstTime = 1;
      consolidateInventory(rawMaterialData[0].inventories);

      const totalFGI=consolidateFinishedGoodInventory(rawMaterialData);

      setLastForescastUploadDate(rawMaterialData[0].lastForecastUpload);

      let countForecast = 0;
      let countFirm = 0;
      let countVase = 0;
      let countInitialBalance = 0;
      let availableFilters = [];
      let anSku = '';

      if (rawMaterialData[0].rawData && rawMaterialData[0].rawData.length > 0) {
        rawData = rawMaterialData[0].rawData;
        const rawDataHeader = { ...rawData[0] };
        anSku = `${rawDataHeader.prodclass} ${rawDataHeader.dim1} ${rawDataHeader.dim2} ${rawDataHeader.dim3} ${rawDataHeader.idesc} ${rawDataHeader.odesc}`;
        rawMaterialData[0].rawData.forEach((entry) => {
          countForecast += entry.type === 'F' ? 1 : 0;
          countFirm += entry.type === 'O' ? 1 : 0;
          countVase += entry.type === 'pos' ? 1 : 0;
        });
      }

      // calculate branches and quantities for filters on the left side
      if(rawMaterialData && rawMaterialData[0] && rawMaterialData[0].inventories){
        availableFilters = rawMaterialData[0].inventories.reduce((carry, { branchname, branchnumber, quantity }) => {
          carry.push({ branchname, branchnumber, quantity, checked: true });
          return carry;
        }, []);
      }

      let inventoryUom ="";
      if(rawMaterialData[0].inventories.length){
        inventoryUom = rawMaterialData[0].inventories[0].uom;
      }

      // sum the quantity in each branch
      countInitialBalance = availableFilters.reduce( (carry, filter)=>{ carry += filter.quantity; return carry }, 0);

      countInitialBalance = parseFloat(countInitialBalance).toFixed(2); 
 //     filteredInventory = calculateFilteredInventory(rawMaterialData[0].inventories);

      setFirmCount(countFirm);
      setVaseCount(countVase);
      setForecastCount(countForecast);
      setInitialBalance(countInitialBalance);
      setInitialBalanceUom(inventoryUom);
      setInitialFGBalance(totalFGI);
      setInitialAfterFGBalance( (parseFloat(totalFGI)+parseFloat( countInitialBalance)).toFixed(2) );
      setFilters(availableFilters);
      setSku(anSku);
      //setTotalInventory(countInitialBalance);
      totalInventory = (parseFloat(totalFGI)+parseFloat( countInitialBalance)).toFixed(2);
    }
  }, [rawMaterialData]);


  if (isLoading) {
    return (
      <tr>
        <td colSpan={5}>
        <RotatingLines
          strokeColor="#306cce"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
        </td>
      </tr>  
    );
  }

  if (rawMaterialData === null) {
    return <div>Data is not available yet.</div>;
  }

  if (rawMaterialData.length === 0) {
    return <div>No forecast records found!!</div>;
  }

  const displayRowData = (e, index, isForecast, isFirmorders, isVase) => {
    let typeClass=(e.type=="F")?"forecast":""; 
    typeClass=(e.type=="O"&&typeClass=="")?"firmorder":typeClass;
    typeClass=(e.type=="pos"&&typeClass=="")?e.type:typeClass;
   
    let classD = 'row-' + typeClass;

    //calclate on on type forecast and if fuom is PCS
    let extendedUom="PCS";
    if(e.type!='pos'){
      if(e.uomForecast=="PCS"){

        e.extendedneed = parseFloat(e.quantity * ( e.totalprocesslength /12 ) ).toFixed(2);
      }else{
        e.extendedneed = parseFloat(e.quantity).toFixed(2);
      }
      if(e.uomForecast=="PCS"){
        extendedUom="FT"
      }else{
        extendedUom=e.uomForecast;
      }
    }else{
      e.extendedneed = parseFloat(e.quantity).toFixed(2);
      extendedUom=e.uomf; 
    }
    
    if ((e.type == 'F' && isForecast) || (e.type == 'O' && isFirmorders) || (e.type == 'pos' && isVase)) {
      if(e.type == 'pos' && isVase){
        totalInventory += parseFloat(e.extendedneed);
      }else{
        totalInventory -= e.extendedneed;
      }
      e.classD = 'positive-number';
      e.balance = parseFloat(totalInventory).toFixed(2);
     
      if(e.balance < 0){
        e.classD = 'negative-number';
      }

      let dueDate=e.dueDate===null?'':e.dueDate.split(' ')[0];
      
      return <tr className={classD} key={'dataid-' + index} >
        <td>{index}</td>
        <td>{e.branchnumber}</td>
        <td>{dueDate}</td>
        <td>{e.invoice}</td>
        <td>{e.part}</td>
        <td>{e.processlength} IN</td>
        <td>{e.scrapallowance} IN</td>
        <td>{e.totalprocesslength} IN</td>
        <td>{Math.floor(e.quantity)}  {e.uomf}</td>
        <td>{e.extendedneed} {extendedUom}</td>
        {displayDaysDataPos(e, index,extendedUom)}
      </tr>;
    }

  }

  const displayBranches = (filters, handleChangeFilters) => {
    return filters.map((inv, index) => <div className='col-row row p-0 m-1' key={index}>
      <div className='controls-left col-1 p-0'><input type="checkbox" checked={inv.checked}
        value={inv.branchnumber} data-branch={inv.branchnumber}
        onChange={handleChangeFilters}
      ></input></div>
      <div className='controls-left col p-0'>{`${inv.branchnumber} - ${inv.branchname}`}</div>
      <div className='controls-left col p-0 text-end'>{inv.quantity.toFixed(2)}</div>
    </div>);
  }



  const handleChangeFirmorders = event => {
    setIsFirmorders(current => !current);
    inventoryFirstTime = 1;
    consolidateInventory(filteredInventory);
  };

  const handleChangeForecast = event => {
    setIsForecast(current => !current);
    inventoryFirstTime = 1;
    consolidateInventory(filteredInventory);
  };

  const handleChangeVase = event => {
    setIsVase(current => !current);
    inventoryFirstTime = 1;
    consolidateInventory(filteredInventory);
  };

  const handleChangeFilters = event => {
    const branch = event.currentTarget.value;
    const checked = event.currentTarget.checked;

    let atempFilters = filters.map((f) => {
      if (f.branchnumber == branch) {
        f.checked = checked;
      }
      return f;
    });

    //setFilters(atempFilters);
    inventoryFirstTime = 1;
    consolidateInventory(filteredInventory);
  };




  return (
    <div className='col-row row'>
      <div className='controls-left col-2'>
        <div className='bg-primary text-white p-2 m-1 mt-2'>Material</div>
        <div className="p-2 m-1"> {sku} </div>

        <div className='bg-primary text-white p-2 m-1 mt-2'>Filters</div>
        <div className='col-row row p-0 m-1'>
          <div className='controls-left col-1 p-0'><input type="checkbox" defaultChecked
            value={isForecast} onChange={handleChangeForecast} ></input></div>
          <div className='controls-left col-1 p-0 forecast-filter'></div>
          <div className='controls-left col-9'>Forecast</div>
          <div className='controls-left col-1 p-0'>{forecastCount}</div>
        </div>

        <div className='col-row row p-0 m-1'>
          <div className='controls-left col-1 p-0'><input type="checkbox" defaultChecked
            value={isForecast} onChange={handleChangeFirmorders}  ></input></div>
          <div className='controls-left col-1 p-0 firmorders-filter'></div>
          <div className='controls-left col-9'>Firm Orders</div>
          <div className='controls-left col-1 p-0'>{firmCount}</div>
        </div>

        <div className='col-row row p-0 m-1'>
          <div className='controls-left col-1 p-0'><input type="checkbox" defaultChecked
            value={isVase} onChange={handleChangeVase}  ></input></div>
          <div className='controls-left col-1 p-0 vase-filter'></div>
          <div className='controls-left col-9'>Incoming</div>
          <div className='controls-left col-1 p-0'>{vaseCount}</div>
        </div>

        <div className='bg-primary text-white p-2 m-1 mt-2'>Inventory</div>
        <>{displayBranches(filters, handleChangeFilters)}</>


      </div>
      <div className='controls-left col-10'>
        <h1>Raw Material List
          <span className='last-forescast-upload-date' title='Last forecast upload date'>(Last forecast uploaded: {lastForescastUploadDate} )</span></h1>
        <table className="table table-bordered text-center zoom-9">
          <thead>
            <tr>
              <th></th>
              <th>Branch</th>
              <th>Due Date</th>
              <th>PO Number</th>
              <th>Part</th>
              <th>Process<br /> Length</th>
              <th>Scrap<br /> Allowance</th>
              <th>Total<br />Process Length</th>
              <th>Qty</th>
              <th>Extended<br /> Need</th>
              <th>Balance</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Initial Balance →</th>
              <th className='text-right'>{initialBalance} {initialBalanceUom}</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Finished Good Inventory →</th>
              <th className='text-right'>{initialFGBalance} {initialBalanceUom}</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Total →</th>
              <th className='text-right'>{initialAfterFGBalance} {initialBalanceUom}</th>
            </tr>
          </thead>
          <tbody>
            {rawData.map((e, index) => (
              displayRowData(e, index, isForecast, isFirmorders, isVase)
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RawMaterialView;