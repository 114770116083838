import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateSupplier,
  cancelformValues
} from 'src/redux/actions/sysAdminActions';
import { editUser } from 'src/redux/actions/userActions';

const useFormControls = (schedule, status) => {
  const dispatch = useDispatch();

  const supplierData = useSelector((state) => state?.suppliers?.supplierData);
  const authToken = useSelector((state) => state?.auth?.token?.token);

  let navigate = useNavigate();
  // We'll update "values" as the form updates
  const [values, setValues] = React.useState();
  // "errors" is used to check the form for errors
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    const initialFormValues = {
      supplierId: supplierData?.id,
      supplierNumber: supplierData?.supplierNumber,
      preferredUOM: supplierData?.preferredUOM,
      inventoryROS: supplierData?.inventoryROS,

      timeZone: supplierData?.timeZone,
      containerProgram: supplierData?.containerProgram,
      containerDefaultReceiveFromLocation:
        supplierData?.containerDefaultReceiveFromLocation,
      containerHomeLocation: supplierData?.containerHomeLocation,
      containerDefaultShipToLocation:
        supplierData?.containerDefaultShipToLocation,
      visibleContainerLocations: supplierData?.visibleContainerLocations,
      associatedUsers: supplierData?.associatedUsers?.map((d) => d?.userId),
      suppliersToAssociate: supplierData?.associatedSuppliers
        ?.filter((d) => !d?.primary)
        ?.map((d) => d?.supplierId),

      truckSchedules: schedule
        ? schedule?.map((d) => {
            return { day: d?.day, time: d?.time };
          })
        : supplierData?.truckSchedules?.map((d) => {
            return { day: d?.day, time: d?.time };
          }),
      features: supplierData?.features?.map((d) => d?.featureId)
    };

    setValues(initialFormValues);
  }, [supplierData, status]);

  const CancelFormValues = {
    supplierId: '',
    supplierNumber: '',
    preferredUOM: '',
    inventoryROS: '',
    timeZone: '',
    containerProgram: '',
    containerDefaultReceiveFromLocation: '',
    containerHomeLocation: '',
    containerDefaultShipToLocation: '',
    visibleContainerLocations: '',
    associatedUsers: [],
    associatedSuppliers: [],
    suppliersToAssociate: [],

    truckSchedules: [],
    features: []
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ('inventoryROS' in fieldValues)
      temp.inventoryROS =
        parseInt(fieldValues.inventoryROS, 10) >= 0 &&
        parseInt(fieldValues.inventoryROS, 10) < 100
          ? ''
          : 'Please Enter a Number between 0-100';

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e, type, nm) => {
    let obj = values;

    if (type === 'array') {
      obj = { ...obj, [nm]: e?.map((item) => item.value) };
    }
    if (type === 'string') {
      obj = { ...obj, [e?.target.name]: e?.target.value };

      validate({ [e?.target.name]: e?.target.value });
    }

    if (type === 'select_string') {
      obj = { ...obj, [nm]: e?.value };
    }

    setValues(obj);
  };

  const handleFormSubmit = (e, type) => {
    e?.preventDefault();
    if (formIsValid() && type != 'cancel button') {
      dispatch(
        updateSupplier(
          {
            ...values,
            statusCode:
              (type === 'Status' && e?.target.checked === true && 'A') ||
              (e?.target.checked === false && 'IA') ||
              undefined
          },
          supplierData?.id,
          supplierData?.supplierNumber,
          type,

          authToken
        )
      );
    }
  };

  const formIsValid = (fieldValues = values) => {
    const isValid = Object.values(errors).every((x) => x === '');

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values
  };
};

export default useFormControls;
