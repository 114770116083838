import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchRawMaterialGrouped, clearData } from 'src/redux/actions/rawMaterialActions';

import { connect } from 'react-redux';
import { Outlet, Link } from "react-router-dom";
import {  useSelector } from 'react-redux';
import SERVER_BASE_URL from 'src/config/config';

const rawMaterialList = ({ rawMaterialGroups, loading, error, fetchRawMaterialGrouped }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state?.auth?.authUserData);

  useEffect(() => {
    fetchRawMaterialGrouped();

    // Return a cleanup function to clear data on unmount
    return () => {
      dispatch(clearData()); // Dispatch the action to clear the data when the component unmounts
    };
  }, [fetchRawMaterialGrouped]);


   const saveReview=(prodclass,dim1,dim2,dim3,idesc,odesc)=>{
    fetch(`${SERVER_BASE_URL}/raw-material-depletion-analysis/addUpdateReview`,{
      method:'POST',
      body:JSON.stringify({prodclass:prodclass,dim1:dim1,dim2:dim2,dim3:dim3,idesc:idesc,odesc:odesc,user:(authUser?.firstName + ' ' + authUser?.lastName)}),
    }).then(()=>{
      fetchRawMaterialGrouped();
    });
  }


  const [filterValue, setFilterValue] = useState('');

  if (rawMaterialGroups) { }
  const filteredGroups = rawMaterialGroups.map((e) => {
    e.skuHuman = `${e.prodclass} ${e.dim1} ${e.dim2} ${e.dim3} ${e.idesc} ${e.odesc}`;
    e.sku = e.prodclass + e.dim1 + e.dim2 + e.dim3 + e.idesc + e.odesc;
    if (e.parts) {
      e.parts = e.parts.split(',').join(', ');
    }
    return e;
  }).filter(group => {
    return group.sku.includes(filterValue.replaceAll(' ', '').toUpperCase()) || group.parts.includes(filterValue.replaceAll(' ', '').toUpperCase());
  }
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="rawMaterialContainer">
      <input
        type="text"
        className="searchInput form-control"
        placeholder='Search Raw Material or Part Number 🔍'
        onChange={(e) => setFilterValue(e.currentTarget.value)}
      />

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Material</th>
            <th>Total Parts</th>
            <th>Part Numbers</th>
            <th>Date Reviewed</th>
            <th>User Reviewed</th>
            <th>Click to mark reviewed</th>
          </tr>
        </thead>
        <tbody>
          {filteredGroups.map((e, index) => (
            <tr key={index}>
              <td><Link to={'/raw-material-view/' + e.id + '/' + id}>{e.skuHuman}</Link></td>
              <td>{e.total}</td>
              <td>{e.parts}</td>
              <td>{e.datereviewed}</td>
              <td>{e.userreviewed}</td>
              <td><button onClick={() => saveReview(e.prodclass,e.dim1,e.dim2,e.dim3,e.idesc,e.odesc)} cssClass='e-round' iconCss='e-icons e-plus-icon' isPrimary={true}>Review Now</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rawMaterialGroups: state.rawMaterial.rawMaterialGroups
  }
};



const mapDispatchToProps = {
  fetchRawMaterialGrouped
};


export default connect(mapStateToProps, mapDispatchToProps)(rawMaterialList);