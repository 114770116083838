import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import $ from 'jquery';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

import { CSVLink } from 'react-csv';
import { ColorRing } from 'react-loader-spinner';
import Trash from '../../../../../../../assets/Icons/trashRed.svg';
import CustomModal from '@mui/material/Modal';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { FileUploader } from 'react-drag-drop-files';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Box,
  Button,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid
} from '@mui/material';

import './DataGrid.css';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteForecastHistory,
  UploadAllValidOrder,
  uploadAndCompareForecast,
  uploadAndCompareForecastHistory,
  uploadForecast,
  uploadPartsForecast,
  validateForecastConfig
} from 'src/redux/actions/ForecastActions';

import moment from 'moment';

import { toast } from 'react-toastify';
import { ActionTypes } from 'src/redux/constants/action-types';

import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';

const ModalHeader = styled(Box)(
  () => `
    font-weight: 900;
    font-size: 18px;
    `
);

const CustomLabel = styled(FormControlLabel)(
  () => `
    margin: 0
    `
);

const CancelButton = styled(Button)(
  () => `
  color: white !important;
  background: #15364A  !important;
  border-radius: 3px  !important;
  height: 38px !important;
  width: 100px !important;

  :hover {
      background-color:  #022f53  !important;
    }
    :disabled {
      opacity: 0.8 !important;
      color: white
    }
  `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 3,
  bordeRadius: '5px'
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '88%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 4,
  bordeRadius: '5px'
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'light' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#ffffff !important',
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const ForecastOrderConfigTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forecastCompareErrors = useSelector(
    (state) => state?.customers?.getForecastCompareErrors
  );
  const gridRef = useRef(); // Optional - for accessing Grid's API

  const customerData = useSelector((state) => state?.customers?.customerData);
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openCompareModal, setopenCompareModal] = React.useState(false);
  const [openTableModal, setOpenTableModal] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [file, setFile] = React.useState(null);
  const [secondFile, setSecondFile] = React.useState(null);
  const [spaces, setSpaces] = React.useState(false);
  const [deleteInfo, setDeleteInfo] = React.useState('');
  const [selectedData, setSelectedData] = React.useState([]);
  const [fileOneErrors, setFileOneErrors] = useState([]);
  const [fileTwoErrors, setFileTwoErrors] = useState([]);
  const [errorHistory, setErrorHistory] = useState(false);

  const [forecastHistoryDataTableColumns, setForecastHistoryDataTableColumns] = useState(false);
  const [forecastHistoryDataTableOptions, setForecastHistoryDataTableOptions] = useState(false);
  const [forecastHistoryDataTableData, setForecastHistoryDataTableData] = useState(false);

  const [forecastUploadDataTableColumns, setForecastUploadDataTableColumns] = useState(false);
  const [forecastUploadDataTableOptions, setForecastUploadDataTableOptions] = useState(false);
  const [forecastUploadDataTableData, setForecastUploadDataTableData] = useState(false);

  const [forecastId, setForecastId] = useState(false);

  const isLoading = useSelector((state) => state?.loaderStatus?.showLoading);

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', margin: '12px 0' }),
    []
  );

  const handleSkipClick = () => {
    if (errorHistory === true) {
      navigate('/compare-forecast', {
        state: {
          file1: selectedData[0]?.fileUploaded?.substring(
            selectedData[0]?.fileUploaded?.lastIndexOf('/') + 1
          ),
          file2: selectedData[1]?.fileUploaded?.substring(
            selectedData[1]?.fileUploaded?.lastIndexOf('/') + 1
          )
        }
      });
    } else {
      navigate('/compare-forecast', {
        state: {
          file1: file?.name,
          file2: secondFile?.name
        }
      });
    }

    dispatch({
      type: ActionTypes.FORECAST_COMPARE_ERROR,
      payload: []
    });
  };

  const onTrashClick = () => {
    setFile(null);
    // setSecondFile(null);
  };

  const handleUploadForecast = () => {
    const fd = new FormData();
    fd.append('file', file);

    setLoader(true);
    dispatch(
      uploadForecast(
        customerData?.customerNumber,
        spaces,
        fd,
        authToken,
        setOpenTableModal,
        setForecastId
      )
    );
    setFile(null);
    setSecondFile(null);
    handleModalClose();
  };

  const handleUploadAndCompareForecast = () => {
    const fd = new FormData();
    fd.append('file1', file);
    fd.append('file2', secondFile);

    dispatch(
      uploadAndCompareForecast(
        customerData?.customerNumber,
        spaces,
        fd,
        authToken,
        setopenCompareModal,
        navigate,
        file?.name,
        secondFile?.name,
        setErrorModalOpen,
        setErrorHistory
      )
    );
  };

  const validateForecast = (event, props) => {
    let row = $(event.currentTarget).data();
    setLoader(true);
    dispatch(
      validateForecastConfig(
        row.id,
        customerData?.customerNumber,
        authToken,
        setOpenTableModal,
        setForecastId
      )
    );
  };

  useEffect(() => {
    if (forecastCompareErrors && forecastCompareErrors?.length > 0) {
      setErrorModalOpen(true);
    }
  }, [forecastCompareErrors]);

  const fileTypes = ['CSV', 'XLSX', 'XLS'];

  const handleModalClose = () => {
    setModalOpen(false);
    setOpenModal(false);
    setopenCompareModal(false);
    setFile(null);
    setSecondFile(null);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    dispatch({
      type: ActionTypes.FORECAST_COMPARE_ERROR,
      payload: []
    });
  };

  const handleTableModalClose = () => {
    setSelectedData([]);
    setOpenTableModal(false);
    $('#forecastUploadHistoryTable').DataTable().ajax.reload();
  };

  useEffect(() => {
    if (forecastCompareErrors) {
      const file1Errors = forecastCompareErrors[0]?.partErrors?.reduce(
        (a, b) => {
          const newArr = b?.errorDetails?.map((d) => d);
          a = [...new Set([...a, ...newArr])];
          return a;
        },
        []
      );

      const file2Errors = forecastCompareErrors[1]?.partErrors?.reduce(
        (a, b) => {
          const newArr = b?.errorDetails?.map((d) => d);
          a = [...new Set([...a, ...newArr])];
          return a;
        },
        []
      );

      setFileOneErrors(file1Errors);
      setFileTwoErrors(file2Errors);
    }
  }, [forecastCompareErrors]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleUploadAllValidOrder = () => {
    dispatch(
      UploadAllValidOrder(
        customerData?.customerNumber,
        forecastId,
        authToken,
        handleTableModalClose,
      )
    );
  };

  const handleCompareForecast = (rows) => {
    if (rows.length != 2 || rows[0].orderStatus.code !== 'P' || rows[1].orderStatus.code !== 'P') {
      toast.error('Please select two pending rows');
    }

    else {
      const ids = [rows[0].id, rows[1].id];
      dispatch(
        uploadAndCompareForecastHistory(
          customerData?.customerNumber,
          ids,
          authToken,
          setopenCompareModal,
          navigate,
          selectedData,
          setErrorHistory
        )
      );
    }
  };

  const openCompareModalPopUp = () => {
    setopenCompareModal(true);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };
  const handleSecondFileChange = (file) => {
    setSecondFile(file);
  };

  const deleteBPO = (e, props) => {
    setModalOpen(true);
    setDeleteInfo(props?.data);
  };

  const onDelete = (data) => {
    dispatch(
      deleteForecastHistory(data?.id, authToken)
    );
    handleModalClose();
  };

  const onSpacesSwitchChange = (e) => {
    if (spaces) {
      setSpaces(e.target.checked);
    } else {
      setSpaces(e.target.checked);
    }
  };

  const handlePlaceOrder = (rows) => {
    if (rows.length < 1) {
      toast.error('Please select valid rows');
    }
    else {
      dispatch(
        uploadPartsForecast(
          customerData?.customerNumber,
          forecastId,
          rows,
          authToken,
          handleTableModalClose,
          gridRef
        )
      );
    }
  };

  const productTable = useCallback(() => {
    return (
      <>
        {forecastUploadDataTableColumns && forecastUploadDataTableOptions && (forecastId != false) && (
          <DataTable
            id={'forecastUploadTable'}
            columns={forecastUploadDataTableColumns}
            options={forecastUploadDataTableOptions}
          />
        )}
      </>
    );
  }, [
    forecastUploadDataTableColumns,
    forecastUploadDataTableOptions,
    forecastId
  ]);

  //Forecast History Data Table settings
  const trashIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faTrashCan} />);
  useEffect(() => {
    let columns = [
      {
        name: 'checkbox',
        data: '',
        orderable: false,
        render: function (data, type, row) {
          return `<input type="checkbox" class="form-check-input" name="item${row.id}" id="item${row.id}" value="${row.id}">`;
        },
      },
      {
        title: 'File Uploaded',
        name: 'fileuploaded',
        data: 'fileUploaded',
        render: function (data, type, row) {
          let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
          return `<div class="text-truncate" style="width: 200px;"><a href="${linkUri}" target="_blank" title="${data.match(/[^\/]+$/)[0]}">${data.match(/[^\/]+$/)[0]}</a></div>`;
        },
      },
      {
        title: 'Total Parts',
        name: 'totalpartsuploaded',
        data: 'totalPartsUploaded',
      },
      {
        title: 'Updated By',
        name: 'updatedby',
        data: 'updatedBy',
        width: '150px',
      },
      {
        title: 'Date/Time',
        name: 'uploaddate',
        data: 'uploadDateAndTime',
        className: 'text-nowrap',
        render: function (data) {
          return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY HH:mm');
        }
      },
      {
        title: 'Valid Parts',
        name: 'validordersfile',
        data: 'validOrdersFileUrl',
        render: function (data, type, row) {
          let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
          return `<a href="${linkUri}" target="_blank">${row.validOrdersFileCount}</a>`;
        },
      },
      {
        title: 'Invalid Parts',
        name: 'invalidordersfile',
        data: 'invalidOrdersFileUrl',
        render: function (data, type, row) {
          let linkUri = `${SERVER_BASE_URL}/file/download?fileName=${data}`;
          return `<a href="${linkUri}" target="_blank">${row.invalidOrdersFileCount}</a>`;
        },
      },
      {
        title: 'Status',
        name: 'orderstatus',
        data: 'orderStatus',
        render: function (data, type, row) {
          let pillColorClass = (data.code == 'P') ? 'bg-warning text-dark' : 'bg-success';
          return `<span class="badge rounded-pill px-3 fw-normal ${pillColorClass}">${data.description}</span>`;
        }
      },
      {
        title: 'Action',
        name: 'action',
        data: 'id',
        className: 'actionColumn',
        render: function (data, type, row) {
          let pillColorClass = (row.orderStatus.code == 'P') ? 'bg-dark' : 'bg-secondary pe-none';
          return `<div class="text-nowrap"><span role="button" class="badge rounded-pill px-3 fw-normal align-text-top ${pillColorClass}" data-button="validate" data-id="${row.id}">Validate</span><span role="button" class="ms-3 fs-6" data-button="delete" data-id="${row.id}">${trashIcon}</span></div>`;
        }
      },
    ];

    setForecastHistoryDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const ForecastHistoryDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      {
        text: 'Select and Compare',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          openCompareModalPopUp();
        },
      },
      {
        text: 'Compare Forecasts',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          handleCompareForecast(dtselected);
        },
      },
      {
        text: 'Upload Forecast',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          handleOpen();
        },
      },
      {
        extend: 'copy',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'csv',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      }
    );
    return buttons;
  };

  const handleHistoryLinks = (event) => {
    let row = $(event.currentTarget).data();
    let props = { 'data': { 'id': row.id } };
    //Different actions depending on data-button type
    if (row.button == 'validate') {
      validateForecast(event, props);
    }
    if (row.button == 'delete') {
      deleteBPO(event, props);
    }
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: ForecastHistoryDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/customer/getFConfiguration/UploadHistory/${customerData?.customerNumber}`,
        type: "GET",
        dataSrc: function (data) {
          setForecastHistoryDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 10,
      order: [[4, 'desc']],
      searching: false,
      select: {
        style: 'multi',
      }
    };
    setForecastHistoryDataTableOptions(options);
  }, []);

  //Forecast Upload Data Table settings
  useEffect(() => {
    let columns = [
      {
        name: 'checkbox',
        data: '',
        orderable: false,
        render: function (data, type, row) {
          return (row.status[0] !== 'Valid') ? `` : `<input type="checkbox" class="form-check-input" name="item${row.id}" id="item${row.id}" value="${row.id}">`;
        },
      },
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'Quantity',
        name: 'quantity',
        data: 'quantity',
      },
      {
        title: 'UOM',
        name: 'uom',
        data: 'uom',
      },
      {
        title: 'Due Date',
        name: 'duedate',
        data: 'dueDate',
      },
      {
        title: 'Forecast Type',
        name: 'forecasttype',
        data: 'forecastType',
        render: function (data, type, row) {
          return (data !== 'F') ? data : '';
        }
      },
      {
        title: 'Status',
        name: 'status',
        data: 'status',
        render: function (data, type, row) {
          let pillColorClass = (data[0].includes('Invalid')) ? 'bg-danger' : 'bg-success';
          return `<span class="badge rounded-pill px-4 fw-normal ${pillColorClass}">${data}</span>`;
        }
      },

    ];
    setForecastUploadDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const forecastUploadDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      {
        text: 'Upload',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          handlePlaceOrder(dtselected);
        },
      },
      {
        text: 'Upload All Valid Parts',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          handleUploadAllValidOrder();
        },
      },
      {
        extend: 'copy',
        className: 'btn-outline-secondary'
      },
      {
        extend: 'csv',
        className: 'btn-outline-secondary'
      },
      {
        extend: 'print',
        className: 'btn-outline-secondary'
      }
    );
    return buttons;
  };

  useEffect(() => {
    if (forecastId !== false) {
      let options = {
        dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
          "<'row'<'col-12'tr>>" +
          "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
        buttons: forecastUploadDataTableButtons(),
        ajax: {
          url: `${SERVER_BASE_URL}/customer/forecastPagingList/${customerData?.customerNumber}/${forecastId}`,
          type: "POST",
          dataSrc: function (data) {
            setForecastUploadDataTableData(data.content);
            return data.content;
          },
          // dataSrc: 'content',
          dataType: 'JSON',
          contentType: 'application/json',
          data: function (d) {
            return JSON.stringify({
              pageIndex: 0,
              pageSize: 99999,
            });
          },
          timeout: 0,
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
        info: true,
        processing: true,
        retrieve: true,
        pageResize: true,
        scrollCollapse: true,
        lengthChange: false,
        pageLength: 10,
        order: [[1, 'asc']],
        searching: false,
        select: {
          style: 'multi',
        }
      };
      setForecastUploadDataTableOptions(options);
    }
  }, [forecastId]);

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { mr: 1 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ flexGrow: 1 }}>
          <span style={{ fontSize: '20px', fontWeight: 700 }}>
            Upload History
          </span>
        </Box>
      </Box>
      <div style={gridStyle}>
        <Box style={{ width: '100%' }}>
          {forecastHistoryDataTableColumns && forecastHistoryDataTableOptions && (
            <DataTable
              id={'forecastUploadHistoryTable'}
              columns={forecastHistoryDataTableColumns}
              options={forecastHistoryDataTableOptions}
              onRowClick={handleHistoryLinks}
            />
          )}
        </Box>
      </div>

      <CustomModal open={openModal} onClose={handleModalClose}>
        <Box sx={style}>
          <ModalHeader>Upload Forecast</ModalHeader>
          <div style={{ marginTop: '20px' }}>
            {!file && (
              <Box className="file_Upload">
                <FileUploader
                  onTypeError={(err) => toast.error(err)}
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    padding: '8px 0 0 0'
                  }}
                >
                  <Grid item xs={6} md={6} sm={12}>
                    {'Acceptable File Types: ' + fileTypes}
                  </Grid>
                  <Grid item xs={6} md={6} sm={12}>
                    {'Max File Size: 50MB'}
                  </Grid>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                borderBottom: '1px solid #D9D9D9',
                padding: '25px 0'
              }}
            >
              <CustomLabel
                label={
                  <Box sx={{ fontWeight: '700', fontSize: '12px' }}>
                    Remove spaces from Part Number
                  </Box>
                }
                control={
                  <IOSSwitch
                    defaultChecked={spaces}
                    onChange={(e) => onSpacesSwitchChange(e)}
                    name="statusCode"
                    sx={{ mr: 1 }}
                  />
                }
              />
            </Box>
            {file && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '25px 0',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#343D42'
                }}
              >
                {file && file?.name}
                <span style={{ margin: '5px 10px 0 10px' }}>
                  {' '}
                  <img
                    width="20px"
                    className="Img"
                    style={{ cursor: 'pointer' }}
                    onClick={onTrashClick}
                    src={Trash}
                  ></img>
                </span>
              </Box>
            )}

            <Box
              sx={{
                '& > :not(style)': { mr: 2, mt: 3 },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Grid item xs={6} md={2}>
                <CancelButton onClick={handleModalClose}>Cancel</CancelButton>
              </Grid>{' '}
              <Grid item xs={6} md={2}>
                <CancelButton disabled={!file} onClick={handleUploadForecast}>
                  Upload
                </CancelButton>
              </Grid>
            </Box>
          </div>
        </Box>
      </CustomModal>

      <CustomModal open={openCompareModal} onClose={handleModalClose}>
        <Box sx={style}>
          <ModalHeader>Upload & Compare Forecast</ModalHeader>
          <div style={{ marginTop: '20px' }}>
            <Box className="file_Upload">
              <Box sx={{ fontWeight: '700', fontSize: '12px' }}>
                Upload File 1 <snap style={{ color: 'red' }}>*</snap>
              </Box>
              <br />
              <FileUploader
                onTypeError={(err) => toast.error(err)}
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
              />
              {file && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '25px 0',
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#343D42'
                  }}
                >
                  {file && file?.name}
                  <span style={{ margin: '5px 10px 0 10px' }}>
                    {' '}
                    <img
                      width="20px"
                      className="Img"
                      style={{ cursor: 'pointer' }}
                      onClick={onTrashClick}
                      src={Trash}
                    ></img>
                  </span>
                </Box>
              )}

              <br />
              <Box sx={{ fontWeight: '700', fontSize: '12px' }}>
                Upload File 2 <snap style={{ color: 'red' }}>*</snap>
              </Box>
              <br />
              <FileUploader
                onTypeError={(err) => toast.error(err)}
                handleChange={handleSecondFileChange}
                name="file"
                types={fileTypes}
              />

              {secondFile && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '25px 0',
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#343D42'
                  }}
                >
                  {secondFile && secondFile?.name}
                  <span style={{ margin: '5px 10px 0 10px' }}>
                    {' '}
                    <img
                      width="20px"
                      className="Img"
                      style={{ cursor: 'pointer' }}
                      onClick={onTrashClick}
                      src={Trash}
                    ></img>
                  </span>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  padding: '8px 0 0 0'
                }}
              >
                <Grid item xs={6} md={6} sm={12}>
                  {'Acceptable File Types: ' + fileTypes}
                </Grid>
                <Grid item xs={6} md={6} sm={12}>
                  {'Max File Size: 50mB'}
                </Grid>
              </Box>
            </Box>

            {
              <Box
                sx={{
                  flexGrow: 1,
                  borderBottom: '1px solid #D9D9D9',
                  padding: '25px 0'
                }}
              >
                <CustomLabel
                  label={
                    <Box sx={{ fontWeight: '700', fontSize: '12px' }}>
                      Remove spaces from Part Number
                    </Box>
                  }
                  control={
                    <IOSSwitch
                      defaultChecked={spaces}
                      onChange={(e) => onSpacesSwitchChange(e)}
                      name="statusCode"
                      sx={{ mr: 1 }}
                    />
                  }
                />
              </Box>
            }

            <Box
              sx={{
                '& > :not(style)': { mr: 2, mt: 3 },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Grid item xs={6} md={2}>
                <CancelButton onClick={handleModalClose}>Cancel</CancelButton>
              </Grid>{' '}
              <Grid item xs={6} md={2}>
                <CancelButton
                  disabled={!file}
                  onClick={handleUploadAndCompareForecast}
                >
                  Compare
                </CancelButton>
              </Grid>
            </Box>
          </div>
        </Box>
      </CustomModal>

      <CustomModal open={openTableModal} onClose={handleTableModalClose}>
        <Box sx={modalStyle}>
          <div>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { mr: 1 },
                display: { md: 'flex', xs: 'box' },
                flexDirection: 'row'
              }}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ flexGrow: 1 }}>
                <span style={{ fontSize: '20px', fontWeight: 700 }}>
                  Upload Forecast for Customer Number:{' '}
                  {customerData?.customerNumber}
                </span>{' '}
              </Box>
            </Box>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[]}
                />
                <span>Loading...</span>
              </Box>
            ) : (
              <Box style={gridStyle}>
                <Box
                  className="ag-theme-alpine forecast_modal"
                  style={{
                    maxWidth: '100%',
                    width: '100%'
                  }}
                >
                  {productTable()}
                </Box>

                <Box
                  sx={{
                    '& > :not(style)': { mr: 2, mt: 3 },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left'
                  }}
                >
                  <Grid item xs={6} md={2}>
                    <CancelButton onClick={handleTableModalClose}>
                      Cancel
                    </CancelButton>
                  </Grid>{' '}
                </Box>
              </Box>
            )}
          </div>
        </Box>
      </CustomModal>

      <Dialog
        className="On-close"
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this history?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="On-delete"
            onClick={(e) => onDelete(deleteInfo)}
            autoFocus
          >
            Agree
          </Button>
          <Button onClick={handleModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {forecastCompareErrors && forecastCompareErrors?.length > 0 && (
        <Dialog
          className="On-close"
          open={errorModalOpen}
          onClose={handleErrorModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontWeight: '900' }} id="alert-dialog-title">
            Errors:
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {fileOneErrors?.length && (
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ mr: 2 }}>
                    {forecastCompareErrors[0].fileName + ':'}
                  </Box>
                  <CSVLink
                    filename={`${forecastCompareErrors[0].fileName + 'Errors.csv'
                      }`}
                    data={fileOneErrors}
                    title="Export as CSV"
                  >
                    Export Errors
                  </CSVLink>
                </Box>
              )}
              <br></br>
              {fileTwoErrors?.length && (
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ mr: 2 }}>
                    {forecastCompareErrors[1].fileName + ':'}
                  </Box>
                  <CSVLink
                    filename={`${forecastCompareErrors[1].fileName + 'Errors.csv'
                      }`}
                    data={fileTwoErrors}
                    title="Export as CSV"
                  >
                    Export Errors
                  </CSVLink>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSkipClick}>Skip and process</Button>
            <Button onClick={handleErrorModalClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ForecastOrderConfigTable;
