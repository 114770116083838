import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from 'react-select';
import React from 'react';

import { Grid, styled, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserRoles } from 'src/redux/actions/userActions';
import useFormControls from './validation';
import { Dispatch } from 'redux';

const AddButton = styled(Button)(
  () => `
        color: white !important;
        background: #15364A  !important;
        border-radius: 3px  !important;
        height: 38px !important;
        width: 63px !important

        :hover {
            background-color:  #022f53  !important;
          }
        :disabled {
          color: #aeaeae !important;
        }
        `
);
const CancelButton = styled(Button)(
  () => `
  background: #FFFFFF  !important;
  border: 1px solid #15364A  !important;
  border-radius: 3px  !important;
  color: #15364A  !important;
  height: 38px !important;
  width: 84px !important
      
          :hover {
              background-color: white  !important;
            }
          
          `
);
const CustomInput = styled(TextField)(
  () => `
  .MuiInputBase-root {
    height: 36px;
    background: #F4F7F9 ;
    border-radius: 4px;
    width: 100%;
  }

        :after{
          border-bottom: unset !important;

        }
        :before{
          border-bottom: unset !important;

        }

        .Mui-disabled:after {
          border-bottom: unset !important;
          border-bottom-style: solid;
        }
        .Mui-disabled:before {
          border-bottom: unset;
          border-bottom-style: solid;
        }
        .Mui-disabled {
          color: white !important;
          background: #f5f5f5;
        }
      `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);



export default function EditCustomerPartForm({ close, partConfigurationData }) {
  const inputRef = React.useRef(null);

  const dispatch = useDispatch();

  const authToken = useSelector((state) => state?.auth?.token?.token);
  const customerData = useSelector((state) => state?.customers?.customerData);

  const customerNumbers = useSelector(
    (state) => state?.customers?.customerNumbers
  );

  const customerNumbersOption = customerNumbers?.map((d) => {
    return {
      label: d?.customerNumber,
      value: d?.customerNumber
    };
  });

  useEffect(() => {
    dispatch(getUserRoles(authToken));
  }, []);

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    resetFields,
    values
  } = useFormControls(partConfigurationData);
 

  const handleSaveClick = (e) => {
    close();
    handleFormSubmit(e, resetFields);
  };

  const handleCancelClick = () => {
    close();
    resetFields();
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <LabelBox>
              Customer Part:
            </LabelBox>      
          </Grid>
          <Grid item xs={8} sx={{ margin: '10px 0' }}>
            <LabelBox>
              {partConfigurationData?.partNumber}
            </LabelBox>{' '} 
          </Grid>
          <Grid item xs={4}>
            <LabelBox>
                Product:
            </LabelBox>{' '}   
          </Grid>
          <Grid item xs={8}>            
            <LabelBox>
              {partConfigurationData?.product}
            </LabelBox>     
          </Grid>
          <Grid item xs={12} sx={{ margin: '10px 0' }}>
            <LabelBox>
              Width<span style={{ color: 'red' }}> *</span>
            </LabelBox>
              <CustomInput
                name="width"
                onBlur={(e) => handleInputValue(e, 'string', '')}
                onChange={(e) => handleInputValue(e, 'string', '')}
                fullWidth
                inputProps={{ maxLength: 20 }}
                key={partConfigurationData?.width}
                defaultValue={partConfigurationData?.width}
                autoComplete="none"
                {...(errors['width'] && {
                  error: true,
                  helperText: errors['width']
                })}
                type="text"
              />            
          </Grid>
        </Grid>
        <Box
          sx={{
            '& > :not(style)': { mr: 2, mt: 3 },
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box>
            <AddButton
              onClick={handleSaveClick}
              disabled={!formIsValid()}
              type="submit"
            >
              {'Save'}
            </AddButton>
          </Box>
          <Box>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          </Box>
        </Box>
      </form>
    </>
  );
}
