import {
  useState,
  useEffect,
  useMemo
} from 'react';
import $ from 'jquery';
import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import moment from 'moment';
import {
  Box,
  styled,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { downloadOrderHistory } from 'src/redux/actions/customerCartActions';
import { advanceTime } from 'src/services/Time-tracking';
import { timeCalculation } from 'src/redux/actions/DashboardActions';

const TableContainer = styled(Box)(
  () => `
    padding: 15px 24px;
    background-color: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    `
);

const MainContainer = styled(Box)(
  () => `
  padding: 0px 32px 0px 32px;
  `
);

const OrderHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderHistoryDataTableColumns, setOrderHistoryDataTableColumns] = useState(false);
  const [orderHistoryDataTableOptions, setOrderHistoryDataTableOptions] = useState(false);
  const [orderHistoryDataTableData, setOrderHistoryDataTableData] = useState(false);

  const authToken = useSelector((state) => state?.auth?.token?.token);
  const customerData = useSelector((state) => state?.customers?.customerData);
  const authUser = useSelector((state) => state?.auth?.authUserData);
  const [time, setTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0
  });

  useEffect(() => {
    navigate('?cp=true');
  }, []);

  const gridStyle = useMemo(() => ({ height: '100%' }), []);

  useEffect(() => {
    if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_CUSTOMER')) {
      let isCancelled = false;

      advanceTime(time, isCancelled, setTime, 'Base Feature');

      return () => {
        isCancelled = true;
        localStorage.setItem('componentTime', JSON.stringify(time));
      };
    }
  }, [time]);

  useEffect(() => {
    return () => {
      const value = JSON.parse(localStorage.getItem('componentTime'));

      if (value?.seconds > 59) {
        dispatch(
          timeCalculation(
            authUser?.email,
            'Base Feature',
            value?.seconds,
            authUser?.id
          )
        );
      }

      localStorage.clear();
    };
  }, []);

  useEffect(() => {
    if (authUser && authUser?.roles?.find((d) => d?.code === 'ROLE_CUSTOMER')) {
      window.addEventListener('beforeunload', handleUnload);
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
  }, []);

  function handleUnload(event) {
    event.preventDefault();
    event.returnValue = '';

    // Make API call here
    const value = JSON.parse(localStorage.getItem('componentTime'));
    if (value?.seconds > 59) {
      dispatch(
        timeCalculation(
          authUser?.email,
          'Base Feature',
          value?.seconds,
          authUser?.id
        )
      );
      localStorage.clear();
    }
  }

  const handleInvoiceDownload = (fileName, data) => {
    dispatch(
      downloadOrderHistory(
        data?.customerNumber,
        data?.partNumber,
        data?.salesOrderNumber,
        fileName,
        authToken
      )
    );
  };

  //Data Table settings
  useEffect(() => {
    let columns = [
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'Product',
        name: 'product',
        data: 'partDescription',
      },
      {
        title: 'Order Number',
        name: 'ordernumber',
        data: 'salesOrderNumber',
      },
      {
        title: 'PO Number',
        name: 'ponumber',
        data: 'customerOrderNumber',
      },
      {
        title: 'Quantity',
        name: 'quantity',
        data: 'quantity',
        render: function (data, type, row, meta) {
          return data + ' ' + row.uom;
        },
      },
      {
        title: 'Due Date',
        name: 'duedate',
        data: 'invoiceDate',
        render: function (data) {
          return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
        }
      },
      {
        title: 'Ship Date',
        name: 'shipdate',
        data: 'shipDate',
        render: function (data) {
          return (data == null) ? '' : moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
        }
      },
      {
        title: 'Status',
        name: 'status',
        defaultContent: '',
      },
      {
        title: 'Documents',
        name: 'documents',
        defaultContent: '',
        render: function (data, type, row, meta) {
          let linkArray = [];
          if (row.invoiceFilePath.length > 0) {
            linkArray.push(`<a href="#" data-type="INVOICE">Invoice</a>`);
          }
          if (row.mtrFilePath.length > 0) {
            linkArray.push(`<a href="#" data-type="MTR">MTR</a>`);
          }
          if (row.packingListFilePath.length > 0) {
            linkArray.push(`<a href="#" data-type="PACKAGING_LIST">Packing List</a>`);
          }
          if (row.podFilePath.length > 0) {
            linkArray.push(`<a href="#" data-type="POD">POD</a>`);
          }
          return linkArray.join(' | ');
        }
      }
    ];

    setOrderHistoryDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const OrderHistoryDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  const handleDocumentLink = (event) => {
    let clickedLink = $(event.currentTarget);
    let row = $('#OrderHistoryTable').DataTable().row($(event.target).closest('tr')).data();
    let linkData = {
      customerNumber: row.customerNumber,
      partNumber: row.partNumber,
      salesOrderNumber: row.salesOrderNumber
    };
    handleInvoiceDownload(clickedLink.data('type'), linkData);
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: OrderHistoryDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/product/${customerData?.customerNumber}/OrderHistory`,
        type: "GET",
        dataSrc: function (data) {
          setOrderHistoryDataTableData(data.content);
          return data.content;
        },
        // dataSrc: 'content',
        contentType: 'application/json',
        data: {
          pageIndex: 0,
          pageSize: 99999,
          sortOrder: '',
          search: ''
        },
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: true,
      pageLength: 10,
      fixedHeader: true,
      scrollY: '450px',
      order: [[1, 'asc']],
      searching: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setOrderHistoryDataTableOptions(options);
  }, []);

  return (
    <MainContainer>
      <div className="custom-pg-content-sec">
        <Box
          className="custom-pg-titlesticky-sec"
          component="form"
          sx={{
            '& > :not(style)': { ml: 0 },
            display: { md: 'flex', xs: 'box' },
            flexDirection: 'row'
          }}
          noValidate
          autoComplete="off"
        >
          <Box className="custom-heading-container-sec" sx={{}}>
            <Breadcrumb
              className="custom-heading-sec"
              userData={customerData}
              location={[
                {
                  location: 'Home',
                  to: `/customer-portal/${customerData?.id}/products`
                }
              ]}
              page={'Order History'}
            />
            <label
              style={{
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1',
                marginTop: '12px'
              }}
            >
              Order History
            </label>
          </Box>
        </Box>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        <div className="custom-pg-tables-content-sec">
          <TableContainer style={gridStyle}>
            {orderHistoryDataTableColumns && orderHistoryDataTableOptions && (
              <DataTable
                id={'OrderHistoryTable'}
                columns={orderHistoryDataTableColumns}
                options={orderHistoryDataTableOptions}
                onRowClick={handleDocumentLink}
              />
            )}
          </TableContainer>
        </div>
      </div>
    </MainContainer>
  );
};

export default OrderHistory;
