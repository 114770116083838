import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import moment from 'moment';
import { Box, styled } from '@mui/material';

import './DataGrid.css';
import { useSelector } from 'react-redux';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import $ from 'jquery';
import ReactDOMServer from 'react-dom/server';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomModal from '@mui/material/Modal';
import UserForm from 'src/content/SuperAdmin/ManageUsers/AddUserForm';

const TableContainer = styled(Box)(
  () => `
  padding: 15px 24px;
  background-color: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  border-radius: 3px;  
  `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 4,
  bordeRadius: '5px'
};

const UserTable = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state?.auth?.token?.token);

  const [userOpen, setUserOpen] = useState(false);

  const [userListDataTableColumns, setUserListDataTableColumns] = useState(false);
  const [userListDataTableOptions, setUserListDataTableOptions] = useState(false);
  const [userListDataTableData, setUserListDataTableData] = useState(false);

  const pencilIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faPencil} />);

  useEffect(() => {
    let columns = [];
    columns.push(
      {
        title: 'Name',
        name: 'name',
        data: 'firstName',
        render: function (data, type, row, meta) {
          return `<a href="" data-id="${row.id}"> ${row.firstName}` + ` ` + `${row.lastName}</a>`;
        }
      },
      {
        title: 'Email',
        name: 'email',
        data: 'email',
        render: function (data, type, row, meta) {
          return `<div class="text-truncate" style="width: 200px;" title="${data}">${data}</div>`;
        }
      },
      {
        title: 'Roles',
        name: 'roles',
        data: 'roles',
        render: function (data, type, row, meta) {
          let roleString = "";
          for (let i = 0; i < row.roles.length; i++) {
            roleString += (i + 1 == row.roles.length) ? row.roles[i].description : row.roles[i].description + ', ';
          }
          return roleString;
        }
      },
      {
        title: 'Last Login',
        name: 'lastlogin',
        data: 'lastLogin',
        render: function (data) {
          if (data != null) {
            return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY');
          }
          return 'No Login Date';
        }
      },
      {
        title: 'Status',
        name: 'status',
        data: 'status',
        render: function (data, type, row) {
          let pillColorClass;
          if (data.description == 'Active') {
            pillColorClass = 'bg-success';
          }
          else if (data.description == 'Request Sent') {
            pillColorClass = 'bg-primary';
          }
          else {
            pillColorClass = 'bg-danger'
          }
          return `<span class="badge rounded-pill px-3 fw-normal ${pillColorClass}">${data.description}</span>`;
        }
      },
      {
        title: 'Action',
        name: 'action',
        data: 'id',
        render: function (data, type, row) {
          return `<span role="button" class="ms-3 fs-6" data-button="edit" data-id="${row.id}">${pencilIcon}</span>`;
        }
      }
    );
    setUserListDataTableColumns(columns);
  }, []);

  const UserListDataTableButtons = () => {
    let buttons = [];
    buttons.push({
      text: 'Add User',
      className: 'btn-dark me-1',
      action: function (e, dt, node, config) {
        handleOpen();
      }
    })
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  const handleEditLink = (event) => {
    let target = event.target;
    if ($(target).closest('svg').data('icon') === "pencil") {
      target = $(event.target).closest('svg').parent();
    }
    let data = $(target).data();
    navigate('/manage-users/' + data.id);
  };

  const handleOpen = () => {
    setUserOpen(true);
  };

  const handleClose = () => {
    setUserOpen(false);
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: UserListDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/user/getUsersType?userTypeCode=I`,
        type: "GET",
        dataSrc: function (data) {
          setUserListDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 10,
      fixedHeader: true,
      scrollY: '450px',
      order: [[4, 'asc']],
      searching: true,
      select: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setUserListDataTableOptions(options);
  }, []);

  const gridStyle =
    (() => ({ height: '100%', width: '100%', margin: '12px 0' }), []);

  return (
    <div className="custom-pg-content-sec">
      <Box
        className="custom-pg-titlesticky-sec"
        component="form"
        sx={{
          '& > :not(style)': { ml: 0 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box flexGrow={1} className="custom-heading-container-sec" sx={{}}>
          <span
            className="custom-heading-sec"
            style={{
              fontSize: '20px',
              fontWeight: 700,
              fontWeight: 700,
              display: 'inline-flex',
              flexDirection: 'column'
            }}
          >
            Manage Users
          </span>
        </Box>
      </Box>
      <div className="custom-pg-tables-content-sec">
        <TableContainer style={gridStyle}>
          {userListDataTableColumns && userListDataTableOptions && (
            <DataTable
              id={'userListTable'}
              columns={userListDataTableColumns}
              options={userListDataTableOptions}
              onRowClick={handleEditLink}
            />
          )}
        </TableContainer>
      </div>
      <>
        <CustomModal keepMounted open={userOpen} onClose={handleClose}>
          <Box sx={style}>
            <div>
              <Typography
                id="keep-mounted-modal-title"
                variant="h3"
                component="h2"
              >
                Add User
              </Typography>
              <span>
                All fields with <span style={{ color: 'red' }}> *</span> are
                mandatory
              </span>
            </div>
            <div style={{ marginTop: '30px' }}>
              <UserForm close={handleClose} />
            </div>
          </Box>
        </CustomModal>
      </>
    </div>
  );
};

export default UserTable;
