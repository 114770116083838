import { Box } from '@mui/material';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from 'src/components/Breadcrumb';

import './FinishedGoodAnalysis.css'; // Import the CSS file

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import moment from 'moment';

const now = moment(); // current date and time

let monday = now.clone().subtract((now.day() - 1), 'days');
let mondays = [];

for (let i = 1; i <= 52; i++) {
  mondays.push(monday.format("MM-DD-YYYY"));
  monday = monday.clone().add(7, 'days');

}

const displayRow1 = (row, isForecast, isFirmorders, index) => {
  let total = 0;

  if (isForecast) {
    total = total + row.forecast[index];
  }
  if (isFirmorders) {
    total = total + row.firmorders[index];
  }
  return total;
}

const displayRow2 = (row, isVase, index) => {
  let total = 0;
  if (isVase) {
    total = row.vaseschedules[index];
  }
  return total;
}

const displayRow3 = (row, isForecast, isFirmorders, isVase, index) => {
  let total = 0;

  if (isVase) {
    total = total + row.vaseschedules[index];
  }

  if (isForecast) {
    total = total - row.forecast[index];
  }

  if (isFirmorders) {
    total = total - row.firmorders[index];
  }

  return total;
}

const FinishedGoodView = () => {
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const customerData = useSelector((state) => state?.customers?.customerData);
  const logoImage = useSelector((state) => state?.customers?.getLogo);

  const [finishedGoodsDataTableColumns, setFinishedGoodsDataTableColumns] = useState(false);
  const [finishedGoodsDataTableOptions, setFinishedGoodsDataTableOptions] = useState(false);
  const [finishedGoodsDataTableData, setFinishedGoodsDataTableData] = useState(false);

  const [isForecast, setIsForecast] = useState(true);
  const [isFirmorders, setIsFirmorders] = useState(true);
  const [isVase, setIsVase] = useState(true);

  const handleChangeForecast = event => {
    setIsForecast(current => !current);
    $('#FinishedGoodsTable').DataTable().ajax.reload();
  };

  const handleChangeFirmorders = event => {
    setIsFirmorders(current => !current);
    $('#FinishedGoodsTable').DataTable().ajax.reload();
  };

  const handleChangeVase = event => {
    setIsVase(current => !current);
    $('#FinishedGoodsTable').DataTable().ajax.reload();
  };

  //Finished Goods Data Table settings
  useEffect(() => {
    let columns = [
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'Item Description',
        name: 'description',
        data: 'description',
      },
      {
        title: 'UOM',
        name: 'uom',
        data: 'uom',
      },
      {
        title: 'Initial Inventory',
        name: 'initialinventory',
        className: 'no-padding',
        orderable: false,
        data: null,
        defaultContent: '',
        render: function (row, type, val, meta) {
          return `
          <div class='div-table inventory'>
            <div class='div-table-row'>
              <div class='div-table-col r1'>${row.totalStockQuantity.toFixed(2)}</div>
            </div>
            <div class='div-table-row'>
              <div class='div-table-col r2'>VAS Schedule Past Due →</div>
            </div>
            <div class='div-table-row'>
              <div class='div-table-col r3'>Balance →</div>
            </div>
          </div>
          `;
        }
      },
      {
        title: 'Past Due',
        name: 'pastdue',
        className: 'no-padding',
        orderable: false,
        data: null,
        defaultContent: '',
        render: function (row, type, val, meta) {
          let balanceRow = parseFloat(row.totalStockQuantity) -
            parseFloat(row.pastDues) +
            parseFloat(row.pastDuesVasSchedule);

          return `
              <div class='div-table number'>
              <div class='div-table-row'>
                <div class='div-table-col r1'>${row.pastDues.toFixed(2)}</div>
              </div>
              <div class='div-table-row'>
                <div class='div-table-col r2'>${row.pastDuesVasSchedule.toFixed(2)}</div>
              </div>
              <div class='div-table-row'>
                <div class='div-table-col r3'>${balanceRow.toFixed(2)}</div>
              </div>
            </div>
          `;
        }
      },
    ];

    let balanceRow = [];
    for (let i = 0; i < mondays.length; i++) {
      columns.push({
        title: mondays[i],
        name: 'date-' + i,
        className: 'no-padding',
        orderable: false,
        data: null,
        defaultContent: '',
        render: function (row, type, val, meta) {
          if (i === 0) {
            balanceRow[i] = parseFloat(row.totalStockQuantity) -
              parseFloat(row.pastDues) +
              parseFloat(row.pastDuesVasSchedule);
          }
          else {
            balanceRow[i] = balanceRow[i - 1] + displayRow3(row, isForecast, isFirmorders, isVase, i);
          }

          let rowColor = (balanceRow[i] > 0) ? 'green' : 'red';
          return `
            <div class='div-table number'>
              <div class='div-table-row'>
                <div class='div-table-col r1'>${parseFloat(displayRow1(row, isForecast, isFirmorders, i)).toFixed(2)}</div>
              </div>
              <div class='div-table-row'>
                <div class='div-table-col r2'>${parseFloat(displayRow2(row, isVase, i)).toFixed(2)}</div>
              </div>
              <div class='div-table-row'>
                <div class='div-table-col r3' style='color:${rowColor}; font-weight:bold;'>${balanceRow[i].toFixed(2)}</div>
              </div>
            </div>
          `;
        }
      });
    }

    setFinishedGoodsDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const FinishedGoodsDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      {
        text: 'Forecast',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let button = e.currentTarget;
          button.className = (button.className.includes('dark')) ? 'btn btn-secondary me-1' : 'btn btn-dark me-1';
          handleChangeForecast();
        },
      },
      {
        text: 'Firm Orders',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let button = e.currentTarget;
          button.className = (button.className.includes('dark')) ? 'btn btn-secondary me-1' : 'btn btn-dark me-1';
          handleChangeFirmorders();
        },
      },
      {
        text: 'VAS Schedules',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let button = e.currentTarget;
          button.className = (button.className.includes('dark')) ? 'btn btn-secondary me-1' : 'btn btn-dark me-1';
          handleChangeVase();
        },
      },
      {
        extend: 'copy',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'csv',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      }
    );
    return buttons;
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: FinishedGoodsDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/part/list/fg/${customerData?.customerNumber}`,
        type: "GET",
        dataSrc: function (data) {
          setFinishedGoodsDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 5,
      fixedHeader: true,
      // scrollY: '270px',
      order: [[0, 'asc']],
      searching: true,
      select: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setFinishedGoodsDataTableOptions(options);
  }, []);

  return (
    <div className='col-row row'>
      <div>
        <Box className="custom-heading-container-sec row align-items-center" sx={{}}>
          <Box className="col-4">
            {customerData?.logo && logoImage && (
              <img
                width={192}
                height={52}
                src={`data:image/jpeg;base64,${logoImage}`}
              ></img>
            )}
          </Box>
          <Box className="col-4">
            <h1>Finished Good List</h1>
          </Box>
          <Box className="col-4">
            <Breadcrumb
              className="breadcrumb text-end"
              userData={customerData}
              location={[
                {
                  location: 'Home',
                  to: `/customer-portal/${customerData?.id}/products`
                }
              ]}
              page={'Finished Good List'}
            />
          </Box>
        </Box>
      </div>
      <div className="row col-12">
        <Box style={{ width: '100%' }}>
          {finishedGoodsDataTableColumns && finishedGoodsDataTableOptions && (
            <DataTable
              id={'FinishedGoodsTable'}
              columns={finishedGoodsDataTableColumns}
              options={finishedGoodsDataTableOptions}
              bordered={true}
            />
          )}
        </Box>
      </div>
    </div>
  );
};





export default FinishedGoodView;