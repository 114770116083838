import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { ColorRing } from 'react-loader-spinner';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import { addDays, subDays } from 'date-fns';
import {
    Box,
    Button,
    styled,
    TextField,
    Select,
    MenuItem,
    Grid
} from '@mui/material';
import Popover from '@mui/material/Popover';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails'; 
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CustomModal from '@mui/material/Modal';
import { Pagination } from '@material-ui/lab';

import SearchIcon from '@mui/icons-material/Search';
import sortIcon from '../../../../assets/Icons/sort.svg';
import filterIcon from '../../../../assets/Icons/Filter.png';
import exportIcon from '../../../../assets/Icons/export.png';
import DeleteIcon from 'src/assets/Icons/Delete.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import {
    POChangeRequestTable,
    POChangeRequestExcelData,
    ApproveRequestOrderData,
    RejectRequestOrderData
} from '../../../../redux/actions/SupplierPortalActions';
import usePagination from 'src/services/pagination/pagiantion.js';
import SortingCount from 'src/services/sortingCount/SortingCount';
import { getFilteredData, handleFilter } from 'src/services/Filter';

const CustomInput = styled(TextField)(
    () => `
    .MuiInputBase-root {
        height: 40px;
        border-radius: 0px;
        width: 100%;
        background: #F4F7F9;

        border: 1px solid #B8C2C8;
        color: #15364A;
    }

    :after{
        border-bottom: unset !important;
        border: 1px solid #20A2F3 !important;

    }
    :before{
        border-bottom: unset !important;
        border: 1px solid #20A2F3 !important;

    }

    .Mui-disabled:after {
        border-bottom: unset !important;
        border-bottom-style: solid;
        background: white;
    }
    .Mui-disabled:before {
        border-bottom: unset;
        border-bottom-style: solid;
        background: white;
    }
    .Mui-disabled {
        background: white;
    }
    .css-trgup9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
        // border-color: unset;
    }
    `
);

const TabItem = styled(Box)(
    () => `

    font-family: Open Sans;

    padding: 22px;
    cursor: pointer;
    color: #72869A;

    font-weight: 700;
    font-size: 18px;
        
    height: 61px;
    margin-right: 12px;


    `
);

const ModalButton = styled(Button)(
    () => `
    background-color: #15364a !important;
    color: white !important;
    height: 40px !important;
    border-radius: 3px !important;
    padding: 0 13px !important;
    font-weight: 600;
    font-size: 16px;

    :hover {
        background-color: black !important;
    }
    `
);

const CustomButton = styled(Button)(
    () => `
    background-color: #15364a !important;
    color: white !important;
    height: 40px !important;
    width: 35px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    padding: 0px !important;
    min-width: 40px;


    :hover {
        background-color: black !important;
    }

    `
);
const ModalHeader = styled(Box)(
    () => `
    font-weight: 700;
    font-size: 20px;
    `
);

const ClearButton = styled(Button)(
    () => `
    color:  white !important;
    background: #022f53 !important;
    border-radius: 3px !important;
    padding: 0px 10px !important;


    :hover {
        color:  white !important;
    }

    `
);
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', sm: '100%' },
    maxHeight: { md: '500px', sm: '100%' },
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 3,
    bordeRadius: '5px'
};

const requestOrderEditStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '60%', sm: '100%' },
    maxHeight: { md: '500px', sm: '100%' },
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 3,
    bordeRadius: '5px'
};

const CancelButton = styled(Button)(
    () => `
    color: white !important;
    background: #15364A  !important;
    border-radius: 3px  !important;
    height: 38px !important;
    width: 63px !important

    :hover {
        background-color:  #022f53  !important;
    }
    :disabled {
        opacity: 0.8 !important;
        color: white
    }
    `
);

const StatusDropdownSelect = styled(Select)(
    () => `
    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }

    .MuiSvgIcon-root {
        margin-top: -10px;
    }

    `
);

const TableContainer = styled(Box)(
    () => `
    padding: 15px 24px;
    background-color: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    border-radius: 3px;


    `
);

const PurchaseOrderChangeRequestTable = ({ supplierId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gridRef = useRef(); // Optional - for accessing Grid's API
    
    const authToken = useSelector((state) => state?.auth?.token?.token);
    const authUserData = useSelector((state) => state?.auth?.authUserData);
    const supplierData = useSelector((state) => state?.suppliers?.supplierData);
    
    const getPOChangeRequestData = useSelector(
        (state) => state?.supplierPortal?.getPOChangeRequestTableData
      );

    const getPOChangeRequestExcel = useSelector(
        (state) => state?.supplierPortal?.getPOChangeRequestTableData
      );

    const [poChangeAnchorEl, setPoChangeAnchorEl] = React.useState(null);
    const [sortanchorEl, setsortAnchorEl] = useState(null);
    
    const [search, setSearch] = React.useState(false);    
    const [poChangeRequestExcel, setPOChangeRequestExcel] = useState([]);
    const [searchPOChangeRequestValue, setsearchPOChangeRequestValue] = React.useState('');
    
    const [tab, setTab] = useState('pochange');
    const [poPage, setPOPage] = useState(1);    
    const [poChangeRequestTableData, setPOChangeRequestTableData] = useState();  
    const [selectedData, setSelectedData] = React.useState({});
    
    const [currentPage, setCurrentPage] = useState(1);
    const options = [10, 20, 50, 100];
    const [poItemsPerPage, setPOItemsPerPage] = useState(options[0]);
    const [quantity, setquantity] = useState('');

    const [poSortType, setPOSortType] = useState();
    const [poSort, setPOSort] = useState();
    const [active, setActive] = useState(false);
    const [poToggle, setPOToggle] = React.useState(false);
    
    const [paginationData, setPaginationData] = useState();
    const [loading, setLoading] = useState(false);

          
    const [filteredData, setFilteredData] = React.useState({
        status: []
      });
    
    const poOpen = Boolean(poChangeAnchorEl);
    
    const id = open ? 'simple-popover' : undefined;
    const id2 = poOpen ? 'simple-popover' : undefined;
    const sortopen = Boolean(sortanchorEl);
    const sortid = sortopen ? 'simple-popover' : undefined;
    
    const defaultColDef = useMemo(() => ({
        sortable: false
      }));

    const onSelectionChanged = useCallback(() => {
        let selectedRows = gridRef.current.api.getSelectedRows();
    
        setSelectedData({
          ...selectedData,
          [poPage.toString()]: selectedRows
        });
      }, [selectedData, poPage]);

    const onClearFilter = () => {
        setFilteredData({
          status: []
        });
      };

    const handleClose = () => {
        setPoChangeAnchorEl(null); 
        setquantity(null);
        if (filteredData?.status?.length) {
          setActive(true);
        } else {
          setActive(false);
        }
      };

    const handleApproveRequestEdit = () => {
        if (Object.values(selectedData).flat().length) {
          const values = {};
          Object.keys(selectedData).forEach((page) => {
            values[page] = selectedData[page].map((sd) => ({
              ...sd,
            }));
          });
          let valueArray = Object.values(values).flat();
          let poIdArray = [];
          valueArray.forEach((po, index) => {
            poIdArray.push(po.id);
          });
          dispatch(
            ApproveRequestOrderData(
                poIdArray, 
                authToken,
                authUserData?.associatedSupplierList,
                searchPOChangeRequestValue,
                quantity,
                poPage,
                poItemsPerPage,
                poSort,
                poSortType
                )
          );
          //gridRef.current.api.redrawRows();

        } else {
          toast.error('Please choose an order to edit');
        }
      };

    const handleRejectRequestEdit = () => {
        if (Object.values(selectedData).flat().length) {
          const values = {};
          Object.keys(selectedData).forEach((page) => {
            values[page] = selectedData[page].map((sd) => ({
              ...sd,
            }));
          });

          let valueArray = Object.values(values).flat();
          let poIdArray = [];
          valueArray.forEach((po, index) => {
            poIdArray.push(po.id);
          });

          dispatch(
            RejectRequestOrderData(
                poIdArray, 
                authToken,
                authUserData?.associatedSupplierList,
                searchPOChangeRequestValue,
                quantity,
                poPage,
                poItemsPerPage,
                poSort,
                poSortType)
          );
          //gridRef.current.api.redrawRows();
        } else {
          toast.error('Please choose an order to edit');
        }
      };

    const handlePOClick = (event) => {
        setActive(true);
        setPoChangeAnchorEl(event.currentTarget);
    
        if (filteredData?.status) {
          setActive(true);
        }
      };

    const handlePageChange = (e, p) => {
        setPOPage(p);
        if (paginationData) {
          paginationData?.jump(p);
        }
      };
  
    const handleCountChange = (e) => {
        setPOItemsPerPage(e);
        // do something with the new itemsPerPage value, e.g. fetch new data from a server
      };

      const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
      };
      const gridStyle = useMemo(
        () => ({ height: '100%', width: '100%', margin: '0 0 12px 0' }),
        []
      );
    
    const onRerender = useCallback(
        (params) => {
            setTimeout(function () {
            if (params && params.api && selectedData[poPage]) {
                const ids = selectedData[poPage]?.map((d) => d?.id);
                params.api.forEachNode((node, i) => {
                node.setSelected(ids?.includes(node?.data?.id));
                });
            }
            }, 100);
          
        },
        [poPage, selectedData]
      );

    const pocustomSort = (e) => {
        setPOSort(e[1]);
        setPOSortType(e[0]);
        setSelectedData([]);
    
        if (paginationData) {
            paginationData?.jump(1);
        }
        setPOPage(1);
      };

    const sorthandleClick = (event) => {
        setsortAnchorEl(event.currentTarget);
      };
    const sorthandleClose = () => {
        setsortAnchorEl(null);
      };

    const searchOnPOChangeRequest = (e) => {
        setsearchPOChangeRequestValue(e?.target.value);
      };


    const bluePrintPOChangeRequest = (data) => {
        return (
          data &&
          data?.content?.map((d) => {
            return {              
              id: d?.id,
              branch: d?.branchNumber,
              productSKU: `${d?.productClass} ${d?.dim1} ${d?.dim2} ${d?.dim3} ${d?.idesc} ${d?.odesc}`,
              productClass: d?.productClass,
              dim1: d?.dim1,
              dim2: d?.dim2,
              dim3: d?.dim3,
              idesc: d?.idesc,
              odesc: d?.odesc,
              poLineNumber: d?.poLineNumber,
              purchaseOrderI5Id: d?.purchaseOrderI5Id,
              poNumber: d?.poNumber,
              quantity: (Math.round(d?.quantity * 100) / 100),
              oldQuantity: (Math.round(d?.oldQuantity * 100) / 100),
              uom: d?.uom,
              supplierName: d?.supplierName,
              length: d?.length,
              weight: d?.weight,
              weightUom: d?.weightUom,
              balance: (Math.round(d?.balance * 100) / 100),
              cost: (Math.round(d?.cost * 100) / 100),
              oldCost: (Math.round(d?.oldCost * 100) / 100),
              poCostUom: d?.costUom,
              statusCode: d?.statusCode,
              status: d?.status,
    
              dueDate: moment.utc(d?.dueDate).format('MM/DD/YYYY'),
              oldDueDate: moment.utc(d?.oldDueDate).format('MM/DD/YYYY')
            };
          })
        );
      };

    const excelbluePrintPOChangeRequest = (data) => {
        return (
          data &&
          data?.content?.map((d) => {
            return {
              branch: d?.branchNumber,
              purchaseOrderNumber: d?.purchaseOrderNumber,
              partNumber: d?.partNumber,
              partDescription: d?.partDescription,
              purchaseLineNumber: d?.purchaseLineNumber,
              dueDate: moment.utc(d?.dueDate).format('MM/DD/YYYY'),
              quantity: d?.quantity
            };
          })
        );
      };
    
    function QuantityComponent(props) {
    return (
        <div>
        <span style={{ color: 'black' }}>
            {(props?.data?.quantity) + ' ' + props?.data.uom}
        </span>
        </div>
    );
    }

    function OldQuantityComponent(props) {
        return (
            <div>
            <span style={{ color: 'black' }}>
                {(props?.data?.oldQuantity) + ' ' + props?.data.uom}
            </span>
            </div>
        );
        }

    function StatusComponent(props) {
        return (
        <div>
            <span
            style={{
                borderRadius: '30px',
                textAlign: 'center',
                padding: '4px 20px',
                height: '22px',
                color: 'white',
                background:
                (props?.data?.status === 'Request Accepted' && '#46bd60') ||
                (props?.data?.status === 'Request Rejected' && '#FF4A4A') ||
                (props?.data?.status === 'Request Pending' && '#555555'),
    
                marginTop: '-2px'
            }}
            >
            {props?.data?.status}
            </span>
        </div>
        );
    }
  
    function CostComponent(props) {
    return (
        <div>
        <span style={{ color: 'black' }}>
            {(props?.data?.cost) + ' ' + props?.data?.poCostUom}
        </span>
        </div>
    );
    }

    function OldCostComponent(props) {
        return (
            <div>
            <span style={{ color: 'black' }}>
                {(props?.data?.oldCost) + ' ' + props?.data?.poCostUom}
            </span>
            </div>
        );
        }

    useEffect(() => {
        if (getPOChangeRequestData) {
          const POChangeRequestData = bluePrintPOChangeRequest(getPOChangeRequestData);
          const excelPOChangeRequestData = excelbluePrintPOChangeRequest(getPOChangeRequestExcel);
          const filterData = getFilteredData(
            poSortType === 'qty:asc'
              ? POChangeRequestData.sort((a, b) => a.qty - b.qty)
              : poSortType === 'qty:dec'
              ? POChangeRequestData.sort((a, b) => b.qty - a.qty)
              : poSortType === 'dueDate:asc'
              ? POChangeRequestData.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
              : poSortType === 'dueDate:dec'
              ? POChangeRequestData.sort((a, b) => (a.dueDate < b.dueDate ? 1 : -1))
              : POChangeRequestData,
            filteredData,
            'systemAdmin'
          );
    
          const excelfilterData = getFilteredData(
            excelPOChangeRequestData,
            filteredData,
            'systemAdmin'
          );
    
          const data = usePagination(
            filterData,
            poItemsPerPage,
            currentPage,
            setCurrentPage
          );
          setPaginationData(data);
          setPOChangeRequestExcel(excelfilterData);
    
          setPOChangeRequestTableData(data?.currentData());
        }
      }, [getPOChangeRequestData, poPage, filteredData, poItemsPerPage, poSort, poSortType]);

    React.useEffect(() => {  
        dispatch(
          POChangeRequestTable(
            authUserData?.associatedSupplierList,
            searchPOChangeRequestValue,
            quantity,
            authToken,
            poPage,
            poItemsPerPage,
            poSort,
            poSortType
          )
        );
      }, [supplierData?.id, searchPOChangeRequestValue, quantity, poItemsPerPage]);

    React.useEffect(async () => {
        dispatch(
          POChangeRequestExcelData(
            supplierData?.supplierNumber,
            searchPOChangeRequestValue,
            quantity,
            authToken,
            10000,
            setPOToggle
          )
        );
      }, [supplierData?.id, searchPOChangeRequestValue, quantity]);

      useEffect(() => {
        setPOItemsPerPage(options[0]);
        setPOPage(1);
        if (paginationData) {
          paginationData?.jump(1);
        }
      }, [tab]);
    
      

    const poChangeRequestTable = useCallback(() => {
        return (
        <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            onFirstDataRendered={onFirstDataRendered}
            onSelectionChanged={onSelectionChanged}
            domLayout={'autoHeight'}
            defaultColDef={defaultColDef} // Default Column Properties
            rowData={!search ? poChangeRequestTableData : bluePrintPOChangeRequest}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            frameworkComponents={{ QuantityComponent, OldQuantityComponent, CostComponent, OldCostComponent, StatusComponent }}
        >
            <AgGridColumn
                resizable={true}
                headerName="PO"
                headerCheckboxSelection={true}
                checkboxSelection={true}
                field="poNumber"
            ></AgGridColumn>
            <AgGridColumn
                resizable={true}
                width="100"
                headerName="Line"
                field="poLineNumber"
            ></AgGridColumn>
            <AgGridColumn
                resizable={true}
                width="100"
                field="supplierName"
            ></AgGridColumn>  
            <AgGridColumn
                resizable={true}
                width="100"
                field="branch"
            ></AgGridColumn>          
            <AgGridColumn
                resizable={true}
                headerName="Product"
                field="productSKU"
            ></AgGridColumn>
            <AgGridColumn
            resizable={true}
                headerName="Quantity Ordered"
                field="quantity"
                cellRenderer="QuantityComponent"
            ></AgGridColumn>
            <AgGridColumn
            resizable={true}
                headerName="Old Quantity Ordered"
                field="oldQuantity"
                cellRenderer="OldQuantityComponent"
            ></AgGridColumn>
            <AgGridColumn
                resizable={true}
                headerName="PO Cost"
                field="cost"
                cellRenderer="CostComponent"
            ></AgGridColumn>
            <AgGridColumn
                resizable={true}
                headerName="Old PO Cost"
                field="oldCost"
                cellRenderer="OldCostComponent"
            ></AgGridColumn>
            <AgGridColumn 
                resizable={true} 
                field="dueDate"
            ></AgGridColumn>  
            <AgGridColumn 
                resizable={true} 
                field="oldDueDate"
            ></AgGridColumn>
            <AgGridColumn
                resizable={true}
                width="175"
                cellRenderer="StatusComponent"
                field="status"
            ></AgGridColumn>   
        </AgGridReact>
        );
    }, [poPage, poChangeRequestTableData, poItemsPerPage, poSort, poSortType]);


    

    return (
        <div className="custom-pg-content-sec">
        <Box
            className="custom-pg-titlesticky-sec"
            component="form"
            sx={{
            '& > :not(style)': { ml: 0 },
            display: { md: 'flex', xs: 'box' },
            flexDirection: 'row'
            }}
            noValidate
            autoComplete="off"
        >
        
        {/* Search Box */}
        <Box className="custom-heading-actionable-sec" sx={{}}>
            <Box sx={{ paddingLeft: '15px' }}>
                <TextField
                    id="filter-text-box"
                    label="Search by po number, product"
                    size="small"
                    type="text"
                    onChange={(e) => searchOnPOChangeRequest(e)}
                    sx={{
                    backgroundColor: 'white',
                    width: '350px',
                    border: '1px solid #E6E6E6',
                    borderRadius: '0px !important',
                    fontWeight: '400',
                    fontSize: '12px !important'
                    }}
                    InputLabelProps={{
                    style: {
                        fontStyle: 'italic',
                        background: '#fff',
                        paddingLeft: '6px'
                    }
                    }}
                    alt="Search"
                    InputProps={{
                    endAdornment: <SearchIcon />
                    }}
                />
            </Box>
        </Box>{' '}
        <Box
            sx={{
                display: { md: 'flex', xs: 'box' },
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }}>

            {tab === 'pochange' && (
            <>
                {(
                    <Box sx={{ paddingLeft: '15px' }}>
                    <ModalButton onClick={handleApproveRequestEdit}>
                        Approve
                    </ModalButton>
                    </Box>
                )}      
                {(
                    <Box sx={{ paddingLeft: '15px' }}>
                    <ModalButton onClick={handleRejectRequestEdit}>
                        Reject
                    </ModalButton>
                    </Box>
                )}           
            </>
            )}
            
            {/* Filters */}
            <Box sx={{ paddingLeft: '15px' }}>
                {tab === 'pochange' && (
                <CustomButton
                    aria-describedby={id}
                    variant="contained"
                    onClick={handlePOClick}
                    title="Filters"
                    sx={{ background: active && 'black !important' }}
                >
                    <img src={filterIcon}></img>
                </CustomButton>
                )}
            </Box>

            {tab === 'pochange' && (
                <Popover
                id={id2}
                open={poOpen}
                anchorEl={poChangeAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                >
                <Typography sx={{ p: 2 }}>
                    {filteredData?.status?.length ? (
                    <ClearButton
                        sx={{ cursor: 'pointer' }}
                        onClick={onClearFilter}
                    >
                        Clear All
                    </ClearButton>
                    ) : (
                    ''
                    )}

                <Accordion>
                    <AccordionSummary
                    className="custom-cls"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography
                            sx={{
                            margin: '0 0'
                            }}
                        >
                            Status
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel
                            label="Request Pending"
                            control={
                                <Checkbox
                                name="Request Pending"
                                checked={filteredData.status.includes('Request Pending')}
                                onChange={(e) =>
                                    handleFilter(
                                    e,
                                    'status',
                                    filteredData,
                                    setFilteredData,
                                    setPOPage,
                                    paginationData?.jump
                                    )
                                }
                                />
                            }
                            />
                            <FormControlLabel
                            label="Request Rejected"
                            control={
                                <Checkbox
                                name="Request Rejected"
                                checked={filteredData.status.includes('Request Rejected')}
                                onChange={(e) =>
                                    handleFilter(
                                    e,
                                    'status',
                                    filteredData,
                                    setFilteredData,
                                    setPOPage,
                                    paginationData?.jump
                                    )
                                }
                                />
                            }
                            />
                            <FormControlLabel
                            label="Request Accepted"
                            control={
                                <Checkbox
                                name="Request Accepted"
                                checked={filteredData.status.includes('Request Accepted')}
                                onChange={(e) =>
                                    handleFilter(
                                    e,
                                    'status',
                                    filteredData,
                                    setFilteredData,
                                    setPOPage,
                                    paginationData?.jump
                                    )
                                }
                                />
                            }
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                </Typography>
                </Popover>
            )}
            {/* Sort and export buttons */}
            {tab === 'pochange' && (
                <>
                <Box sx={{ pl: 2 }}>
                    <CustomButton
                    aria-describedby={sortid}
                    variant="contained"
                    onClick={sorthandleClick}
                    title="Sort"
                    sx={{ background: active && 'black !important' }}
                    >
                    <img src={sortIcon}></img>
                    </CustomButton>
                </Box>
                <Popover
                    id={sortid}
                    open={sortopen}
                    anchorEl={sortanchorEl}
                    onClose={sorthandleClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                    }}
                >
                    <Box className={'sortText'} sx={{ mr: 2, pl: 2, pt: 4, pb: 2 }}>
                    <div
                        onClick={(e) => pocustomSort(['quantity', 'ASC'])}
                        style={{ cursor: 'pointer', fontSize: '12px' }}
                    >
                        Quantity: Asc - Dec
                    </div>
                    <div
                        onClick={(e) => pocustomSort(['quantity', 'DESC'])}
                        style={{ cursor: 'pointer', fontSize: '12px' }}
                    >
                        Quantity: Dec - Asc
                    </div>
                    <div
                        onClick={(e) => pocustomSort(['dueDate', 'ASC'])}
                        style={{ cursor: 'pointer', fontSize: '12px' }}
                    >
                        Due Date: Asc - Dec
                    </div>
                    <div
                        onClick={(e) => pocustomSort(['dueDate', 'DESC'])}
                        style={{ cursor: 'pointer', fontSize: '12px' }}
                    >
                        Due Date: Dec - Asc
                    </div>
                    </Box>
                </Popover>
                <Box sx={{ paddingLeft: '15px' }}>
                    {getPOChangeRequestExcel && getPOChangeRequestExcel?.content && (
                    <CustomButton>
                        {!poToggle ? (
                        <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['white', 'white', 'white', 'white', 'white']}
                        />
                        ) : (
                        <CSVLink
                            data={poChangeRequestExcel}
                            filename={'Products:PO Change Request'}
                            title="Export as CSV"
                        >
                            <img src={exportIcon}></img>
                        </CSVLink>
                        )}
                    </CustomButton>
                    )}
                </Box>
                </>
            )}
            </Box>
        </Box>
        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}{' '}
        <div className="custom-pg-table-content-sec">
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
            }}
            >
            <TabItem
                className={`TEST5` && tab === 'pochange' && 'tabActive'}
                id="TEST5"
                onClick={() => setTab('pochange')}
            >
                PO Change Requests
            </TabItem>
            </Box>
        </div>
        {/* MODAL AND POPOVERS */}
        
        {tab && tab === 'pochange' && (
            <TableContainer style={gridStyle}>
            <>
                <Box
                    className="ag-theme-alpine"
                    style={{
                        maxWidth: '100%',
                        width: '100%'
                    }}
                >
                    {poChangeRequestTable()}
                    {onRerender(gridRef.current)}
                </Box>
                <Box
                    sx={{
                        '& > :not(style)': { mr: 2, mt: 2 },
                        display: { md: 'flex', xs: 'box' },
                        flexDirection: 'row-reverse'
                    }}
                >
                    <Pagination
                        count={paginationData?.maxPage}
                        size="large"
                        page={poPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                    />
                    <SortingCount
                        options={options}
                        onChange={handleCountChange}
                    />
                </Box>{' '}
            </>
            
            </TableContainer>
        )}
        </div>
    );
};

export default PurchaseOrderChangeRequestTable;