import { useState, useEffect } from 'react';
import CustomModal from '@mui/material/Modal';

import Typography from '@mui/material/Typography';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { Box, styled } from '@mui/material';

import './DataGrid.css';
import RoleForm from '../AddRoleForm';
import { useSelector } from 'react-redux';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import $ from 'jquery';
import ReactDOMServer from 'react-dom/server';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableContainer = styled(Box)(
  () => `
    padding: 15px 24px;
    background-color: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    border-radius: 3px;  
    `
);

const RolesTable = () => {
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const [editRoleData, setEditRoleData] = useState({});

  const [roleListDataTableColumns, setRoleListDataTableColumns] = useState(false);
  const [roleListDataTableOptions, setRoleListDataTableOptions] = useState(false);
  const [roleListDataTableData, setRoleListDataTableData] = useState(false);

  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [editRoleOpen, setEditRoleOpen] = useState(false);

  const pencilIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faPencil} />);

  useEffect(() => {
    let columns = [];
    columns.push(
      {
        title: 'Role Name',
        name: 'name',
        data: 'description',
        render: function (data, type, row, meta) {
          return `<span style="color: rgb(32, 162, 243);">${row.description}</span>`
        }
      },
      {
        title: 'Permissions',
        name: 'permissions',
        data: 'permissions',
        width: '33%',
        render: function (data, type, row, meta) {
          let permissionString = "";
          for (let i = 0; i < row.permissions.length; i++) {
            permissionString += (i + 1 == row.permissions.length) ? row.permissions[i].permissionName : row.permissions[i].permissionName + ', ';
          }
          return permissionString;
        }
      },
      {
        title: 'Action',
        name: 'action',
        data: 'id',
        render: function (data, type, row) {
          return `<span role="button" class="ms-3 fs-6" data-description="${row.description}" data-code="${row.code}" data-permissions="${encodeURIComponent(JSON.stringify(row.permissions))}" data-id="${row.id}">${pencilIcon}</span>`;
        }
      }
    );
    setRoleListDataTableColumns(columns);
  }, []);

  const roleListDataTableButtons = () => {
    let buttons = [];
    buttons.push({
      text: 'Add Role',
      className: 'btn-dark me-1',
      action: function (e, dt, node, config) {
        handleAddOpen();
      }
    })
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  const handleAddOpen = () => {
    setAddRoleOpen(true);
  };

  const handleEditOpen = (event) => {
    let target = event.target;
    if ($(target).closest('svg').data('icon') === "pencil") {
      target = $(event.target).closest('svg').parent();
    }
    let data = $(target).data();
    console.log(data);
    setEditRoleData({
      role: {
        id: data.id,
        description: data.description,
        code: data.code
      },
      permissions: JSON.parse(decodeURIComponent(data.permissions))
    });
    setEditRoleOpen(true);
  }

  const handleAddClose = () => {
    setAddRoleOpen(false);
  };

  const handleEditClose = () => {
    setEditRoleOpen(false);
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: roleListDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/user/getAllRoles`,
        type: "GET",
        dataSrc: function (data) {
          setRoleListDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 10,
      fixedHeader: true,
      // scrollY: '450px',
      order: [[0, 'asc']],
      searching: true,
      select: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setRoleListDataTableOptions(options);
  }, []);

  const gridStyle =
    (() => ({ height: '100%', width: '100%', margin: '12px 0' }), []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', sm: '100%' },
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 4,
    bordeRadius: '5px'
  };

  return (
    <div className="custom-pg-content-sec">
      <Box
        className="custom-pg-titlesticky-sec"
        component="form"
        sx={{
          '& > :not(style)': { ml: 0 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box className="custom-heading-container-sec" sx={{}}>
          <span
            className="custom-heading-sec"
            style={{
              fontSize: '20px',
              lineHeight: 1,
              fontWeight: 700,
              display: 'inline-flex',
              flexDirection: 'column'
            }}
          >
            Manage Roles
          </span>
        </Box>
      </Box>{' '}

      <div className="custom-pg-tables-content-sec">
        <TableContainer style={gridStyle}>
          {roleListDataTableColumns && roleListDataTableOptions && (
            <DataTable
              id={'roleListTable'}
              columns={roleListDataTableColumns}
              options={roleListDataTableOptions}
              onRowClick={handleEditOpen}
            />
          )}
        </TableContainer>
      </div>
      <CustomModal id="editModal" keepMounted
        open={editRoleOpen} onClose={handleEditClose}
      >
        <Box sx={style}>
          <div>
            <Typography id="keep-mounted-modal-title" variant="h3" component="h2">Edit Role</Typography>
            <span>All fields with <span style={{ color: 'red' }}> *</span> are mandatory</span>
          </div>
          <div style={{ marginTop: '30px' }}>
            <RoleForm
              isEdit={true}
              editRoleData={editRoleData}
              close={handleEditClose}
            />
          </div>
        </Box>
      </CustomModal>
      <>
        <CustomModal id="addModal" keepMounted open={addRoleOpen} onClose={handleAddClose}>
          <Box sx={style}>
            <div>
              <Typography
                id="keep-mounted-modal-title"
                variant="h3"
                component="h2"
              >
                Add Role
              </Typography>
              <span>
                All fields with <span style={{ color: 'red' }}> *</span> are
                mandatory
              </span>
            </div>
            <div style={{ marginTop: '30px' }}>
              <RoleForm close={handleAddClose} />
            </div>
          </Box>
        </CustomModal>
      </>
    </div>
  );
};

export default RolesTable;
