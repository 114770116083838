import { Box } from '@mui/material';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';

const BlanketPOView = () => {

  const authToken = useSelector((state) => state?.auth?.token?.token);
  const customerData = useSelector((state) => state?.customers?.customerData);

  const [blanketPOListViewDataTableColumns, setBlanketPOListViewDataTableColumns] = useState(false);
  const [blanketPOListViewDataTableOptions, setBlanketPOListViewDataTableOptions] = useState(false);
  const [blanketPOListViewDataTableData, setBlanketPOListViewDataTableData] = useState(false);

  //Blanket PO List View Data Table settings
  useEffect(() => {
    let columns = [
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'Cost',
        name: 'cost',
        data: 'price',
        render: function (data) {
          return (parseFloat(data).toFixed(2));
        }
      },
      {
        title: 'Quantity',
        name: 'quantity',
        data: null,
        defaultContent: '',
        render: function (row, type, val, meta) {
          return row.quantity + ' ' + row.uom;
        }
      },
      {
        title: 'Used',
        name: 'used',
        data: null,
        defaultContent: '',
        render: function (row, type, val, meta) {
          return row.quantityOrdered + ' ' + row.uom;
        }
      },
      {
        title: 'Balance Remaining',
        name: 'balanceremaining',
        data: null,
        defaultContent: '',
        render: function (row, type, val, meta) {
          return (parseFloat(row.quantity) - parseFloat(row.quantityOrdered)) + ' ' + row.uom;
        }
      },
      {
        title: 'PO Number',
        name: 'ponumber',
        data: 'poNumber',
      },
      {
        title: 'Effective Date',
        name: 'effectivedate',
        data: 'effectiveDate',
      },
      {
        title: 'End Date',
        name: 'enddate',
        data: 'endDate',
      },
    ];
    setBlanketPOListViewDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const blanketPoListViewDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      {
        extend: 'copy',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'csv',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':not(.actionColumn)',
        },
        className: 'btn-outline-secondary'
      }
    );
    return buttons;
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: blanketPoListViewDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/customer/BlanketPO/fetchBlanketPOs/${customerData?.customerNumber}`,
        type: "POST",
        dataSrc: function (data) {
          setBlanketPOListViewDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 15,
      fixedHeader: true,
      // scrollY: '270px',
      order: [[0, 'asc']],
      searching: true,
      select: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      },
      rowGroup: {
        dataSrc: 'partNumber'
      }
    };
    setBlanketPOListViewDataTableOptions(options);
  }, []);

  return (
    <div className='col-row row justify-content-center'>
      <div className="text-center">
        <h1>Blanket PO List</h1>
      </div>
      <div className="row col-12">
        <Box style={{ width: '100%' }}>
          {blanketPOListViewDataTableColumns && blanketPOListViewDataTableOptions && (
            <DataTable
              id={'BlanketPOListViewTable'}
              columns={blanketPOListViewDataTableColumns}
              options={blanketPOListViewDataTableOptions}
              bordered={true}
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default BlanketPOView;