import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import sysAdminReducer from './sysAdminReducer';
import customerPortalReducer from './customerPortalReducer';
import supplierPortalReducer from './supplierPortalReducer';
import loaderReducer from './loaderReducer';
import dashboardReducer from './dashboardReducer';
import rawMaterialReducer from './rawMaterialReducer';


const reducers = combineReducers({
  users: userReducer,
  auth: authReducer,
  customers: sysAdminReducer,
  suppliers: sysAdminReducer,
  customerPortal: customerPortalReducer,
  supplierPortal: supplierPortalReducer,
  dashboard:dashboardReducer,
  loaderStatus:loaderReducer,
  rawMaterial: rawMaterialReducer,
});

export default reducers;
