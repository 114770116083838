import * as React from 'react';
import {
  addNewRole,
  addUser,
  editRole,
  getAllUsers,
  getUser
} from 'src/redux/actions/userActions';
import UserForm from './index';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useFormControls = (editRoleData, selectedPermissions) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // We'll update "values" as the form updates
  const [values, setValues] = React.useState({});
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const authUser = useSelector((state) => state?.auth?.authUserData);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (editRoleData) {
      const initialFormValues = {
        id: editRoleData?.role?.id,
        description: editRoleData?.role?.description,
        code: editRoleData?.role?.code,
        permissions: selectedPermissions?.map((d) => d?.value)
      };
      setValues(initialFormValues);
    } else {
      const initialFormValues = {
        description: '',
        code: '',
        permissions: []
      };
      setValues(initialFormValues);
    }
  }, [editRoleData, selectedPermissions]);

  const resetFields = () => {
    setValues({
      description: '',
      code: '',
      permissions: []
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ('firstName' in fieldValues)
      temp.description = fieldValues.firstName ? '' : 'This field is required.';
    if ('permissions' in fieldValues) {
      temp.roles = fieldValues.roles ? [] : 'This field is required.';
    }

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e, type, nm) => {
    let obj = values;
    if (type === 'string') {
      obj = {
        ...obj,
        [e?.target.name]: e?.target.value,
        code: `ROLE_${e?.target.value.toUpperCase()}`
      };

      validate({ [e?.target.name]: e?.target.value });
    }
    if (type === 'array') {
      obj = {
        ...obj,
        [nm]: e?.map((item) => {
          return item.value;
        })
      };

      // validate({ ...obj, [nm]: e.map((item) => item.value) });
    }
    setValues(obj);
  };

  const handleFormSubmit = async (e, reset, isEdit) => {
    e?.preventDefault();
    if (!isEdit && formIsValid()) {
      await dispatch(addNewRole(values, authToken));
    } else if (isEdit && formIsValid()) {
      await dispatch(editRole(values, authToken));
    }
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues?.description && Object.values(errors).every((x) => x === '');

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    resetFields,
    values
  };
};

export default useFormControls;
