import axios from 'axios';
import { ActionTypes } from '../constants/action-types';
import SERVER_BASE_URL from '../../config/config';
import { toast } from 'react-toastify';
import store from '../store';

export const addToCart =
  (
    values,
    id,
    authToken,
    handlePlaceOrderModalClose,
    handleDeselect,
    setSelectedData,
    setSubmit
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/cart/addCartOrders`,
        values,
        config
      );

      if (
        response?.data?.find(
          (d) =>
            d?.errorsMap?.customerId ||
            d?.errorsMap?.dueDate ||
            d?.errorsMap?.partNumber ||
            d?.errorsMap?.purchaseOrderNumber ||
            d?.errorsMap?.quantity ||
            d?.errorsMap?.shippingLocationId ||
            d?.errorsMap?.uom
        )
      ) {
        toast.error('Parts cannot be added to cart');
        dispatch({
          type: ActionTypes.GET_ADD_CART_RESPONSE,
          payload: []
        });
      }
      else if(
        response?.data?.find(
          (d) =>
            d?.warningMap?.customerId ||
            d?.warningMap?.dueDate ||
            d?.warningMap?.partNumber ||
            d?.warningMap?.purchaseOrderNumber ||
            d?.warningMap?.quantity ||
            d?.warningMap?.shippingLocationId ||
            d?.warningMap?.uom
        )
      ) {
        var warningStrings = [];
        var warningStr = "";
        warningStrings[0] = (response?.data?.find((d) =>d?.warningMap?.customerId));
        warningStrings[1] = (response?.data?.find((d) =>d?.warningMap?.dueDate));
        warningStrings[2] = (response?.data?.find((d) =>d?.warningMap?.partNumber));
        warningStrings[3] = (response?.data?.find((d) =>d?.warningMap?.purchaseOrderNumber));
        warningStrings[4] = (response?.data?.find((d) =>d?.warningMap?.quantity));
        warningStrings[5] = (response?.data?.find((d) =>d?.warningMap?.shippingLocationId));
        warningStrings[6] = (response?.data?.find((d) =>d?.warningMap?.uom));
        
        for(var i = 0; i < warningStrings.length; i++){
          if(warningStrings[i]){
            if(i == 0)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.customerId + " ";
            if(i == 1)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.dueDate + " ";
            if(i == 2)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.partNumber + " ";
            if(i == 3)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.purchaseOrderNumber + " ";
            if(i == 4)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.quantity + " ";
            if(i == 5)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.shippingLocationId + " ";
            if(i == 6)
              warningStr += "For part number " + warningStrings[i].partNumber + ": " + warningStrings[i].warningMap.uom + " ";
          }
        }
        var warningMessage = "There are the following warnings: " + warningStr + "Do you want to place the order under these conditions?";
        var confirm = window.confirm(warningMessage);

        if(confirm){
          for(const ele of values){
            ele.overrideValid = true;
          }
          const confirmedResp = await axios.post(
            `${SERVER_BASE_URL}/cart/addCartOrders`,
            values,
            config
          );
          toast.success('Parts added to cart!');
          setSubmit(true);
          handleDeselect();
          setSelectedData({});
          dispatch({
            type: ActionTypes.GET_ADD_CART_RESPONSE,
            payload: response.data
          });
  
          return response;
        }
        else{
          toast.error('Parts not added to cart');
          dispatch({
            type: ActionTypes.GET_ADD_CART_RESPONSE,
            payload: []
          });
        }        
      } else {
        toast.success('Parts added to cart!');
        handleDeselect();
        setSelectedData({});
        setSubmit(true);
        dispatch({
          type: ActionTypes.GET_ADD_CART_RESPONSE,
          payload: response.data
        }); 
        

        return response;
      }

      const CartDataresponse = await axios.get(
        `${SERVER_BASE_URL}/cart/getAllCartOrderByUserId/${id}`,
        config
      );

      dispatch({
        type: ActionTypes.GET_ADD_CART_RESPONSE,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const updateCartOrders =
  (customerId, cartData, authToken, setEnableSubmit) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.put(
        `${SERVER_BASE_URL}/cart/updateCartOrders`,
        cartData,
        config
      );

      dispatch({
        type: ActionTypes.GET_ALL_CART_ORDERS,
        payload: response.data
      });

      if (
        response?.data?.find(
          (d) =>
            d?.errorsMap?.customerId ||
            d?.errorsMap?.dueDate ||
            d?.errorsMap?.partNumber ||
            d?.errorsMap?.purchaseOrderNumber ||
            d?.errorsMap?.quantity ||
            d?.errorsMap?.shippingLocationId
        )
      ) {
        setEnableSubmit(false);
      } else {
        setEnableSubmit(true);
        toast.success('Updated cart order successfully.');
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const getAllCartOrderByUserId =
  (customerId, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/cart/getAllCartOrderByUserId/${customerId}`,

        config
      );

      dispatch({
        type: ActionTypes.GET_ALL_CART_ORDERS,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }

      toast.error(e?.response?.data?.errorDescription);
    }
  };
// /product/{{customerNumber}}/{{partNumber}}/OrderHistory/{{salesOrderNumber}}/{{TYPE}}

export const downloadOrderHistoryForAll =
  (customerNumber, partNumber, salesOrderNumber, authToken) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const downloadFile = (blob, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      };

      fetch(
        `${SERVER_BASE_URL}/product/${customerNumber}/OrderHistory/${salesOrderNumber}/INVOICE?partNumber=${partNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken
          }
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          downloadFile(response, 'INVOICE');
        });

      fetch(
        `${SERVER_BASE_URL}/product/${customerNumber}/OrderHistory/${salesOrderNumber}/MTR?partNumber=${partNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken
          }
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          downloadFile(response, 'MTR');
        });

      fetch(
        `${SERVER_BASE_URL}/product/${customerNumber}/OrderHistory/${salesOrderNumber}/PACKAGING_LIST?partNumber=${partNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken
          }
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          downloadFile(response, 'PACKAGING_LIST');
        });

      fetch(
        `${SERVER_BASE_URL}/product/${customerNumber}/OrderHistory/${salesOrderNumber}/POD?partNumber=${partNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken
          }
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          downloadFile(response, 'POD');
        });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const downloadOrderHistory =
  (customerNumber, partNumber, salesOrderNumber, TYPE, authToken) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const downloadFile = (blob, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      };

      fetch(
        `${SERVER_BASE_URL}/product/${customerNumber}/OrderHistory/${salesOrderNumber}/${TYPE}?partNumber=${partNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken
          }
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          downloadFile(response, TYPE);
        });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const deleteCartOrders =
  (customerId, id, authToken, setDeleteIconDisabled) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/cart/deleteCartOrders`,

        [id],

        config
      );

      const responseCart = await axios.get(
        `${SERVER_BASE_URL}/cart/getAllCartOrderByUserId/${customerId}`,

        config
      );

      setDeleteIconDisabled(false);

      dispatch({
        type: ActionTypes.GET_ALL_CART_ORDERS,

        payload: responseCart.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }

      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const placeCartOrders =
  (customerId, customerNumber, orders, navigate, authToken) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/customer/${customerNumber}/placeCartOrders`,
        orders,
        config
      );

      const CartDataresponse = await axios.get(
        `${SERVER_BASE_URL}/cart/getAllCartOrderByUserId/${customerId}`,
        config
      );

      dispatch({
        type: ActionTypes.GET_ALL_CART_ORDERS,
        payload: CartDataresponse.data
      });

      toast.success('Order Received by i5. Processing can take up to 15 minutes.');
      navigate(`customer-portal/${customerId}/cart?cp=true`);
      navigate(`/customer-portal/${customerId}/products?cp=true`);
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const getAllCustomerShippingLocations =
  (customerId, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/customer/getAllCustomerShippingLocations/${customerId}`,
        config
      );

      dispatch({
        type: ActionTypes.GET_CUSTOMER_SHIPPING_LOCATION,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const clearCartFieldError = (args) => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_CART_FIELD_ERROR,
    payload: args
  });
};
export const partNumberState = (partNumber, id) => (dispatch) => {
  dispatch({
    type: ActionTypes.PART_NUMBER,
    payload: { partNumber, id }
  });
};
