import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
  } from 'react';
  import { AgGridColumn, AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
  import Typography from '@mui/material/Typography';
  
  import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
  import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
  import 'react-date-range/dist/styles.css'; // main style file
  import 'react-date-range/dist/theme/default.css'; // theme css file
  import {
    Box,
    styled,
  } from '@mui/material';
  import './DataGrid.css';
  
  import { useDispatch, useSelector } from 'react-redux';
  import moment from 'moment';
  import CustomModal from '@mui/material/Modal';
  import usePagination from 'src/services/pagination/pagiantion';
  import { toast } from 'react-toastify';
  
  import DataTable from 'src/components/DataTables/DataTable';
  import SERVER_BASE_URL from 'src/config/config';
  import $ from 'jquery';
  import ReactDOMServer from 'react-dom/server';
  
  import { faPencil } from '@fortawesome/free-solid-svg-icons';
  
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import EditCustomerPartForm from "src/content/SystemAdmin/ManageUsers/SingleCustomer/CustomerTabs/PartsConfiguration/EditPartForm";
  
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '88%', sm: '100%' },
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    pr: 4,
    pl: 4,
    pb: 4,
    bordeRadius: '5px',
    maxHeight: '70%',
    overflowY: 'scroll'
  };
  
  const TableContainer = styled(Box)(
    () => `
    padding: 15px 24px;
    background-color: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    `
  );
  
  const PartsConfigurationTable = () => {
    const dispatch = useDispatch();

    const [editPartsConfigurationOpen, setEditPartsConfigurationOpen] = useState(false);
  
    const [partsConfigurationDataTableColumns, setPartsConfigurationDataTableColumns] = useState(false);
    const [partsConfigurationDataTableOptions, setPartsConfigurationDataTableOptions] = useState(false);
    const [partsConfigurationDataTableData, setPartsConfigurationDataTableData] = useState(false);
  
    const customerData = useSelector((state) => state?.customers?.customerData);
    const [editPartsConfigurationData, setEditPartsConfigurationData] = useState(false);
  
    const pencilIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faPencil} />);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { md: '40%', sm: '100%' },
      bgcolor: 'background.paper',
      boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
      p: 4,
      bordeRadius: '5px'
    };
  
    useEffect(() => {
        let columns = [
            {
                title: 'Action',
                name: 'action',
                data: 'customerNumber',
                render: function (data, type, row) {
                    console.log(row);
                    return `<span role="button" class="ms-3 fs-6" data-button="edit" data-customerNumber="${row.customerNumber}" 
                    data-partNumber="${row.partNumber}" data-width="${row.width}" data-partId="${row.id}" 
                    data-product="${row.productClass} ${row.dim1} ${row.dim2} ${row.dim3} ${row.idesc} ${row.odesc}">${pencilIcon}</span>`;
                }
            },
            {
                title: 'Part Number',
                name: 'partnumber',
                data: 'partNumber'
            },
            {
                title: 'Product',
                name: 'product',
                data: 'productClass',
                render: function (data, type, row) {
                    return `${row.productClass} ${row.dim1} ${row.dim2} ${row.dim3} ${row.idesc} ${row.odesc}`
                }
            },
            {
                title: 'Length',
                name: 'length',
                data: 'length'
            },
            {
                title: 'Width',
                name: 'width',
                data: 'width'
            },
            {
                title: 'Price',
                name: 'price',
                data: 'price'
            },
            {
                title: 'Lead Time',
                name: 'leadtime',
                data: 'leadTimeWeeks'
            }
        ];
        setPartsConfigurationDataTableColumns(columns);
    }, []);
  
    const partsConfigurationDataTableButtons = () => {
      let buttons = [];
      
      buttons.push(
        { extend: 'copy', className: 'btn-outline-secondary' },
        { extend: 'csv', className: 'btn-outline-secondary' },
        { extend: 'print', className: 'btn-outline-secondary' }
      );
      return buttons;
    };
  
    const handleRowClick = (event) => {
      let row = $(event.currentTarget).data();
      if (row.button == 'edit') {
        handleEditOpen(event);
      }
    }
  
    const handleEditOpen = (event) => {
      let target = event.target;
      if ($(target).closest('svg').data('icon') === "pencil") {
        target = $(event.target).closest('svg').parent();
      }
      let data = $(target).data();
  
      setEditPartsConfigurationData({
        customerNumber: data.customernumber,
        partNumber: data.partnumber,
        product: data.product,
        width: data.width,
        partId: data.partid
      });
      setEditPartsConfigurationOpen(true);
    }
  
    const handleEditClose = () => {
      setEditPartsConfigurationOpen(false);
    };
  
    useEffect(() => {
      let options = {
        dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
          "<'row'<'col-12'tr>>" +
          "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
        buttons: partsConfigurationDataTableButtons(),
        ajax: {
          url: `${SERVER_BASE_URL}/part/list/cn/${customerData?.customerNumber}?pageSize=1000000&pageIndex=0`, //double check url path when given row of data
          type: "GET",
          dataSrc: function (data) {
            setPartsConfigurationDataTableData(data);
            return data.content;
          },
          // dataSrc: 'content',
          dataType: 'JSON',
          timeout: 0,
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
        info: true,
        processing: true,
        retrieve: true,
        pageResize: true,
        scrollCollapse: true,
        lengthChange: false,
        pageLength: 20,
        fixedHeader: true,
        order: [[0, 'asc']],
        searching: true,
        select: false,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
        }
      };
      setPartsConfigurationDataTableOptions(options);
    }, [customerData?.customerNumber]);

  
    const authToken = useSelector((state) => state?.auth?.token?.token);
  
    const gridStyle = useMemo(
      () => ({ height: '100%', width: '100%', margin: '0px 0 12px 0' }),
      []
    );
  
    let width = 100;
  
    const onGridSizeChanged = (params) => {
      let columnCount = params?.columnApi?.columnModel?.gridColumns?.length;
      width = params?.clientWidth / columnCount;
      params?.api?.sizeColumnsToFit();
    };
  
    return (
      <Box>
        <TableContainer style={gridStyle}>
          {partsConfigurationDataTableColumns && partsConfigurationDataTableOptions && (
            <DataTable
              id={'partsConfigurationTable'}
              columns={partsConfigurationDataTableColumns}
              options={partsConfigurationDataTableOptions}
              onRowClick={handleRowClick}
            />
          )}
        </TableContainer>
          
        <CustomModal id="editModal" className="edit_external_modal" open={editPartsConfigurationOpen} onClose={handleEditClose}>
          <Box sx={style}>
            <div>
              <Typography id="keep-mounted-modal-title" variant="h3" component="h2">Edit Customer Part</Typography>
              <span>All fields with <span style={{ color: 'red' }}> *</span> are mandatory</span>
            </div>
            <div style={{ marginTop: '30px' }}>
                <EditCustomerPartForm partConfigurationData={editPartsConfigurationData} close={handleEditClose} />
            </div>
          </Box>
        </CustomModal>
  
      </Box>
    );
  };
  
  export default PartsConfigurationTable;
  