import { ActionTypes } from "../constants/action-types";
import SERVER_BASE_URL from '../../config/config';
import axios from 'axios';

const fetchRawMaterial = () => async (dispatch) => {
    try {
        const response = await fetch(`${SERVER_BASE_URL}/raw-material-depletion-analysis/search?keyword=WPFBOBCAT6.000.625HREW`);
        const payload = await response.json();
        dispatch({ type: ActionTypes.RAW_MATERIAL_FETCH_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: ActionTypes.RAW_MATERIAL_FETCH_ERROR, payload: error.message });
    }
};

const fetchRawMaterialGrouped1 = () => async (dispatch) => {
    try {
        const response = await fetch(`${SERVER_BASE_URL}/raw-material-depletion-analysis/grouped-by-material`);
        const payload = await response.json();
        dispatch({ type: ActionTypes.RAW_MATERIAL_GROUPED_FETCH_SUCCESS, payload });
    } catch (error) {
        dispatch({ type: ActionTypes.RAW_MATERIAL_GROUPED_FETCH_ERROR, payload: error.message });
    }
};


export const fetchRawMaterialGrouped =
  (
    authToken,
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      let response;

      response = await axios.get(
        `${SERVER_BASE_URL}/raw-material-depletion-analysis/grouped-by-material`,
        {

        },
        config
      );

      dispatch({
        type: ActionTypes.RAW_MATERIAL_GROUPED_FETCH_SUCCESS,
        payload: response.data
      });

      // toast.success('Part Number Added Successfully');
    } catch (e) {
      console.log(e, 'eee');
    }
  };


export {fetchRawMaterial};



export const fetchRawMaterialData =
  (
    id,
    customerNumber,
    authToken,
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      let response;

      response = await axios.get(
        `${SERVER_BASE_URL}/raw-material-depletion-analysis/get/${id}/${customerNumber}`,
        {

        },
        config
      );

      dispatch({
        type: ActionTypes.RAW_MATERIAL_FETCH_SUCCESS,
        payload: response.data
      });

      // toast.success('Part Number Added Successfully');
    } catch (e) {
      console.log(e, 'eee');
    }
  };


  export const clearData = () => {
    return {
      type: ActionTypes.CLEAR_DATA,
    };
  };