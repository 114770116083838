import { useCallback, useEffect, useRef } from 'react';
import { Box, Button, styled, TextField, MenuItem, Grid } from '@mui/material';
import calenderIcon from '../../../../assets/Icons/calender.svg';
import DeleteIcon from 'src/assets/Icons/Delete.png';
import Select from 'react-select';
import { useState } from 'react';
import useFormControls from './validations';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const CustomInput = styled(TextField)(
  () => `
    .MuiInputBase-root {
      height: 40px;
      border-radius: 0px;
      background: #F4F7F9;
  
      border: 1px solid #B8C2C8;
      color: #15364A;
    }
  
          :after{
            border-bottom: unset !important;
            border: 1px solid #20A2F3 !important;
  
          }
          :before{
            border-bottom: unset !important;
            border: 1px solid #20A2F3 !important;
  
          }
  
          .Mui-disabled:after {
            border-bottom: unset !important;
            border-bottom-style: solid;
            background: white;
          }
          .Mui-disabled:before {
            border-bottom: unset;
            border-bottom-style: solid;
            background: white;
          }
          .Mui-disabled {
            background: white;
          }
          .css-trgup9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
            // border-color: unset;
          }
        `
);

const CancelButton = styled(Button)(
  () => `
  color: white !important;
  background: #15364A  !important;
  border-radius: 3px  !important;
  height: 38px !important;
  width: 90px !important;

  :hover {
      background-color:  #022f53  !important;
    }
    :disabled {
      opacity: 0.8 !important;
      color: white
    }
  `
);

const LabelBox = styled(Box)(
  () => `
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
        `
);

const CustomSelect = styled(Select)(
  () => `
 
  .MuiInputBase-root {
    height: 40px;
    border-radius: 0px;
    background: #F4F7F9;

    border: 1px solid #B8C2C8;
    color: #15364A;
  }

        :after{
          border-bottom: unset !important;
          border: 1px solid #20A2F3 !important;

        }
        :before{
          border-bottom: unset !important;
          border: 1px solid #20A2F3 !important;

        }

        .Mui-disabled:after {
          border-bottom: unset !important;
          border-bottom-style: solid;
          background: white;
        }
        .Mui-disabled:before {
          border-bottom: unset;
          border-bottom-style: solid;
          background: white;
        }
        .Mui-disabled {
          background: white;
        }
        .css-trgup9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
          // border-color: unset;
        }
      `
);

const PartNameText = styled(Box)(
  () => `
  
    font-weight: 700;
    font-size: 14px;
    `
);

const InputBox = styled(Box)(
  () => `
    background: #15364A;
    border: 1px solid #15364A;
    color: white;
    width: 52px;
  
    
    `
);

const ModalHeader = styled(Box)(
  () => `
      font-weight: 700;
       font-size: 20px;
      `
);

const RequestOrderEditForm = ({
  selectedData,
  sd,
  handleModalClose,
  gridRef,
  setSelectedData,
  handleDeselect,
  page,
  setPage
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const supplierData = useSelector((state) => state?.suppliers?.supplierData);

  const authToken = useSelector((state) => state?.auth?.token?.token);
 


  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    resetFields,
    date,
    setValues,
    values,
    submit,
    setSubmit
  } = useFormControls(
    selectedData,
    handleModalClose,
    handleDeselect,
    setSelectedData,
    setPage
  );

  useEffect(() => {
    if (submit === true) {
      setSelectedData({});
      handleModalClose();
      setSubmit(false);
    }
  }, [submit]);

  const deleteRecord = useCallback(
    (event, pageNo, ind, data) => {
   
      if (page != pageNo) {
        setPage(pageNo);
      }
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      const select = gridRef.current.api.getSelectedNodes();

  
      const arr = [...sd[pageNo]]?.filter((f) => {
        return f?.id !== data?.id;
      });

     

      const arr2 = [...values[pageNo]]?.filter((f) => {
        return f?.purchaseOrderI5Id !== data?.purchaseOrderI5Id;
      });
    

      select.forEach((s, index) => {
      
        if (s?.data?.id === data?.id) {
          s?.setSelected(false);
        }
      });
      

      if (Object.values(sd)?.flat()?.length === 1) {
        // setData([...arr]);
        setSelectedData({});
        gridRef.current.api.deselectAll();
        handleModalClose();
        return;
      }
     
      setSelectedData({ ...sd, [+pageNo]: [...arr] });
      setValues({ ...values, [+pageNo]: [...arr2] });
    },
    [sd, page, loading, values]
  );

  const RequestOrderEditForm = useCallback(() => {
    return (
      <>
       
        {sd &&
          Object?.keys(sd)?.map((page, i) =>
            sd[page]?.map((d, j) => (
              <div key={d?.id}>
                <div style={{ margin: '20px 0' }}>
                  <Grid sx={{ flexGrow: 1 }}>
                    <PartNameText>PO Number: {`${d?.poNumber}-${d?.poLineNumber} ${d?.productSKU}`}</PartNameText>{' '}
                    <PartNameText>SKU: {`${d?.productSKU}`}</PartNameText>{' '}
                  </Grid>
                </div>
                <Grid container>
                  <Grid xs={3}>
                 
                    <LabelBox>Quantity</LabelBox>
                    <Box
                      sx={{
                        marginRight: '16px',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <CustomInput
                        name="quantity"
                        autoComplete="none"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        {...(errors['qty'] && {
                          error: true,
                          helperText: errors['qty']
                        })}
                        value={parseInt(values[page]?.[j]?.quantity)}
                        maxLength={11}
                        onChange={(e) =>
                          handleInputValue(e, 'string', 'quantity', page, j)
                        }
                      />
                      
                    </Box>
                    <span style={{ color: '#F61616' }}>
                      
                    </span>
                  </Grid>
                  <Grid xs={2}>
                    <LabelBox>Due Date</LabelBox>
                    <Box
                      className="dueDate"
                      sx={{
                        display: 'flex',
                        marginRight: '16px',
                        flexDirection: 'row'
                      }}
                    >
                      <DesktopDatePicker
                        name="dueDate"
                        inputFormat="MM/dd/yyyy"
                        type="date"
                        value={values && values[page]?.[j]?.dueDate}
                        onChange={(e) =>
                          handleInputValue(e, 'date', '', page, j)
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                      
                    </Box>
                    <span style={{ color: '#F61616' }}>
                      
                    </span>
                  </Grid>
                  
                  <Grid xs={2}>
                    <LabelBox>Cost</LabelBox>
                    <Box
                      sx={{
                        display: 'flex',
                        marginRight: '16px',
                        flexDirection: 'row'
                      }}
                    >
                      <CustomInput
                        name="cost"
                        autoComplete="none"
                        type="number"
                        value={parseFloat(values[page]?.[j]?.cost)}
                        {...(errors['qty'] && {
                          error: true,
                          helperText: errors['qty']
                        })}
                        onChange={(e) =>
                          handleInputValue(e, 'string', 'cost', page, j)
                        }
                      />
                    </Box>
                    <span style={{ color: '#F61616' }}>
                      
                    </span>
                  </Grid>
                  <Grid xs={2}>
                    <LabelBox>Reasons</LabelBox>
                    <Box
                      sx={{
                        display: 'flex',
                        marginRight: '5px',
                        flexDirection: 'row'
                      }}
                    >
                    <CustomSelect
                      closeMenuOnSelect={true}
                      name="remarks"
                      options={
                        [
                          {
                            label: 'MK request',
                            value: 'MK request'
                          },
                          {
                            label: 'Supplier request',
                            value: 'Supplier request'
                          },
                          {
                            label: 'MK approved move in date',
                            value: 'MK approved move in date'
                          }
                        ]
                      }
                      defaultValue={
                        {
                          label: 'Supplier request',
                          value: 'Supplier request'
                        }
                      }
                      className="basic-multi input_box"
                      classNamePrefix="select"
                      onChange={(e) => handleInputValue(e, 'select_string', 'remarks', page, j)}
                    />
                    </Box>
                  </Grid>
                  <Grid xs={1}>
                    {loading ? (
                      <div style={{ cursor: 'pointer', margin: '30px' }}>
                        ...
                      </div>
                    ) : (
                      <img
                        className="Img"
                        width="20px"
                        style={{ cursor: 'pointer', margin: '30px' }}
                        onClick={(e) => deleteRecord(e, page, j, d)}
                        src={DeleteIcon}
                      ></img>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))
          )}
      </>
    );
  }, [sd, values, loading, page]);

 
  return (
    <>

      <ModalHeader>Enter Order Changes</ModalHeader>
      <form onSubmit={handleFormSubmit}>
        {RequestOrderEditForm()}
        <Box
          sx={{
            '& > :not(style)': { mr: 2, mt: 3 },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right'
          }}
        >
          <Grid item xs={6} md={2}>
            <CancelButton onClick={handleModalClose}>Cancel</CancelButton>
          </Grid>
          <Grid item xs={6} md={2}>
            <CancelButton disabled={!formIsValid()} type="submit">
              Request
            </CancelButton>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default RequestOrderEditForm;
