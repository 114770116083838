import React from 'react';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.css';
import 'datatables.net-select-bs5/css/select.bootstrap5.css';
import './DataTable.css';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-bs5';
// import 'datatables.net-plugins';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-rowgroup-bs5';

export default class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.dataTable = null;
        this.tableRef = React.createRef();
        // Bind custom method to the component instance
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.$el = $(this.el);
        //override default buttons classes
        $.fn.DataTable.Buttons.defaults.dom.container.className = 'dt-buttons btn-group flex-wrap btn-group-sm';
        $.fn.DataTable.Buttons.defaults.dom.button.className = 'btn';
        //Requiring columns & options props
        if (!this.props.columns || !this.props.options) {
            return false;
        }
        this.dataTable = this.$el.DataTable({
            // data: this.props.data,
            columns: this.props.columns,
            ...this.props.options,
            ...this.props.bordered,
            ...this.props.striped
        });
        $(`#${this.props.id} tbody`).on('click', 'tr', function () {
            //Only try checking the box if Select is enabled
            let tableInit = $(this).closest('table').DataTable().init();
            if (tableInit.hasOwnProperty('select') && tableInit.select !== false) {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected').find('input').prop("checked", false).trigger("change");
                } else {
                    $(this).addClass('selected').find('input').prop("checked", true).trigger("change");
                }
            }
        });
        this.dataTable.on('user-select', function (event, dt, type, indexes) {
            let row = dt.row(indexes[0][0].row).node();
            if ($(row).find('input').length === 0) {
                event.preventDefault();
            }
        });
        $(`#${this.props.id} tbody`).on('click', 'a, [role="button"]', this.handleClick);
    }

    componentWillUnmount() {
        if (this.dataTable != null && $.fn.DataTable.isDataTable(`#${this.props.id}`)) {
            this.dataTable.destroy(true);
            $(`#${this.props.id} tbody`).off('click', 'a, [role="button"]', this.handleClick);
        }
    }

    // connecting search to an external component, optional but shows how to access the API
    search = (value) => {
        this.dataTable.search(value).draw();
    };

    handleClick(event) {
        // Access any React component method or state here
        // Example: Call a method from the parent component
        this.props.onRowClick(event); // Assuming onRowClick is a prop passed from parent
    }

    handleDeselect() {
        this.dataTable.rows().deselect();
        $('tr:not(".selected")', $(`#${this.props.id} tbody`)).find('input').prop("checked", false).trigger("change");
    };

    render() {
        if (this.props.bordered) {
            return <table className="datatable-simple display table table-bordered table-striped table-hover w-100"
                id={this.props.id}
                ref={(el) => (this.el = el)} />;
        }
        return <table className="datatable-simple display table table-striped table-hover w-100"
            id={this.props.id}
            ref={(el) => (this.el = el)} />;
    }
}