import { Helmet } from 'react-helmet-async';
import { Box, styled, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerPortalEditPartsTable from 'src/content/CustomerPortal/EditParts/Table/index';

const MainContainer = styled(Box)(
  () => `
  padding: 0px 32px 0px 32px;
  `
);


function CustomerPortalEditParts() {
  const navigate = useNavigate();

  const customerData = useSelector((state) => state?.customers?.customerData);
  const customerNumber = customerData?.customerNumber;

  useEffect(() => {
    navigate('?cp=true');
  }, []);
  return (
    <>
      <MainContainer>
        <CustomerPortalEditPartsTable  customerNumber={customerNumber} />
      </MainContainer>
    </>
  );
}

export default CustomerPortalEditParts;
