import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

const TableDataPerPage = ({
  currentPageDataLength,
  currentPage,
  itemsPerPage,
  totalDataLength,
  setDataCount,
  setTableDataCount,
  data
}) => {
  useEffect(() => {
    // Simulating fetching data from backend and updating userData
    // Replace this with your actual data fetching logic
    const mockData = Array.from({ length: data?.length }, (_, index) => ({
      id: index + 1,
      name: `${index + 1}`
      // Other user properties...
    }));
    setDataCount(mockData);
  }, [data]);

  useEffect(() => {
    // Calculate the tableData based on the current page and itemsPerPage
    const startIndex = (currentPage - 1) * +itemsPerPage;
    const endIndex = Math.min(startIndex + +itemsPerPage, data?.length);
    const dataOnCurrentPage = data?.slice(startIndex, endIndex);
    setTableDataCount(dataOnCurrentPage);
  }, [currentPage, totalDataLength, +itemsPerPage]);

  return (
    <Box marginLeft={2}>
      {currentPageDataLength?.length > 0
        ? `${(currentPage - 1) * +itemsPerPage + 1}-${
            (currentPage - 1) * +itemsPerPage + currentPageDataLength?.length
          }/${totalDataLength?.length}`
        : ''}
    </Box>
  );
};

export default TableDataPerPage;
