import * as React from 'react';
import {
  UpdateCustomerPart
} from 'src/redux/actions/sysAdminActions';
import UserForm from './index';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useFormControls = (partConfigurationData) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // We'll update "values" as the form updates
  const [values, setValues] = React.useState();
  const [clearState, setClearState] = React.useState(false);
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const authUser = useSelector((state) => state?.auth?.authUserData);
  const customerData = useSelector((state) => state?.customers?.customerData);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (partConfigurationData) {
      const initialFormValues = {
        customerNumber: partConfigurationData?.customerNumber,
        partId: partConfigurationData?.partId,
        partNumber: partConfigurationData?.partNumber,
        product: partConfigurationData?.product
      };
      
      setValues(initialFormValues);
    }
  }, []);

  const resetFields = () => {
    const initialFormValues = {
        partNumber: '',
        product: '',
        width: '',
        partId: ''
    };
    setValues(initialFormValues);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e, type, nm) => {
    let obj = values;
    if (type === 'string') {
      obj = { ...obj, [e?.target.name]: e?.target.value };

      validate({ [e?.target.name]: e?.target.value });
    }

    if (type === 'select_string') {
      obj = { ...obj, [nm]: e?.value };
    }

    if (type === 'select_object') {
    obj = { ...obj, [nm]: e?.value };
    }
    if (type === 'array') {
    obj = { ...obj, [nm]: e?.map((item) => item.value) };
    // validate({ ...obj, [nm]: e.map((item) => item.value) });
    }    

    setValues(obj);
    //
  };

  const handleFormSubmit = async (e, reset) => {
    e?.preventDefault();
    if (formIsValid()) {
    dispatch(
        UpdateCustomerPart(
        {...values },
        customerData?.customerNumber,
        values.partId,
        reset,
        authToken
        )
    );
    }    
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues?.width
      Object.values(errors).every((x) => x === '');

    return isValid;
  };
  return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    resetFields,
    values,
    errors
    };
};

export default useFormControls;
