import * as React from 'react';
import Box from '@mui/material/Box';
import Select from 'react-select';
import DeleteIcon from '../../../../../../assets/Icons/Delete.png';
import CustomModal from '@mui/material/Modal';
import DateFnsUtils from '@date-io/date-fns';
import { FileUploader } from 'react-drag-drop-files';

import { Helmet } from 'react-helmet-async';

import trashIcon from 'src/assets/Icons/trashRed.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  FormControlLabel,
  Button,
  FormLabel,
  Grid,
  styled,
  TextField
} from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import useFormControls from './Validations';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import SupplierInfo from './SupplierInfo';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';

import {
  getPrefferedTimeZone,
  getPrefferedUom
} from 'src/redux/actions/sysAdminActions';
import { useParams } from 'react-router';
window.Buffer = window.Buffer || require('buffer').Buffer;

const TopBox = styled(Box)(
  () => `
  
   padding: 40px 0 22px 0;
   
   background: #FFFFFF
    
    `
);

const CustomLabel = styled(FormControlLabel)(
  () => `
    margin: 0
    `
);

const AddButton = styled(Button)(
  () => `
          color: white !important;
          background: #15364A  !important;
          border-radius: 3px  !important;
          height: 38px !important;
          width: 63px !important;
        
  
          :hover {
              background-color:  #022f53  !important;
            }
          :disabled {
            color: #aeaeae !important;
          }
          `
);

const CancelButton = styled(Button)(
  () => `
    background: #FFFFFF  !important;
    border: 1px solid #15364A  !important;
    border-radius: 3px  !important;
    color: #15364A  !important;
    height: 38px !important;
    width: 84px !important
        
            :hover {
                background-color: white  !important;
              }
            
            `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 4,
  bordeRadius: '5px'
};

const SubContentContainer = styled(Box)(
  () => `
    padding-bottom: 48px;
      border-bottom: 2px solid #D9D9D9;
    `
);

const Header = styled(Box)(
  () => `
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    padding: 34px 0 16px 0;
    `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);
const TimeInput = styled(KeyboardTimePicker)(
  () => `
    .MuiInputBase-root {
      padding-left: 10px;
      height: 40px !important;
      border-radius: 4px !important;
      width: 100% !important;
      background: #F4F7F9 !important;
      border: 1px solid #B8C2C8 !important;
      color: #15364A !important;
    }
  
          :after{
            border-bottom: unset !important;
  
          }
          :before{
            border-bottom: unset !important;
  
          }
  
          .Mui-disabled:after {
            border-bottom: unset !important;
            border-bottom-style: solid;
            background: white;
          }
          .Mui-disabled:before {
            border-bottom: unset;
            border-bottom-style: solid;
            background: white;
          }
          .Mui-disabled {
            background: white;
          }
          .css-trgup9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
            // border-color: unset;
          }
        `
);

const CustomInput = styled(TextField)(
  () => `
    .MuiInputBase-root {
      height: 40px !important;
      border-radius: 4px !important;
      width: 100% !important;
      background: #F4F7F9 !important;
      color: #15364A !important;
    }
  
          :after{
            border-bottom: unset !important;
  
          }
          :before{
            border-bottom: unset !important;
  
          }
  
          .Mui-disabled:after {
            border-bottom: unset !important;
            border-bottom-style: solid;
            background: white;
          }
          .Mui-disabled:before {
            border-bottom: unset;
            border-bottom-style: solid;
            background: white;
          }
          .Mui-disabled {
            background: white;
          }
          .css-trgup9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
            // border-color: unset;
          }
        `
);

const CustomInput1 = styled(TextField)(
  () => `
    .MuiInputBase-root {
      height: 36px;
      background: #F4F7F9 ;
      border-radius: 4px;
      width: 100%;
    }
  
          :after{
            border-bottom: unset !important;
  
          }
          :before{
            border-bottom: unset !important;
  
          }
  
          .Mui-disabled:after {
            border-bottom: unset !important;
            border-bottom-style: solid;
          }
          .Mui-disabled:before {
            border-bottom: unset;
            border-bottom-style: solid;
          }
          .Mui-disabled {
            color: white !important;
            background: #f5f5f5;
          }
        `
);

export default function Example() {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#ffffff !important',
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }));


  const dispatch = useDispatch();
  const { id } = useParams();
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const buyerUsers = useSelector((state) => state?.users?.usersByRole);

  const supplierData = useSelector((state) => state?.suppliers?.supplierData);
  const uomData = useSelector((state) => state?.suppliers?.getUomData);
  const timeZoneData = useSelector(
    (state) => state?.suppliers?.getTimezoneData
  );

  const [data, setData] = React.useState();
  const [status, setStatus] = React.useState();

  const buyerUserOption = buyerUsers?.map((user) => {
    return {
      label: user?.email,
      value: user?.id
    };
  });




  React.useEffect(() => {
    dispatch(getPrefferedTimeZone(authToken));
  }, []);


  const timeZone = timeZoneData?.map((d) => ({
    label: d?.timezoneName,
    value: d?.timezoneCode
  }));

  const onStatusChange = (e) => {

    setStatus(e.target.checked);
    handleFormSubmit(e, 'Status');
  };

  const { handleInputValue, handleFormSubmit, values, formIsValid, errors } =
    useFormControls(data, undefined);

  let supplierTimeZoneLabel = timeZone?.find(
    (d) => d?.value === supplierData?.timeZone?.toUpperCase() && d?.label
  );

  return (
    <>
      <form
        onSubmit={(e) =>
          handleFormSubmit(
            e,
            'Supplier Information'
          )
        }
      >
        <TopBox>
          <Box
            sx={{
              display: { md: 'flex', xs: 'box' },
              flexDirection: 'row'
            }}
          >
            {' '}
            <Box sx={{ flexGrow: 1 }}>
              <CustomLabel
                label={
                  supplierData?.status?.code === 'A' ? 'Active' : 'Inactive'
                }
                control={
                  <IOSSwitch
                    defaultChecked={
                      supplierData?.status?.code === 'A' ? true : false
                    }
                    checked={status}
                    onChange={(e) => onStatusChange(e)}
                    name="statusCode"
                    sx={{ mr: 1 }}
                  />
                }
              />
            </Box>
            <button
              type="submit"
              style={{
                backgroundColor: '#15364A',
                color: 'white',
                padding: '10px 15px',
                cursor: 'pointer',
                borderRadius: '3px'
              }}
            >
              Save
            </button>
          </Box>{' '}
        </TopBox>
        <SupplierInfo />
        <SubContentContainer>
          <Header>Supplier Information</Header>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          ></Grid>
          <Grid item xs={12} md={3} sx={{ margin: '10px 0' }}>
            <LabelBox>Associated Buyers</LabelBox>
            <Select
              closeMenuOnSelect={false}
              isMulti
              fullWidth
              fullHeight
              key={
                supplierData &&
                supplierData?.associatedUsers
                  ?.filter((f) => f?.userType === 'I')
                  ?.map((d) => {
                    return {
                      label: <div title={d?.email}>{d?.email}</div>,
                      value: d?.userId
                    };
                  })
              }
              name="associatedUsers"
              options={buyerUserOption}
              className="basic-multi-select overFlow_dropdown select_multi input_box"
              defaultValue={
                supplierData &&
                supplierData?.associatedUsers
                  ?.filter((f) => f?.userType === 'I')
                  ?.map((d) => {
                    return {
                      label: <div title={d?.email}>{d?.email}</div>,
                      value: d?.userId
                    };
                  })
              }
              classNamePrefix="select"
              onChange={(e) =>
                handleInputValue(e, 'array', 'associatedUsers')
              }
            />
          </Grid>

        </SubContentContainer>
      </form>
    </>
  );
}
