import axios from 'axios';
import { ActionTypes } from '../constants/action-types';
import SERVER_BASE_URL from '../../config/config';
import { toast } from 'react-toastify';
import store from '../store';
import { array } from 'prop-types';
import moment from 'moment';

export const submitPOChangeRequest =
(
  values,
  id,
  authToken,
  handleModalClose,
  handleDeselect,
  setSelectedData,
  setSubmit,
  setPage
) =>
async (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${authToken}` }
  };
  try {
    handleModalClose(true);
    const response = await axios.post(
      `${SERVER_BASE_URL}/supplier/addOrderChangeRequest`,
      values,
      config
    );

    if (
      response?.data?.find(
        (d) =>
          d?.errorsMap?.customerId ||
          d?.errorsMap?.dueDate ||
          d?.errorsMap?.partNumber ||
          d?.errorsMap?.purchaseOrderNumber ||
          d?.errorsMap?.quantity ||
          d?.errorsMap?.shippingLocationId ||
          d?.errorsMap?.uom
      )
    ) {
      toast.error('Error while submitting request');
      
    } else {
      toast.success('Order update request submitted');
      handleDeselect.current.api.deselectAll();
      setSelectedData({});
      setPage(1);
      setSubmit(true);
      handleModalClose(true);

      return response;
    }

    const dataresponse = await axios.get(
      `${SERVER_BASE_URL}/supplier/addOrderChangeRequest/${id}`,
      config
    );

    dispatch({
      type: ActionTypes.GET_ADD_ORDER_CHANGE_REQUEST,
      payload: response.data
    });
  } catch (e) {
    if (e?.response?.status === 401) {
      dispatch({
        type: ActionTypes.LOGOUT
      });
    }
    toast.error(e?.response?.data?.errorDescription);
  }
};
  
export const getPOSalesTableData =
  (
    supplierNumber,
    searchValue,
    quantity,
    dateRange,
    authToken,
    pageIndex,
    poSalesItemsPerPage,
    posort,
    posortType
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      let response;
      if (dateRange[0].startDate === null && !searchValue) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            sortField: posortType,
            sortOrder: posort,
            pageSize: parseInt(poSalesItemsPerPage),
            pageIndex: pageIndex - 1
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_SALES_TABLE_DATA,
          payload: response.data
        });
      } else if (searchValue.length > 0) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,

            sortField: posortType,
            sortOrder: posort,
            pageSize: 9999,
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_SALES_TABLE_DATA,
          payload: response.data
        });
      } else {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            startDueDate: `${moment(dateRange[0].startDate).format(
              'MM-DD-YYYY'
            )}`,
            endDueDate: `${moment(dateRange[0].endDate).format('MM-DD-YYYY')}`,
            sortField: posortType,
            sortOrder: posort,
            pageSize: parseInt(poSalesItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_SALES_TABLE_DATA,
          payload: response.data
        });
      }

      // toast.success('Part Number Added Successfully');
    } catch (e) {}
  };

  export const POSalesTable =
  (
    supplierNumber,
    searchValue,
    quantity,
    dateRange,
    authToken,
    pageIndex,
    poSalesItemsPerPage,
    posort,
    posortType
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      let response;

      if (dateRange[0].startDate === null && !searchValue) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            sortField: posortType,
            sortOrder: posort,
            pageSize: parseInt(poSalesItemsPerPage),
            pageIndex: pageIndex - 1
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_SALES_TABLE_DATA,
          payload: response.data
        });
      } else if (searchValue.length > 0) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,

            sortField: posortType,
            sortOrder: posort,
            pageSize: 9999,
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_SALES_TABLE_DATA,
          payload: response.data
        });
      } else {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            startDueDate: `${moment(dateRange[0].startDate).format(
              'MM-DD-YYYY'
            )}`,
            endDueDate: `${moment(dateRange[0].endDate).format('MM-DD-YYYY')}`,
            sortField: posortType,
            sortOrder: posort,
            pageSize: parseInt(poSalesItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_SALES_TABLE_DATA,
          payload: response.data
        });
      }

      // toast.success('Part Number Added Successfully');
    } catch (e) {}
  };


  export const POSalesExcelData =
  (
    supplierNumber,
    searchValue,
    quantity,
    dateRange,
    authToken,
    pageIndex,
    excelToggle
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      excelToggle(false);
      let response;

      response = await axios.post(
        `${SERVER_BASE_URL}/supplier/list/${supplierNumber}`,
        {
          search: `${searchValue}`,
          sortOrder: 'DESC',
          pageSize: 99999,
          pageIndex: 0
        },
        config
      );

      dispatch({
        type: ActionTypes.GET_SUPPLIER_PO_EXCEL_DATA,
        payload: response.data
      });

      excelToggle(true);

      // toast.success('Part Number Added Successfully');
    } catch (e) {
      console.log(e, 'eee');
    }
  };

  export const getExternalSupplier =
  (supplierNumber, authToken) => async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` }
      };
      const response = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/EU`,
        config
      );

      dispatch({
        type: ActionTypes.GET_EXTERNAL_SUPPLIERS,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
        
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const POChangeRequestTable =
  (
    supplierNumbers,
    searchValue,
    quantity,
    authToken,
    pageIndex,
    poItemsPerPage,
    poSort,
    filteredData,
    poSortType
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      let response;

      if (dateRange[0].startDate === null && !searchValue) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: pageIndex - 1
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      } else if (searchValue.length > 0) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,

            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      } else {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            startDueDate: `${moment(dateRange[0].startDate).format(
              'MM-DD-YYYY'
            )}`,
            endDueDate: `${moment(dateRange[0].endDate).format('MM-DD-YYYY')}`,
            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      }

      // toast.success('Part Number Added Successfully');
    } catch (e) {}
  };

  export const POChangeRequestExcelData =
  (
    supplierNumbers,
    searchValue,
    quantity,
    dateRange,
    authToken,
    pageIndex,
    excelToggle
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      excelToggle(false);
      let response;
      response = await axios.post(
        `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
        {
          search: `${searchValue}`,
          sortOrder: 'DESC',
          pageSize: 99999,
          pageIndex: 0
        },
        config
      );

      dispatch({
        type: ActionTypes.GET_PO_CHANGE_REQUEST_EXCEL_DATA,
        payload: response.data
      });

      excelToggle(true);

      // toast.success('Part Number Added Successfully');
    } catch (e) {
      console.log(e, 'eee');
    }
  };

  export const ApproveRequestOrderData = 
  (
    values,
    authToken,
    supplierNumbers,
    searchValue,
    quantity,
    statusFilter,
    pageIndex,
    poItemsPerPage,
    poSort,
    poSortType
  ) =>
  async (dispatch) => 
  {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    let postResponse;
    let response;

    try{
      postResponse = await axios.post(
        `${SERVER_BASE_URL}/supplier/setPOChangeRequest/RA/${values}`,
        config
      );

      if (dateRange[0].startDate === null && !searchValue) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers},
          `,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: pageIndex - 1
          },
          config
        );
        
        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      } else if (searchValue.length > 0) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,

            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      } else {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            startDueDate: `${moment(dateRange[0].startDate).format(
              'MM-DD-YYYY'
            )}`,
            endDueDate: `${moment(dateRange[0].endDate).format('MM-DD-YYYY')}`,
            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      }
    } catch (e) {
      console.log(e, 'eee');
    }
  };

  export const RejectRequestOrderData = 
  (
    values,
    authToken,
    supplierNumbers,
    searchValue,
    quantity,
    dateRange,
    pageIndex,
    poItemsPerPage,
    poSort,
    poSortType
  ) =>
  async (dispatch) => 
  {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    let postResponse;
    let response;

    try{
      postResponse = await axios.post(
        `${SERVER_BASE_URL}/supplier/setPOChangeRequest/RR/${values}`,
        config
      );

      if (dateRange[0].startDate === null && !searchValue) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: pageIndex - 1
          },
          config
        );
        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      } else if (searchValue.length > 0) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,

            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      } else {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierNumbers}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            startDueDate: `${moment(dateRange[0].startDate).format(
              'MM-DD-YYYY'
            )}`,
            endDueDate: `${moment(dateRange[0].endDate).format('MM-DD-YYYY')}`,
            sortField: poSortType,
            sortOrder: poSort,
            pageSize: parseInt(poItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_CHANGE_REQUEST_DATA,
          payload: response.data
        });
      }
    } catch (e) {
      console.log(e, 'eee');
    }
  };

  export const uploadPDF =
  (
    supplierNumber,
    file,
    fileData,
    authToken
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      var fileName = `${fileData?.poNumber}-${fileData?.poLineNumber}_${fileData?.fileType}.pdf`;

      const response = await axios.post(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/${fileData?.fileType}/uploadPDF?fileName=${fileName}` ,
        file,
        config
      );


      toast.success('File uploaded successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const viewFile =
  (
    supplierNumber,
    fileData,
    authToken
  ) => {
    //TODO Add body
  };

  export const uploadBulkPOChangeRequest =
  (
    supplierNumber,
    file,
    authToken,
    setOpenTableModal,
    pageSize,
    pageIndex
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      setOpenTableModal(true);
      let data;
      const response = await axios.post(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/BulkPOChangeRequestConfiguration/uploadExcel`,
        file,
        config
      );

      if ( 1 === 2 && response && response?.data?.id) {
        data = await axios.post(
          `${SERVER_BASE_URL}/supplier/boPagingList/${supplierNumber}/${response?.data?.id}`,
          {
            pageSize: pageSize,
            pageIndex: pageIndex - 1
          },
          config
        );
       
      }

      const POHistoryresponse = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/BulkPOChangeRequestUploadHistory`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_PO_CHANGE_REQUEST_UPLOAD_HISTORY,
        payload: POHistoryresponse.data
      });

      dispatch({
        type: ActionTypes.GET_BULK_PO_CHANGE_REQUEST_EXCEL_DATA,
        payload: response.data.fileData
      });
      toast.success('File uploaded successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const DownloadBulkPOChangeRequest =
  (fileName, extension, authToken) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Disposition': `attachment; filename=template.${extension}`,
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      };
      const response = await axios.get(
        `${SERVER_BASE_URL}/file/download?fileName=${fileName}`,
        config
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const requestBulkPOChanges =
  (supplierNumber, poChanges, authToken, handleModalClose, handleTableModalClose, gridRef) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      if (poChanges.length !== 0) {
        const response = await axios.post(
          `${SERVER_BASE_URL}/supplier/${supplierNumber}/bulkPOChangeRequestConfiguration/requestChanges`,
          poChanges,
          config
        );
        if (response) {
          handleModalClose(true);
          handleTableModalClose(true);
        }

        toast.success(response.data);
      } else {
        toast.error('No valid POs selected.');
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const getBulkPOChangeRequestUploadHistory =
  (supplierNumber, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/BulkPOChangeRequestUploadHistory`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_PO_CHANGE_REQUEST_UPLOAD_HISTORY,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const saveBulkPOChangeRequestData =
  (authToken, values, supplierid, supplierNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/supplier/BulkPOChangeRequestConfiguration/addOrUpdate`,

        { ...values, supplierNumber: supplierNumber },

        config
      );

      const saveAddBulkPOData = await axios.post(
        `${SERVER_BASE_URL}/supplier/BulkPOChangeRequestConfiguration/addAdditionalField`,
        values,
        config
      );

      const getBulkPOConfig = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/BulkPOChangeRequestConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_PO_DATA,

        payload: getBulkPOConfig.data
      });
      toast.success('Bulk order configuration got updated successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }

      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const getBulkPOChangeRequestConfig =
  (authToken, supplierNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/BulkPOChangeRequestConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_BULK_PO_CHANGE_REQUEST_CONFIG,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const uploadPOReadyShip =
  (
    supplierNumber,
    file,
    authToken,
    setOpenTableModal,
    pageSize,
    pageIndex
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      setOpenTableModal(true);
      let data;
      const response = await axios.post(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/POReadyShipConfiguration/uploadExcel`,
        file,
        config
      );

      if ( 1 === 2 && response && response?.data?.id) {
        data = await axios.post(
          `${SERVER_BASE_URL}/supplier/ReadyShipPagingList/${supplierNumber}/${response?.data?.id}`,
          {
            pageSize: pageSize,
            pageIndex: pageIndex - 1
          },
          config
        );
       
      }

      const POHistoryresponse = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/POReadyShipUploadHistory`,
        config
      );

      dispatch({
        type: ActionTypes.GET_PO_READY_SHIP_DATA,
        payload: POHistoryresponse.data
      });

      dispatch({
        type: ActionTypes.GET_PO_READY_SHIP_EXCEL_DATA,
        payload: response.data.fileData
      });
      toast.success('File uploaded successfully.');
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const DownloadPOReadyShip =
  (fileName, extension, authToken) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Disposition': `attachment; filename=template.${extension}`,
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      };
      const response = await axios.get(
        `${SERVER_BASE_URL}/file/download?fileName=${fileName}`,
        config
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const requestPOReadyShip =
  (supplierNumber, poChanges, authToken, handleModalClose, handleTableModalClose, gridRef) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      if (poChanges.length !== 0) {
        const response = await axios.post(
          `${SERVER_BASE_URL}/supplier/${supplierNumber}/poReadyShipConfiguration/readyShip`,
          poChanges,
          config
        );
        if (response) {
          handleModalClose(true);
          handleTableModalClose(true);
        }

        toast.success(response.data);
      } else {
        toast.error('No valid POs selected.');
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const getPOReadyShipUploadHistory =
  (supplierNumber, authToken) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/POReadyShipUploadHistory`,
        config
      );

      dispatch({
        type: ActionTypes.GET_READY_SHIP_UPLOAD_HISTORY,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const getPOReadyShipConfig =
  (authToken, supplierNumber) => async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
      const response = await axios.get(
        `${SERVER_BASE_URL}/supplier/${supplierNumber}/POReadyShipConfiguration`,
        config
      );

      dispatch({
        type: ActionTypes.GET_READY_SHIP_CONFIG,
        payload: response.data
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        dispatch({
          type: ActionTypes.LOGOUT
        });
      }
      toast.error(e?.response?.data?.errorDescription);
    }
  };

  export const POReadyShipTable =
  (
    supplierNumber,
    searchValue,
    quantity,
    dateRange,
    authToken,
    pageIndex,
    poSalesItemsPerPage,
    posort,
    posortType
  ) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    try {
      let response;

      if (dateRange[0].startDate === null && !searchValue) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/listReadyShip/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            sortField: posortType,
            sortOrder: posort,
            pageSize: parseInt(poSalesItemsPerPage),
            pageIndex: pageIndex - 1
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_READY_SHIP_DATA,
          payload: response.data
        });
      } else if (searchValue.length > 0) {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/listReadyShip/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,

            sortField: posortType,
            sortOrder: posort,
            pageSize: 9999,
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_READY_SHIP_DATA,
          payload: response.data
        });
      } else {
        response = await axios.post(
          `${SERVER_BASE_URL}/supplier/listReadyShip/${supplierNumber}`,
          {
            search: `${searchValue}`,
            quantity: `${quantity}`,
            startDueDate: `${moment(dateRange[0].startDate).format(
              'MM-DD-YYYY'
            )}`,
            endDueDate: `${moment(dateRange[0].endDate).format('MM-DD-YYYY')}`,
            sortField: posortType,
            sortOrder: posort,
            pageSize: parseInt(poSalesItemsPerPage),
            pageIndex: 0
          },
          config
        );

        dispatch({
          type: ActionTypes.GET_PO_READY_SHIP_DATA,
          payload: response.data
        });
      }

      // toast.success('Part Number Added Successfully');
    } catch (e) {}
  };