import React, {
    useState,
    useEffect,
    useMemo,
  } from 'react';
  import User_Setup from '../../../../assets/Icons/User_setup.svg';
  import Access_Portal from '../../../../assets/Icons/Access_portal.svg';
  import Tooltip from '@mui/material/Tooltip';
  import Typography from '@mui/material/Typography';
  
  import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
  import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
  
  import {
    Box,
    Button,
    Grid,
    styled,
    TextField
  } from '@mui/material';
  
  import './DataGrid.css';
  
  import { useDispatch, useSelector } from 'react-redux';
  
  import CustomModal from '@mui/material/Modal';
  import InfoIcon from 'src/assets/Icons/infoIcon.svg';
  
  import { addCustomers } from 'src/redux/actions/sysAdminActions';
  
  import DataTable from 'src/components/DataTables/DataTable';
  import SERVER_BASE_URL from 'src/config/config';
  
  const CustomInput = styled(TextField)(
    () => `
      .MuiInputBase-root {
        height: 36px;
        background: #F4F7F9 ;
        border-radius: 4px;
        width: 100%;
      }
    
            :after{
              border-bottom: unset !important;
    
            }
            :before{
              border-bottom: unset !important;
    
            }
    
            .Mui-disabled:after {
              border-bottom: unset !important;
              border-bottom-style: solid;
            }
            .Mui-disabled:before {
              border-bottom: unset;
              border-bottom-style: solid;
            }
            .Mui-disabled {
              color: white !important;
              background: #f5f5f5;
            }
          `
  );
  
  const LabelBox = styled(Box)(
    () => `
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
        `
  );
  
  const AddButton = styled(Button)(
    () => `
          color: white !important;
          background: #15364A  !important;
          border-radius: 3px  !important;
          height: 38px !important;
          width: 63px !important
  
          :hover {
              background-color:  #022f53  !important;
            }
          :disabled {
            color: #aeaeae !important;
          }
          `
  );
  const CancelButton = styled(Button)(
    () => `
    background: #FFFFFF  !important;
    border: 1px solid #15364A  !important;
    border-radius: 3px  !important;
    color: #15364A  !important;
    height: 38px !important;
    width: 84px !important
        
            :hover {
                background-color: white  !important;
              }
            
            `
  );
  
  const TableContainer = styled(Box)(
    () => `
    padding: 15px 24px;
    background-color: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  
    `
  );
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', sm: '100%' },
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 4,
    bordeRadius: '5px'
  };
  
  const CustomerTable = ({ customerNumber }) => {
    const dispatch = useDispatch();
  
    const [customerPartsTableData, setCustomerPartsTableData] = useState(false);
    const [customerPartsTableColumns, setCustomerPartsTableColumns] = useState(false);
    const [customerPartsTableOptions, setCustomerPartsTableOptions] = useState(false);
  
    const [openAddCustomer, setOpenAddCustomer] = useState(false);
  
    const authUser = useSelector((state) => state?.auth?.authUserData);
    const authToken = useSelector((state) => state?.auth?.token?.token);
  
    const gridStyle = useMemo(
      () => ({
        height: '100%',
        width: '100%',
        margin: '12px 0'
      }),
      []
    );
  
    const handleAddCustomer = (e) => {
      e.preventDefault();
      dispatch(
        addCustomers(customerNumber, setOpenAddCustomer, setCustomerNumber, authToken)
      );
    };
  
    const handleCloseAddCustomer = () => {
      setCustomerNumber('');
      setOpenAddCustomer(false);
    };
  
    //Setting up Customer Part Columns
    useEffect(() => {
      let columns = [
        {
          title: "Customer Number",
          name: "customerNumber",
          data: "customerNumber"
        }
      ]
      setCustomerPartsTableColumns(columns);
    }, []);
  
    //Set up Buttons based on privileges
    const CustomerPartsTableButtons = () => {
      let buttons = [];
      buttons.push({
        text: 'Edit Part',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          setOpenAddCustomer(true);
        },
      });
      buttons.push(
        { extend: 'copy', className: 'btn-outline-secondary' },
        { extend: 'csv', className: 'btn-outline-secondary' },
        { extend: 'print', className: 'btn-outline-secondary' }
      );
      return buttons;
    };
  
  
    //Setting up configuration
    useEffect(() => {
      let options = {
        dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
          "<'row'<'col-12'tr>>" +
          "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
        buttons: CustomerPartsTableButtons(),
        ajax: {
          url: `${SERVER_BASE_URL}/parts/list/cn/${customerNumber}`, 
          type: "GET",
          dataSrc: function (data) {
            setCustomerPartsTableData(data);
            return data;
          },
          // dataSrc: 'content',
          contentType: 'application/json',
          dataType: 'JSON',
          timeout: 0,
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
        info: true,
        processing: true,
        retrieve: true,
        pageResize: true,
        scrollCollapse: true,
        lengthChange: true,
        pageLength: 10,
        fixedHeader: true,
        scrollY: '450px',
        order: [[1, 'asc']],
        searching: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
        }
      };
      setCustomerPartsTableOptions(options);
    }, []);
  
    return (
      <div className="custom-pg-content-sec">
        <Box
          className="custom-pg-titlesticky-sec"
          component="form"
          sx={{
            '& > :not(style)': { ml: 0 },
            display: { md: 'flex', xs: 'box' },
            flexDirection: 'row'
          }}
          noValidate
          autoComplete="off"
        >
          <Box className="custom-heading-container-sec" sx={{}}>
            <span
              className="custom-heading-sec"
              style={{
                fontSize: '20px',
                fontWeight: 700,
                fontWeight: 700,
                display: 'inline-flex',
                flexDirection: 'column'
              }}
            >
              Manage Customers
              <label
                style={{
                  fontSize: '15px',
                  fontWeight: '600',
                  lineHeight: '1',
                  marginTop: '12px'
                }}
              >
                Customer List
              </label>
            </span>{' '}
          </Box>
  
          {/* MODAL AND POPOVERS */}
          <CustomModal keepMounted open={openAddCustomer} onClose={handleCloseAddCustomer}>
            <Box sx={style}>
              <div>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Add Customer
                </Typography>
              </div>
              <div style={{ marginTop: '30px' }}>
                <form onSubmit={handleAddCustomer}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sx={{ margin: '10px 0' }}>
                      <Box sx={{ display: 'flex' }}>
                        <LabelBox sx={{ flexGrow: 1 }}>
                          Enter Customer Number
                          <span style={{ color: 'red' }}> *</span>
                        </LabelBox>
                        <Tooltip
                          title={
                            <ul>
                              <li>
                                Add by comma seperated to load more customer
                                numbers
                              </li>
                              <li>Only numbers are allowed to enter!</li>
                            </ul>
                          }
                          disableInteractive
                        >
                          <img src={InfoIcon}></img>
                        </Tooltip>
                      </Box>
  
                      <CustomInput
                        name="firstName"
                        onChange={(e) => setCustomerNumber(e.target.value)}
                        fullWidth
                        value={customerNumber}
                        autoComplete="none"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      '& > :not(style)': { ml: 2, mt: 3 },
                      display: 'flex',
                      flexDirection: 'row-reverse'
                    }}
                  >
                    <Grid item xs={6} md={6}>
                      <AddButton
                        disabled={!customerNumber}
                        // onClick={(e) => handleSaveClick(e)}
                        type="submit"
                      >
                        Add
                      </AddButton>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      {/* <CancelButton onClick={handleCancelClick */}
                      <CancelButton onClick={handleCloseAddCustomer}>Cancel</CancelButton>
                    </Grid>
                  </Box>
                </form>
              </div>
            </Box>
          </CustomModal>
        </Box>
        <div className="custom-pg-tables-content-sec">
          <TableContainer style={gridStyle}>
            {customerPartsTableColumns && customerPartsTableOptions && (
              <DataTable
                id={'ManageCustomersTable'}
                columns={customerPartsTableColumns}
                options={customerPartsTableOptions}
              />
            )}
          </TableContainer>
        </div>
      </div>
    );
  };
  
  export default CustomerTable;
  