import { Box, styled, Grid } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {RotatingLines} from 'react-loader-spinner';
import {
  getAllCustomersById,
  getCustomer
} from 'src/redux/actions/sysAdminActions';
import { advanceTime } from 'src/services/Time-tracking';
import { timeCalculation } from 'src/redux/actions/DashboardActions';
import { fetchRawMaterial, clearData } from 'src/redux/actions/rawMaterialActions';
import Table from './Table';
import './RawMaterialAnalysis.css'; // Import the CSS file

import { connect } from 'react-redux';

const rawMaterialList = ({ rawMaterial, loading, error, fetchRawMaterial }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchRawMaterial();

     // Return a cleanup function to clear data on unmount
     return () => {
      dispatch(clearData()); // Dispatch the action to clear the data when the component unmounts
    };
  }, [fetchRawMaterial]);

  if (loading) {
    return (
      <tr>
        <td colSpan={5+mondays.length}>
        
        <RotatingLines
          strokeColor="#306cce"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
       
        </td>
      </tr>  
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Table/>
      <h1>Raw Material List</h1>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Due Date</th>
            <th>Invoice Number</th>
            <th>Part Number</th>
            <th>Part Length</th>
            <th>Process Length</th>
            <th>LB</th>
            <th>Avg Cost</th>
            <th>PCS</th>
            <th>Quantity</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {rawMaterial.map((e, index) => (
            <tr key={index}>
              <td>{e.prodclass}</td>
              <td>{e.dim1}</td>
              <td>{e.dim2}</td>
              <td>{e.dim3}</td>
              <td>{e.idesc}</td>
              <td>{e.odesc}</td>
              <td>{e.branchnumber}</td>
              <td>{e.branchname}</td>
              <td>{e.partnumber}</td>
              <td>{e.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rawMaterial: state.rawMaterial.rawMaterials,
    loading: state.rawMaterial.loading,
    error: state.rawMaterial.error
  }
};

const mapDispatchToProps = {
  fetchRawMaterial
};


//export default RawMaterialMain;

export default connect(mapStateToProps, mapDispatchToProps)(rawMaterialList);