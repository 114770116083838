import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import { FileUploader } from 'react-drag-drop-files';
import Trash from 'src/assets/Icons/trashRed.svg';
import CustomModal from '@mui/material/Modal';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import moment from 'moment';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import {
  Box,
  Button,
  styled,
  Grid
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import {
  uploadPDF
} from '../../../../redux/actions/SupplierPortalActions';
import { toast } from 'react-toastify';
import RequestOrderEditForm from './requestOrderEditForm';

const TabItem = styled(Box)(
  () => `
  
    font-family: Open Sans;
    
    padding: 22px;
    cursor: pointer;
    color: #72869A;
    
    font-weight: 700;
    font-size: 18px;
      
    height: 61px;
    margin-right: 12px;
  
    
    `
);

const ModalButton = styled(Button)(
  () => `
    background-color: #15364a !important;
      color: white !important;
      height: 40px !important;
      border-radius: 3px !important;
      padding: 0 13px !important;
      font-weight: 600;
      font-size: 16px;
  
      :hover {
          background-color: black !important;
        }
      `
);

const ModalHeader = styled(Box)(
  () => `
      font-weight: 700;
       font-size: 20px;
      `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  maxHeight: { md: '500px', sm: '100%' },
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 3,
  bordeRadius: '5px'
};

const requestOrderEditStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '60%', sm: '100%' },
  maxHeight: { md: '500px', sm: '100%' },
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 3,
  bordeRadius: '5px'
};

const CancelButton = styled(Button)(
  () => `
    color: white !important;
    background: #15364A  !important;
    border-radius: 3px  !important;
    height: 38px !important;
    width: 63px !important
  
    :hover {
        background-color:  #022f53  !important;
      }
      :disabled {
        opacity: 0.8 !important;
        color: white
      }
    `
);

const TableContainer = styled(Box)(
  () => `
        padding: 15px 24px;
        background-color: white;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
      
        
        `
);

const SupplierPortalTable = (supplierNumber) => {
  const dispatch = useDispatch();
  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [requestOrderEditData, setRequestOrderEditData] = React.useState({});
  const [openRequestOrderEditModal, setOpenRequestOrderEditModal] = React.useState(false);

  const [fileData, setFileData] = React.useState({});
  const [file, setFile] = React.useState({});
  const [openFileModal, setFileModal] = React.useState(false);
  const fileTypes = ['PDF'];

  const authToken = useSelector((state) => state?.auth?.token?.token);
  const authUserRole = useSelector((state) => state?.auth?.authUserData);
  const authUserData = useSelector((state) => state?.auth?.authUserData);
  const supplierData = useSelector((state) => state?.suppliers?.supplierData);

  const [poSalesDataTableColumns, setPOSalesDataTableColumns] = useState(false);
  const [poSalesDataTableOptions, setPOSalesDataTableOptions] = useState(false);
  const [poSalesDataTableData, setPOSalesDataTableData] = useState(false);

  const [poReadyShipDataTableColumns, setPOReadyShipDataTableColumns] = useState(false);
  const [poReadyShipDataTableOptions, setPOReadyShipDataTableOptions] = useState(false);
  const [poReadyShipDataTableData, setPOReadyShipDataTableData] = useState(false);

  const [poChangeDataTableColumns, setPOChangeDataTableColumns] = useState(false);
  const [poChangeDataTableOptions, setPOChangeDataTableOptions] = useState(false);
  const [poChangeDataTableData, setPOChangeDataTableData] = useState(false);

  const [tab, setTab] = useState('posales');
  const [selectedData, setSelectedData] = React.useState({});

  const [poSalesPage, setPOSalesPage] = useState(1);

  //PO Sales Data Table buttons
  const POSalesDataTableButtons = () => {
    let buttons = [];
    if (authUserRole?.roles?.find((d) => d?.code !== 'ROLE_CUSTOMER')) {
      buttons.push({
        text: 'Request Edit',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          setSelectedData({ dtselected });
          //handleOpenLeadTime(dtselected);
        },
      });
    }
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  //PO Sales Data Table columns
  useEffect(() => {
    let columns = [
      {
        name: 'checkbox',
        data: '',
        orderable: false,
        render: function (data, type, row) {
          return `<input type="checkbox" class="form-check-input" name="item${row.id}" id="item${row.id}" value="${row.id}">`;
        },
      },
      {
        title: 'PO',
        name: 'poNumber',
        data: 'purchaseOrderNumber',
      },
      {
        title: 'Line',
        name: 'poLineNumber',
        data: 'purchaseOrderLineNumber',
        defaultContent: '',
      },
      {
        title: 'Branch',
        name: 'branch',
        data: 'branchNumber',
        defaultContent: '00',
      },
      {
        title: 'Product',
        name: 'product',
        data: 'productClass',
        render: function (data, type, row) {
          return `${row.productClass} ${row.dim1} ${row.dim2} ${row.dim3} ${row.idesc} ${row.odesc}`;
        },
      },
      {
        title: 'Quantity Ordered',
        name: 'quantity',
        data: 'quantity',
        defaultContent: '00',
        render: function (data, type, row) {
          return Number(data).toFixed(2);
        },
      },
      {
        title: 'Remaining Balance',
        name: 'balance',
        data: 'balance',
        defaultContent: '00',
        render: function (data, type, row) {
          return Number(data).toFixed(2);
        },
      },
      {
        title: 'Current PO Cost',
        name: 'cost',
        data: 'cost',
        render: function (data, type, row) {
          return `${row.cost} ${row.costUom}`;
        },
      },
      {
        title: 'Line Weight',
        name: 'weight',
        data: 'weight',
        render: function (data, type, row) {
          return `${row.weight} ${row.weightUom}`;
        },
      },
      {
        title: 'Length',
        name: 'length',
        data: 'length',
        defaultContent: '0',
      },
      {
        title: 'Due Date',
        name: 'dueDate',
        data: 'dueDate',
        render: function (data, type, row) {
          return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
        },
      },
      {
        title: 'Documents',
        name: 'id',
        data: 'id',
        render: function (data, type, row) {
          let linkArray = [];
          var productSKU = `${row?.productClass} ${row?.dim1} ${row?.dim2} ${row?.dim3} ${row?.idesc} ${row?.odesc}`;
          linkArray.push(`<a href="#" data-type="INVOICE">Invoice</a>`);
          linkArray.push(`<a href="#" data-type="MTR">MTR</a>`);
          linkArray.push(`<a href="#" data-type="PACKAGING_LIST">Packing List</a>`);
          linkArray.push(`<a href="#" data-type="POD">POD</a>`);

          return linkArray.join(' | ');
        },
      },
    ];

    setPOSalesDataTableColumns(columns);
  }, []);


  //Set PO Order Options
  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: POSalesDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/supplier/list/${supplierNumber?.supplierNumber}?pageSize=1000000&pageIndex=0`,
        type: "GET",
        dataSrc: function (data) {
          setPOSalesDataTableData(data);
          return data.content;
        },
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 50,
      fixedHeader: true,
      scrollY: '450px',
      order: [[1, 'asc']],
      searching: true,
      select: {
        style: 'multi',
      },
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setPOSalesDataTableOptions(options);
  }, []);

  const handleDocumentLink = (event) => {
    let clickedLink = $(event.currentTarget);

    let row = $('#POSalesTable').DataTable().row($(event.target).closest('tr')).data();

    handleFileUploadOpen(clickedLink.data('type'), row.purchaseOrderNumber.trim(), row.purchaseOrderLineNumber.trim(), productSKU.trim())
  };

  //PO Change Data Table buttons
  const POChangeDataTableButtons = () => {
    let buttons = [];
    if (authUserRole?.roles?.find((d) => d?.code !== 'ROLE_CUSTOMER')) {
      buttons.push({
        text: 'Request Edit',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          setSelectedData({ dtselected });
          //handleOpenLeadTime(dtselected);
        },
      });
    }
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  //PO Change Datatable Columns
  useEffect(() => {
    let columns = [
      {
        name: 'checkbox',
        data: '',
        orderable: false,
        render: function (data, type, row) {
          return `<input type="checkbox" class="form-check-input" name="item${row.id}" id="item${row.id}" value="${row.id}">`;
        },
      },
      {
        title: 'PO',
        name: 'poNumber',
        data: 'purchaseOrderNumber',
      },
      {
        title: 'Line',
        name: 'poLineNumber',
        data: 'purchaseOrderLineNumber',
        defaultContent: '',
      },
      {
        title: 'Supplier Name',
        name: 'supplierName',
        data: 'supplierName',
        defaultContent: '',
      },
      {
        title: 'Product',
        name: 'product',
        data: 'productClass',
        render: function (data, type, row) {
          return `${row.productClass} ${row.dim1} ${row.dim2} ${row.dim3} ${row.idesc} ${row.odesc}`;
        },
      },
      {
        title: 'Quantity Ordered',
        name: 'quantity',
        data: 'quantity',
        defaultContent: '00',
        render: function (data, type, row) {
          return Number(data).toFixed(2) + " " + row.uom;
        },
      },
      {
        title: 'Remaining Balance',
        name: 'balance',
        data: 'balance',
        defaultContent: '00',
        render: function (data, type, row) {
          return Number(data).toFixed(2) + " " + row.uom;
        },
      },
      {
        title: 'Current PO Cost',
        name: 'cost',
        data: 'cost',
        render: function (data, type, row) {
          return `${row.cost} ${row.costUom}`;
        },
      },
      {
        title: 'Line Weight',
        name: 'weight',
        data: 'weight',
        render: function (data, type, row) {
          return `${row.weight} ${row.weightUom}`;
        },
      },
      {
        title: 'Length',
        name: 'length',
        data: 'length',
        defaultContent: '0',
      },
      {
        title: 'Due Date',
        name: 'dueDate',
        data: 'dueDate',
        render: function (data, type, row) {
          return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
        },
      },
    ];

    setPOChangeDataTableColumns(columns);
  }, []);


  //Set PO Change Options
  useEffect(() => {
    if (supplierData) {
      let options = {
        dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
          "<'row'<'col-12'tr>>" +
          "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
        buttons: POChangeDataTableButtons(),
        ajax: {
          url: `${SERVER_BASE_URL}/supplier/getPOChangeRequest/${supplierData.supplierNumber}`,
          type: "GET",
          dataSrc: function (data) {
            setPOChangeDataTableData(data);
            return data.content;
          },
          dataType: 'JSON',
          timeout: 0,
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
        info: true,
        processing: true,
        retrieve: true,
        pageResize: true,
        scrollCollapse: true,
        lengthChange: false,
        pageLength: 50,
        fixedHeader: true,
        scrollY: '450px',
        order: [[1, 'asc']],
        searching: true,
        select: {
          style: 'multi',
        },
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search',
        }
      };
      setPOChangeDataTableOptions(options);
    }
  }, [supplierData]);

  const handleRequestOrderEditModalClose = () => {
    setOpenRequestOrderEditModal(false);
  };

  const handleFileModalClose = () => {
    setFile(null);
    setFileModal(false);
  };

  const handleFileUploadOpen = (type, number, lineNumber, sku) => {
    const values = { fileType: type, poNumber: number, poLineNumber: lineNumber, productSKU: sku };

    setFileData({ ...values });
    setFile(null);
    setFileModal(true);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleFileUpload = () => {
    const fd = new FormData();
    fd.append('file', file);

    dispatch(
      uploadPDF(
        supplierData?.supplierNumber,
        fd,
        fileData,
        authToken)
    );
    handleFileModalClose();
  };

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', margin: '0 0 12px 0' }),
    []
  );

  const onTrashClick = () => {
    setFile(null);
  };

  return (
    <div className="custom-pg-content-sec">
      <Box
        className="custom-pg-titlesticky-sec"
        component="form"
        sx={{
          '& > :not(style)': { ml: 0 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ flexGrow: 1 }}>
          <span style={{ fontSize: '20px', fontWeight: 700 }}>
            {supplierData?.supplierName}
          </span>
        </Box>
      </Box>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}{' '}
      <div className="custom-pg-table-content-sec">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <TabItem
            className={`TEST5` && tab === 'posales' && 'tabActive'}
            id="TEST5"
            onClick={() => setTab('posales')}
          >
            Purchase Orders
          </TabItem>

          <TabItem
            className={`TEST5` && tab === 'pochange' && 'tabActive'}
            id="TEST5"
            onClick={() => setTab('pochange')}
          >
            PO Change Requests
          </TabItem>

          <TabItem
            className={`TEST6` && tab === 'poreadyship' && 'tabActive'}
            id="TEST6"
            onClick={() => setTab('poreadyship')}
          >
            Ready to Ship
          </TabItem>
        </Box>
      </div>
      {/* MODAL AND POPOVERS */}
      <CustomModal
        open={openRequestOrderEditModal}
        onClose={handleRequestOrderEditModalClose}>
        <Box sx={requestOrderEditStyle}>
          <RequestOrderEditForm
            handleModalClose={handleRequestOrderEditModalClose}
            selectedData={requestOrderEditData}
            sd={selectedData}
            gridRef={gridRef}
            setSelectedData={setSelectedData}
            handleDeselect={gridRef}
            page={poSalesPage}
            setPage={setPOSalesPage}
            sx={{ visibility: 'Hidden' }}
          />
        </Box>
      </CustomModal>
      <CustomModal open={openFileModal} onClose={handleFileModalClose}>
        <Box sx={style}>
          <ModalHeader>Upload File {fileData?.fileType}</ModalHeader>
          <Box sx={{ flexGrow: 1 }}>
            <span style={{ fontSize: '16px', fontWeight: 700 }}>
              Purchase Order: {fileData?.poNumber}-{fileData?.poLineNumber}
            </span><br />
            <span style={{ fontSize: '16px', fontWeight: 700 }}>
              Product: {fileData?.productSKU}
            </span>{' '}
          </Box>
          <div style={{ marginTop: '20px' }}>
            {!file && (
              <Box className="file_Upload">
                <FileUploader
                  onTypeError={(err) => toast.error(err)}
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    padding: '8px 0 0 0'
                  }}
                >
                  <Grid item xs={6} md={6} sm={12}>
                    {'Acceptable File Types: ' + fileTypes}
                  </Grid>
                  <Grid item xs={6} md={6} sm={12}>
                    {'Max File Size: 50mB'}
                  </Grid>
                </Box>
              </Box>
            )}

            {file && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '25px 0',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#343D42'
                }}
              >
                {file && file?.name}
                <span style={{ margin: '5px 10px 0 10px' }}>
                  {' '}
                  <img
                    width="20px"
                    className="Img1"
                    style={{ cursor: 'pointer' }}
                    onClick={onTrashClick}
                    src={Trash}
                  ></img>
                </span>
              </Box>
            )}

            <Box
              sx={{
                '& > :not(style)': { mr: 2, mt: 3 },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Grid item xs={6} md={2}>
                <CancelButton onClick={handleFileModalClose}>Cancel</CancelButton>
              </Grid>{' '}
              <Grid item xs={6} md={2}>
                <CancelButton disabled={!file} onClick={handleFileUpload}>
                  Upload
                </CancelButton>
              </Grid>
            </Box>
          </div>
        </Box>
      </CustomModal>
      <>
        <div style={{ display: tab === 'posales' ? 'contents' : 'none' }}>
          {poSalesDataTableColumns && poSalesDataTableOptions && (
            <TableContainer style={gridStyle}>
              <DataTable
                id={'POSalesTable'}
                columns={poSalesDataTableColumns}
                options={poSalesDataTableOptions}
                onRowClick={handleDocumentLink}
              />
              <Box sx={{}}>
                <ModalButton onClick={() => { $('#dataTable').DataTable().ajax.reload(); }}>
                  Refresh
                </ModalButton>
              </Box>
            </TableContainer>
          )}
        </div>
        <div style={{ display: tab === 'pochange' ? 'contents' : 'none' }}>
          {poChangeDataTableColumns && poChangeDataTableOptions && (
            <TableContainer style={gridStyle}>
              <DataTable
                id={'POChangeTable'}
                columns={poChangeDataTableColumns}
                options={poChangeDataTableOptions}
              />
              <Box sx={{}}>
                <ModalButton onClick={() => { $('#dataTable').DataTable().ajax.reload(); }}>
                  Refresh
                </ModalButton>
              </Box>
            </TableContainer>
          )}
        </div>
        <div style={{ display: tab === 'poreadyship' ? 'contents' : 'none' }}>
          {poReadyShipDataTableColumns && poReadyShipDataTableOptions && (
            <TableContainer style={gridStyle}>
              <DataTable
                id={'POReadyShipTable'}
                columns={poReadyShipDataTableColumns}
                options={poReadyShipDataTableOptions}
              />
            </TableContainer>
          )}
        </div>
      </>
    </div>
  );
};
export default SupplierPortalTable;
