import { ActionTypes } from '../constants/action-types';

const initialState = {};

const supplierPortalReducer = (
  state = initialState,
  { type, payload } = []
) => {
  switch (type) {
    
    case ActionTypes.GET_SUPPLIERS_PORTAL:
      return { ...state, suppliersPortalData: { ...payload } };

    case ActionTypes.GET_PO_SALES_TABLE_DATA:
      return { ...state, getPOSalesTableData: [ ...payload ] };

    case ActionTypes.GET_SUPPLIER_PO_EXCEL_DATA:
      return { ...state, getPOSalesTableData: { ...payload } };

    case ActionTypes.GET_SUPPLIER:
      return { ...state, supplierData: { ...payload } };

    case ActionTypes.GET_PO_CHANGE_REQUEST_DATA:
      return { ...state, getPOChangeRequestTableData: { ...payload } };

    case ActionTypes.GET_PO_CHANGE_REQUEST_EXCEL_DATA:
      return { ...state, getPOChangeRequestTableData: { ...payload } };

    case ActionTypes.GET_PO_READY_SHIP_DATA:
      return { ...state, getPOReadyShipTableData: { ...payload } };  

    case ActionTypes.GET_PO_READY_SHIP_EXCEL_DATA:
      return { ...state, getPOReadyShipTableData: { ...payload } };      

    case ActionTypes.GET_READY_SHIP_UPLOAD_HISTORY:
      return { ...state, getReadyShipUploadHistory: { ...payload } };  

    case ActionTypes.GET_READY_SHIP_CONFIG:
      return { ...state, getReadyShipConfig: { ...payload }}

    case ActionTypes.GET_BULK_PO_CHANGE_REQUEST_UPLOAD_HISTORY:
      return { ...state, getBulkPOChangeRequestUploadHistory: [ ...payload ] };

    case ActionTypes.GET_BULK_PO_CHANGE_REQUEST_CONFIG:
      return { ...state, getBulkPOChangeRequestConfig: { ...payload } };

    case ActionTypes.GET_BULK_PO_CHANGE_REQUEST_EXCEL_DATA:
      return { ...state, getBulkPOChangeRequestExcelData: [ ...payload ] };

    default:
      return state;
  }
};

export default supplierPortalReducer;
