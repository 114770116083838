import { Helmet } from 'react-helmet-async';
import { Box, styled, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../../../../components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';

import { getSupplier } from 'src/redux/actions/sysAdminActions';
import moment from 'moment';




const HistoryItem = styled(Box)(
  () => `
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  
  color: #15364A;
  margin-bottom: 4px;
`
);

const ContentBox = styled(Box)(
  () => `
  padding: 0px 80px 0 32px;
`
);

const TextSpan = styled(Typography)(
  () => `
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  
  
  color: #15364A;
`
);
function SupplierInfo() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const supplierData = useSelector((state) => state?.suppliers?.supplierData);
  const authToken = useSelector((state) => state?.auth?.token?.token);

  useEffect(() => {
    if (id) dispatch(getSupplier(id, authToken));
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '26px 0px 26px 0px',
          background: '#F2F2F2 !important'
        }}
      >
        <Grid item>
          <ContentBox>
            <HistoryItem>Supplier Number</HistoryItem>
            <TextSpan>{supplierData?.supplierNumber}</TextSpan>
          </ContentBox>
        </Grid>
        <Grid item>
          <ContentBox>
            <HistoryItem>Supplier Name</HistoryItem>
            <TextSpan>{supplierData?.supplierName} </TextSpan>
          </ContentBox>
        </Grid>

        <Grid item>
          <ContentBox>
            <HistoryItem>Last Modified By</HistoryItem>
            <TextSpan>{supplierData?.updatedBy}</TextSpan>
          </ContentBox>
        </Grid>
        <Grid item>
          <ContentBox>
            <HistoryItem>No of Users</HistoryItem>
            <TextSpan>
              {supplierData?.associatedUsers?.filter(
                (d) => d?.userType === 'SE'
              ).length ?? 'N/A'}
            </TextSpan>
          </ContentBox>
        </Grid>
        <Grid item>
          <ContentBox>
            <HistoryItem>Last Updated Date</HistoryItem>
            <TextSpan>
              {moment.utc(supplierData?.updatedDate).format('MM/DD/YYYY HH:mm')}
            </TextSpan>
          </ContentBox>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '26px 0px 26px 0px',
          background: '#F2F2F2 !important'
        }}
      >
        <Grid item>
          <ContentBox>
            <HistoryItem>Manufacture Code</HistoryItem>
            <TextSpan>{supplierData?.manufactureCode}</TextSpan>
          </ContentBox>
        </Grid>
        <Grid item>
          <ContentBox>
            <HistoryItem>Vendor Type Code</HistoryItem>
            <TextSpan>{supplierData?.inventoryVendorType} </TextSpan>
          </ContentBox>
        </Grid>
      </Box>
    </>
  );
}

export default SupplierInfo;
